header {
  width: 100%;
  height: 60px;
}

#main_screen {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  min-width: fit-content;
  background: var(--primary-background);
  transition: background .2s;
  position: relative;

  > aside {
    height: 100%;
    position: relative;
    width: 360px;
    border-right: 1px solid var(--border-color);

    > div {
      &:first-of-type {
        height: 100%;
      }

      &.border-resize {
        position: absolute;
        top: 0;
        right: 0;
        width: 5px;
        height: 100%;
        cursor: col-resize;
      }
    }
  }

  > section {
    flex: 1;
    display: flex;

    &#settings {
      position: relative;
    }

    .settings,
    .chat,
    .chat-container {
      flex: 1;

      &.ip {
        mark {
          color: var(--auth-header-color) !important;
        }
      }
    }

    .settings {
      position: relative;
      display: flex;
      max-height: 100%;
      overflow: hidden overlay;
    }
  }
}

.no-fill {
  path {
    fill: none !important;
  }
}