@use "src/sass/abstracts/styled-fonts" as *;

.call_ringing {
  width: 100%;
  height: 332px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;

  p {
    color: var(--font-color-white);
    max-width: 232px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .top {
    @include font-body-small-medium;
    display: flex;
    gap: 4px;
    flex-direction: column;
    margin-bottom: 12px;

    > p:first-of-type::after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0;
      margin-right: 1em
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > button:hover, > button:focus, > button:focus-within {
      * {
        text-decoration: underline;
      }
    }

    h4 {
      color: var(--font-color-white);
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }

    > p {
      @include font-body-medium-regular;
    }
  }
}

@keyframes ellipsis {
  to {
    width: 1em;
    margin-right: 0;
  }
}


@-webkit-keyframes ellipsis {
  to {
    width: 1em;
    margin-right: 0;
  }
}