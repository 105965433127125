/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  cursor: pointer;
  background-color: var(--tertiary-background);
  -webkit-transition: .4s;
  transition: .4s;
  width: 34px;
  height: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  margin: 2.5px;
  display: block;
  background-color: var(--font-color-white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background: var(--primary-color);
}

input:checked + .slider:before {
  margin: 2.5px 2.5px 2.5px 14.5px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
