@use "src/sass/abstracts/styled-fonts" as *;

.menu-header {

  .menu-header__title {
    position: relative;
    display: flex;
    height: 28px;
    align-items: center;
    margin: 16px 16px 28px;
    flex-direction: row;

    > svg {
      width: 20px;

      path, circle {
        fill: var(--primary-font-color);
      }
    }

    .menu-header__icon-container {
      display: flex;
      gap: 4px;
      margin-left: auto;

      button {
        &[data-is-active='true'] {
          background: var(--secondary-background)
        }

        &[data-warning='true'] {
          padding: 6px;
          width: 32px;
          border-color: transparent;

          path {
            fill: var(--color-danger);
          }
        }

        .menu-header__icon-notification {
          position: absolute;
          width: 8px;
          height: 8px;
          right: 0;
          top: 4px;
          border-radius: 50%;
          background: var(--notification-color);
        }
      }

      .menu-header__icon-extra-padding {
        span svg {
          width: 20px;
        }
      }
    }

    h2 {
      @include font-body-large-medium;
      letter-spacing: 0.03em;
      color: var(--primary-font-color);
    }

    .menu-header__blf-device {
      margin: 0 !important;
      margin-left: 12px !important;
      position: static !important;
      width: max-content;
      max-width: 100%;
    }

    > i {
      margin-left: auto;
      cursor: pointer;

      &.collapsed {
        transform: rotate(180deg);
      }
    }

    > i {
      &:first-of-type {
        margin-left: auto;
      }

      & ~ i {
        margin-left: 20px;
      }

      &.icon-cross {
        font-size: 16px;
      }
    }

    > div:not(.caller-id) {
      display: flex;
      align-items: center;
      margin-left: auto;

      i {
        font-size: 16px;
        color: var(--secondary-font-color);
        cursor: pointer;

        &:hover {
          color: var(--tertiary-color);
        }

        & ~ i {
          margin-left: 20px;
        }

        &.icon-all {
          font-size: 11px;
        }

        &.icon-blf-online,
        &.icon-favourite-enabled,
        &.icon-favourite-disabled {
          font-size: 18px;
        }

        &.icon-cross--hover {
          color: var(--secondary-color);

          &:hover {
            color: var(--secondary-color-dark);
          }
        }

        &.active {
          color: var(--tertiary-color);
        }
      }

      .notification-bubble {
        position: absolute;
        top: -15px;
        right: -10px;
      }
    }

  }

  .menu-header__tab-dropdown {
    position: relative;
    display: flex;
    height: 28px;
    align-items: center;
    margin: 16px 16px 28px 4px !important;
    flex-direction: row;


    &.prov {
      margin: 0 0 0 4px !important;
    }

    > button {
      border: none;
      width: max-content;
      min-width: 0;

      h2 {
        @include font-body-large-medium;
        color: var(--primary-font-color);
      }

      > span, > span > svg {
        width: 24px;
        height: 24px;

        path {
          fill: var(--primary-font-color);
        }
      }
    }
  }

  .menu-header__options {
    display: flex;
    flex-direction: row;
    width: 100%;

    > button {
      background: #FEFEFE;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      align-self: center;
      margin-right: 10px;

      &:hover {
        background: var(--border-color);
      }
    }
  }

  .menu-header-input {
    display: flex;
    flex-direction: column;
    height: 36px;
    justify-content: center;
    gap: 10px;
    margin: 0 16px 28px;

    > div {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 4px 4px 8px;
      gap: 10px;
      width: 100%;
      height: 50px;
      max-height: 40px;
      border-radius: 4px;
      border: 1px solid var(--border-color);

      &:focus-within {
        border: 1px solid var(--focus-border-color);
      }

      .text-input {
        flex-direction: row;
        align-items: center;
        flex: 1;

        > span {
          width: 28px;
          height: 28px;
        }

        > svg {
          min-height: 24px;
          min-width: 24px;
          max-height: 24px;
          max-width: 24px;
          margin-right: 4px;
        }

        input {
          width: 100%;
          background: none;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
      }

      .menu-header__filters-divider {
        width: 1px;
        height: 24px;
        background: var(--border-color);
      }

      .menu-header__filters {
        > button {
          border: none;
          gap: 4px;
          min-width: 76px;

          > p {
            @include font-body-medium-regular;
          }
        }

        ul {
          max-height: 380px;
          overflow: hidden auto;
          min-width: 180px;
          max-width: 320px;

          .menu-header__remove-filters {
            width: max-content;
            margin: 0 4px 0 auto;
            border-radius: 4px;
          }

          > label {
            @include font-body-medium-regular;
            display: flex;
            align-items: center;
            padding: 0 12px;
            cursor: pointer;
            gap: 8px;
            height: 32px;

            &:hover, &:focus, &:focus-within {
              background: var(--secondary-background);

              input[type="checkbox"] {
                border: 1px solid var(--focus-border-color);
              }
            }

            &[data-disabled='true'] {
              pointer-events: none;

              > p {
                color: var(--secondary-font-color);
              }

              input[type="checkbox"] {
                opacity: 0.8;
              }
            }

            input[type="checkbox"] {
              appearance: none;
              margin: 0;
              font: inherit;
              color: currentColor;
              width: 14px;
              height: 14px;
              border: 1px solid var(--border-color);
              border-radius: 3px;
              display: grid;
              place-content: center;
              transition: 0.2s background, 0.2s border;
              transform-origin: center;

              &:checked {
                background: var(--primary-color);
                border: 1px solid var(--primary-color) !important;
              }

              &:before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--font-color-white);
                transform-origin: center;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
              }

              &:checked::before {
                transform: scale(1);
              }
            }
          }

          > p {
            padding: 0 8px;
            @include font-body-medium-bold;
          }
        }
      }
    }
  }

  .menu-header__filters-display {
    margin: -22px 16px 6px;
    height: 16px;

    p {
      @include font-body-small-medium;
      color: var(--secondary-font-color);
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: right;

      span {
        font-style: italic;
      }
    }
  }

  .restricted-phonebooks {
    //position: absolute;
    //left: 24px;
    margin: 0 !important;
    justify-content: center;
    width: 24px;
    height: 20px;

    i {
      width: 8px;
      height: 8px;
      background: var(--color-warning);
      border-radius: 50%;
    }

    >div {
      top: 100%;
      bottom: revert;

      &:before {
        top: -12px;
        left: 19px;
        z-index: 100;
        transform: rotate(180deg);
      }
    }

    ul {
      padding-top: 8px;

      li {
        color: var(--font-color-white);
        @include font-body-small-regular;
        display: flex;
        align-items: center;
        gap: 4px;

        &:before {
          display: flex;
          width: 3px;
          height: 3px;
          background: currentColor;
          content: '';
          border-radius: 50%;

        }
      }
    }
  }

  .menu-header__caller-ID {
    display: flex;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
    justify-content: flex-end;

    > div > button {
      border: none;

      path {
        fill: var(--dropdown-arrow-color);
      }
    }

    > p {
      @include font-body-medium-regular;
      color: var(--primary-font-color);
    }
  }

}

.global-search .menu-header {
  cursor: pointer;
}
