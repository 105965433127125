

.form_dialogue {
  display: grid;
  place-items: center;
  background: none;

  &[data-as-dialogue='true'] {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
  }
}