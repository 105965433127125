@use "src/sass/abstracts/styled-fonts" as *;

.chat_settings_container {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 250;
  top: 69px;
  background: var(--primary-background);
  border-left: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  width: 400px;
}

.chat_settings_page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    > h4 {
      @include font-body-large-medium;
      margin-right: auto;
    }
  }

  .selected_users {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    > li {
      border: 1px solid var(--border-color);
      display: flex;
      gap: 4px;
      padding: 4px;
      border-radius: 4px;

      > p {
        @include font-body-small-regular;
      }

      button, span, svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  .input_container {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 40px;
    cursor: text;

    span, svg {
      width: 28px;
      height: 28px;
    }

    &:focus, &:focus-within {
      border-color: var(--focus-border-color);
    }
  }
}