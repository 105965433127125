
@use "src/sass/abstracts/mixins" as *;

#create_account {
  display: flex;
  height: 100vh;

  .container {
    height: unset;

    .logo {
      margin: 0 155px 50px;

      > img {
        width: 170px;
        height: 70px;
      }
    }

    .create-details {
      position: relative;
      justify-content: center;
      width: 340px;
      margin: 0 70px;

      > h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
      }

      > p {
        text-align: center;
      }

      a {
        color: var(--primary-background);
      }

      .login-error {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 66px;
        top: 22px;
        background: var(--primary-color);
        width: fit-content;
        height: 53px;
        padding: 2px 15px;
        border-radius: 4px;
        border: solid 1px var(--primary-background);
        font-size: 14px;
        animation: shake-horizontal 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;

        > p {
          color: var(--primary-background);
          padding-left: 5px;
        }

        &::after,
        &::before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &::after {
          border-color: rgba(234, 27, 75, 0);
          border-top-color: var(--primary-color);
          border-width: 11px;
          margin-left: -11px;
        }

        &::before {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: var(--primary-background);
          border-width: 12px;
          margin-left: -12px;
        }
      }

      &-row {
        @include user-text-input-box;
      }

      .terms,
      .login {
        color: #fff;
        font-size: 14px;
      }

      .terms {
        margin-bottom: 10px;

        a:hover {
          color: var(--secondary-color);
        }
      }

      button.btn--blue {
        @include form-button;
        margin: 20px 0;
      }

      .divider {
        width: 100%;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 20px;
        background: var(--secondary-background-color);
      }
    }

    .creating-account {
      .create-details-row {
        .user-input-overlay {
          display: block;
        }
      }
    }
  }
}
