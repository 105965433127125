@use "src/sass/abstracts/styled-fonts" as *;

.base-input {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    @include font-body-medium-regular;
    color: var(--secondary-font-color);
    padding-left: 4px;
  }

  &.no-border input {
    border: none;
  }

  &.all-border {
    label {
      color: var(--primary-font-color);
      font-weight: 500;
      padding-left: 3px;
      padding-bottom: 5px;
    }

    input {
      color: var(--primary-font-color);
      height: 40px;
      padding: 10px;
      border: solid 1px var(--border-color);
      border-radius: 4px;

      &:focus {
        border-color: var(--secondary-color);
      }

      &::placeholder {
        color: var(--primary-font-color);
      }
    }
  }

  .required {
    font-size: 16px;
    color: var(--color-danger);
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .validation-container {
    position: absolute;
    top: calc(50% + 9px);
    right: 15px;
    transform: translateY(-50%);

    i {
      font-size: 10px;

      &.icon-eye,
      &.icon-eye-cross {
        cursor: pointer;
      }

      &.icon-eye {
        display: block;
        font-size: 7px;
        transform: translateY(-2px);
      }
    }

    > button ~ i {
      margin-left: 10px;
    }
  }

  .error-message {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;

    > p {
      @include font-body-medium-regular;
      color: var(--primary-color);
      white-space: nowrap;
    }
  }
}

.text-input {
  @extend .base-input;
  display: flex;
  flex-direction: row;
  &:disabled {
    opacity: 0.25;
  }

  &.search-icon {
    > i {
      font-size: 15px;
      position: absolute;
      bottom: 10px;
      left: 0;
    }

    input {
      padding-left: 20px;
    }

    &.all-border {
      > i {
        left: 10px;
        bottom: 14px;
      }

      input {
        padding-left: 30px;
      }
    }
  }

  input {
    @include font-body-medium-regular;
    height: 30px;
    background: transparent;
    color: var(--primary-font-color);

    &:focus::-webkit-input-placeholder {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }
}

.select-input {
  @extend .base-input;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &:disabled {
    opacity: 0.25;
  }

  &[data-is-secondary="true"] {
    justify-content: flex-start;

    > div {
      > button {
        p {
          font-size: 14px;
          color: var(--primary-color);
        }

        span {
          svg {
            width: 18px;
            path {
              fill: var(--primary-color);
            }
          }
        }
      }
    }
  }

  > div {
    @include options-list;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 4px;
    border-radius: 4px;

    ul {
      z-index: 100;
    }

    #voicemail, #forward {
      min-width: 240px;
    }

    p, input, input::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: var(--primary-font-color);
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: var(--secondary-font-color);
      }
    }

    input {
      width: 100%;
      padding: 5px 0 5px 5px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    > button {

      &[data-has-input='true'] {
        padding: 0 5px 0 0;

        > p {
          margin-left: 5px;
        }
      }

      &:hover, &:focus {

        input {
          background: var(--secondary-background);
        }
      }

      &:focus-within {
        input {
          background: var(--secondary-background);
        }
      }

      span {
        width: 24px;
        height: 24px;

        path {
          fill: var(--secondary-font-color);
        }
      }
    }

    .select-input__divider {
      border-radius: 0;
      width: 100%;
      margin: 0;
      border-bottom: 1px solid var(--border-color);
      color: var(--primary-font-color);
      pointer-events: none;

      &:hover {
        background: transparent;
        text-decoration: none;
        cursor: default !important;
      }
    }

    i {
      color: var(--tertiary-color);
      position: absolute;
      top: 12px;
      right: 2px;
    }
  }

  label {
    color: var(--primary-font-color);
    font-size: 14px;
  }
}

.slider-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 12px;
  transition: .2s;
  @include input-focus;

  &:disabled, &[data-locked="true"], &:has(input:disabled) {
    opacity: 0.25;
    pointer-events: none;
  }

  label {
    cursor: pointer;
    pointer-events: none;
  }

  &:hover:not(:disabled) {
    opacity: 0.7;
  }

  &:focus-within, &:focus, &:active {
    &:not(:disabled) {
      .slider.round {
        box-shadow: var(--primary-color-low-a) 0 0 0 2px;
      }
    }
  }

  &:has(*:focus-visible) {
    outline: 2px solid rgba(#33C3EE, 0.5);
  }

  &:hover {
    cursor: pointer;
  }

  p {
    @include font-body-medium-regular;
    margin-right: 10px;
  }
}