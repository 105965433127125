/* Red */
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;

/* Pink */
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;

/* Purple */
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;

/* Deep Purple */
$deep-purple-300: #9575cd;
$deep-purple-400: #7e57c2;
$deep-purple-500: #673ab7;
$deep-purple-600: #5e35b1;
$deep-purple-700: #512da8;
$deep-purple-800: #4527a0;
$deep-purple-900: #311b92;

/* Indigo */
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;

/* Blue */
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;

/* Light Blue */
$light-blue-300: #4fc3f7;
$light-blue-400: #29b6f6;
$light-blue-500: #03a9f4;
$light-blue-600: #039be5;
$light-blue-700: #0288d1;
$light-blue-800: #0277bd;
$light-blue-900: #01579b;

/* Cyan */
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;

/* Teal */
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;

/* Green */
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;

/* Light Green */
$light-green-300: #7cb342;
$light-green-400: #7cb342;
$light-green-500: #7cb342;
$light-green-600: #7cb342;
$light-green-700: #689f38;
$light-green-800: #558b2f;
$light-green-900: #33691e;

/* Lime */
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;

/* Yellow */
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;

/* Amber */
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;

/* Orange */
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;

/* Deep Orange */
$deep-orange-300: #ff8a65;
$deep-orange-400: #ff7043;
$deep-orange-500: #ff5722;
$deep-orange-600: #f4511e;
$deep-orange-700: #e64a19;
$deep-orange-800: #d84315;
$deep-orange-900: #bf360c;

/* Brown */
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;

/* Grey */
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

/* Blue Grey */
$blue-grey-300: #90a4ae;
$blue-grey-400: #78909c;
$blue-grey-500: #607d8b;
$blue-grey-600: #546e7a;
$blue-grey-700: #455a64;
$blue-grey-800: #37474f;
$blue-grey-900: #263238;

/* Background */

.brighten {
  filter: brightness(2);
}

/* Red */
.red-300 {
  background-color: #e57373 !important;
  fill: #e57373 !important;
}
.red-400 {
  background-color: #ef5350 !important;
  fill: #ef5350 !important;
}
.red-500 {
  background-color: #f44336 !important;
  fill: #f44336 !important;
}
.red-600 {
  background-color: #e53935 !important;
  fill: #e53935 !important;
}
.red-700 {
  background-color: #d32f2f !important;
  fill: #d32f2f !important;
}
.red-800 {
  background-color: #c62828 !important;
  fill: #c62828 !important;
}
.red-900 {
  background-color: #b71c1c !important;
  fill: #b71c1c !important;
}

/* Pink */
.pink-300 {
  background-color: #f06292 !important;
  fill: #f06292 !important;
}
.pink-400 {
  background-color: #ec407a !important;
  fill: #ec407a !important;
}
.pink-500 {
  background-color: #e91e63 !important;
  fill: #e91e63 !important;
}
.pink-600 {
  background-color: #d81b60 !important;
  fill: #d81b60 !important;
}
.pink-700 {
  background-color: #c2185b !important;
  fill: #c2185b !important;
}
.pink-800 {
  background-color: #ad1457 !important;
  fill: #ad1457 !important;
}
.pink-900 {
  background-color: #880e4f !important;
  fill: #880e4f !important;
}

/* Purple */
.purple-300 {
  background-color: #ba68c8 !important;
  fill: #ba68c8 !important;
}
.purple-400 {
  background-color: #ab47bc !important;
  fill: #ab47bc !important;
}
.purple-500 {
  background-color: #9c27b0 !important;
  fill: #9c27b0 !important;
}
.purple-600 {
  background-color: #8e24aa !important;
  fill: #8e24aa !important;
}
.purple-700 {
  background-color: #7b1fa2 !important;
  fill: #7b1fa2 !important;
}
.purple-800 {
  background-color: #6a1b9a !important;
  fill: #6a1b9a !important;
}
.purple-900 {
  background-color: #4a148c !important;
  fill: #4a148c !important;
}

/* Deep Purple */
.deep-purple-300 {
  background-color: #9575cd !important;
  fill: #9575cd !important;
}
.deep-purple-400 {
  background-color: #7e57c2 !important;
  fill: #7e57c2 !important;
}
.deep-purple-500 {
  background-color: #673ab7 !important;
  fill: #673ab7 !important;
}
.deep-purple-600 {
  background-color: #5e35b1 !important;
  fill: #5e35b1 !important;
}
.deep-purple-700 {
  background-color: #512da8 !important;
  fill: #512da8 !important;
}
.deep-purple-800 {
  background-color: #4527a0 !important;
  fill: #4527a0 !important;
}
.deep-purple-900 {
  background-color: #311b92 !important;
  fill: #311b92 !important;
}

/* Indigo */
.indigo-300 {
  background-color: #7986cb !important;
  fill: #7986cb !important;
}
.indigo-400 {
  background-color: #5c6bc0 !important;
  fill: #5c6bc0 !important;
}
.indigo-500 {
  background-color: #3f51b5 !important;
  fill: #3f51b5 !important;
}
.indigo-600 {
  background-color: #3949ab !important;
  fill: #3949ab !important;
}
.indigo-700 {
  background-color: #303f9f !important;
  fill: #303f9f !important;
}
.indigo-800 {
  background-color: #283593 !important;
  fill: #283593 !important;
}
.indigo-900 {
  background-color: #1a237e !important;
  fill: #1a237e !important;
}

/* Blue */
.blue-300 {
  background-color: #64b5f6 !important;
  fill: #64b5f6 !important;
}
.blue-400 {
  background-color: #42a5f5 !important;
  fill: #42a5f5 !important;
}
.blue-500 {
  background-color: #2196f3 !important;
  fill: #2196f3 !important;
}
.blue-600 {
  background-color: #1e88e5 !important;
  fill: #1e88e5 !important;
}
.blue-700 {
  background-color: #1976d2 !important;
  fill: #1976d2 !important;
}
.blue-800 {
  background-color: #1565c0 !important;
  fill: #1565c0 !important;
}
.blue-900 {
  background-color: #0d47a1 !important;
  fill: #0d47a1 !important;
}

/* Light Blue */
.light-blue-300 {
  background-color: #4fc3f7 !important;
  fill: #4fc3f7 !important;
}
.light-blue-400 {
  background-color: #29b6f6 !important;
  fill: #29b6f6 !important;
}
.light-blue-500 {
  background-color: #03a9f4 !important;
  fill: #03a9f4 !important;
}
.light-blue-600 {
  background-color: #039be5 !important;
  fill: #039be5 !important;
}
.light-blue-700 {
  background-color: #0288d1 !important;
  fill: #0288d1 !important;
}
.light-blue-800 {
  background-color: #0277bd !important;
  fill: #0277bd !important;
}
.light-blue-900 {
  background-color: #01579b !important;
  fill: #01579b !important;
}

/* Cyan */
.cyan-300 {
  background-color: #4dd0e1 !important;
  fill: #4dd0e1 !important;
}
.cyan-400 {
  background-color: #26c6da !important;
  fill: #26c6da !important;
}
.cyan-500 {
  background-color: #00bcd4 !important;
  fill: #00bcd4 !important;
}
.cyan-600 {
  background-color: #00acc1 !important;
  fill: #00acc1 !important;
}
.cyan-700 {
  background-color: #0097a7 !important;
  fill: #0097a7 !important;
}
.cyan-800 {
  background-color: #00838f !important;
  fill: #00838f !important;
}
.cyan-900 {
  background-color: #006064 !important;
  fill: #006064 !important;
}

/* Teal */
.teal-300 {
  background-color: #4db6ac !important;
  fill: #4db6ac !important;
}
.teal-400 {
  background-color: #26a69a !important;
  fill: #26a69a !important;
}
.teal-500 {
  background-color: #009688 !important;
  fill: #009688 !important;
}
.teal-600 {
  background-color: #00897b !important;
  fill: #00897b !important;
}
.teal-700 {
  background-color: #00796b !important;
  fill: #00796b !important;
}
.teal-800 {
  background-color: #00695c !important;
  fill: #00695c !important;
}
.teal-900 {
  background-color: #004d40 !important;
  fill: #004d40 !important;
}

/* Green */
.green-300 {
  background-color: #81c784 !important;
  fill: #81c784 !important;
}
.green-400 {
  background-color: #66bb6a !important;
  fill: #66bb6a !important;
}
.green-500 {
  background-color: #4caf50 !important;
  fill: #4caf50 !important;
}
.green-600 {
  background-color: #43a047 !important;
  fill: #43a047 !important;
}
.green-700 {
  background-color: #388e3c !important;
  fill: #388e3c !important;
}
.green-800 {
  background-color: #2e7d32 !important;
  fill: #2e7d32 !important;
}
.green-900 {
  background-color: #1b5e20 !important;
  fill: #1b5e20 !important;
}

/* Light Green */
.light-green-300 {
  background-color: #aed581 !important;
  fill: #aed581 !important;
}
.light-green-400 {
  background-color: #9ccc65 !important;
  fill: #9ccc65 !important;
}
.light-green-500 {
  background-color: #8bc34a !important;
  fill: #8bc34a !important;
}
.light-green-600 {
  background-color: #7cb342 !important;
  fill: #7cb342 !important;
}
.light-green-700 {
  background-color: #689f38 !important;
  fill: #689f38 !important;
}
.light-green-800 {
  background-color: #558b2f !important;
  fill: #558b2f !important;
}
.light-green-900 {
  background-color: #33691e !important;
  fill: #33691e !important;
}

/* Lime */
.lime-300 {
  background-color: #dce775 !important;
  fill: #dce775 !important;
}
.lime-400 {
  background-color: #d4e157 !important;
  fill: #d4e157 !important;
}
.lime-500 {
  background-color: #cddc39 !important;
  fill: #cddc39 !important;
}
.lime-600 {
  background-color: #c0ca33 !important;
  fill: #c0ca33 !important;
}
.lime-700 {
  background-color: #afb42b !important;
  fill: #afb42b !important;
}
.lime-800 {
  background-color: #9e9d24 !important;
  fill: #9e9d24 !important;
}
.lime-900 {
  background-color: #827717 !important;
  fill: #827717 !important;
}

/* Yellow */
.yellow-300 {
  background-color: #fff176 !important;
  fill: #fff176 !important;
}
.yellow-400 {
  background-color: #ffee58 !important;
  fill: #ffee58 !important;
}
.yellow-500 {
  background-color: #ffeb3b !important;
  fill: #ffeb3b !important;
}
.yellow-600 {
  background-color: #fdd835 !important;
  fill: #fdd835 !important;
}
.yellow-700 {
  background-color: #fbc02d !important;
  fill: #fbc02d !important;
}
.yellow-800 {
  background-color: #f9a825 !important;
  fill: #f9a825 !important;
}
.yellow-900 {
  background-color: #f57f17 !important;
  fill: #f57f17 !important;
}

/* Amber */
.amber-300 {
  background-color: #ffd54f !important;
  fill: #ffd54f !important;
}
.amber-400 {
  background-color: #ffca28 !important;
  fill: #ffca28 !important;
}
.amber-500 {
  background-color: #ffc107 !important;
  fill: #ffc107 !important;
}
.amber-600 {
  background-color: #ffb300 !important;
  fill: #ffb300 !important;
}
.amber-700 {
  background-color: #ffa000 !important;
  fill: #ffa000 !important;
}
.amber-800 {
  background-color: #ff8f00 !important;
  fill: #ff8f00 !important;
}
.amber-900 {
  background-color: #ff6f00 !important;
  fill: #ff6f00 !important;
}

/* Orange */
.orange-300 {
  background-color: #ffb74d !important;
  fill: #ffb74d !important;
}
.orange-400 {
  background-color: #ffa726 !important;
  fill: #ffa726 !important;
}
.orange-500 {
  background-color: #ff9800 !important;
  fill: #ff9800 !important;
}
.orange-600 {
  background-color: #fb8c00 !important;
  fill: #fb8c00 !important;
}
.orange-700 {
  background-color: #f57c00 !important;
  fill: #f57c00 !important;
}
.orange-800 {
  background-color: #ef6c00 !important;
  fill: #ef6c00 !important;
}
.orange-900 {
  background-color: #e65100 !important;
  fill: #e65100 !important;
}

/* Deep Orange */
.deep-orange-300 {
  background-color: #ff8a65 !important;
  fill: #ff8a65 !important;
}
.deep-orange-400 {
  background-color: #ff7043 !important;
  fill: #ff7043 !important;
}
.deep-orange-500 {
  background-color: #ff5722 !important;
  fill: #ff5722 !important;
}
.deep-orange-600 {
  background-color: #f4511e !important;
  fill: #f4511e !important;
}
.deep-orange-700 {
  background-color: #e64a19 !important;
  fill: #e64a19 !important;
}
.deep-orange-800 {
  background-color: #d84315 !important;
  fill: #d84315 !important;
}
.deep-orange-900 {
  background-color: #bf360c !important;
  fill: #bf360c !important;
}

/* Brown */
.brown-300 {
  background-color: #a1887f !important;
  fill: #a1887f !important;
}
.brown-400 {
  background-color: #8d6e63 !important;
  fill: #8d6e63 !important;
}
.brown-500 {
  background-color: #795548 !important;
  fill: #795548 !important;
}
.brown-600 {
  background-color: #6d4c41 !important;
  fill: #6d4c41 !important;
}
.brown-700 {
  background-color: #5d4037 !important;
  fill: #5d4037 !important;
}
.brown-800 {
  background-color: #4e342e !important;
  fill: #4e342e !important;
}
.brown-900 {
  background-color: #3e2723 !important;
  fill: #3e2723 !important;
}

/* Grey */
.grey-300 {
  background-color: #e0e0e0 !important;
  fill: #e0e0e0 !important;
}
.grey-400 {
  background-color: #bdbdbd !important;
  fill: #bdbdbd !important;
}
.grey-500 {
  background-color: #9e9e9e !important;
  fill: #9e9e9e !important;
}
.grey-600 {
  background-color: #757575 !important;
  fill: #757575 !important;
}
.grey-700 {
  background-color: #616161 !important;
  fill: #616161 !important;
}
.grey-800 {
  background-color: #424242 !important;
  fill: #424242 !important;
}
.grey-900 {
  background-color: #212121 !important;
  fill: #212121 !important;
}

/* Blue Grey */
.blue-grey-300 {
  background-color: #90a4ae !important;
  fill: #90a4ae !important;
}
.blue-grey-400 {
  background-color: #78909c !important;
  fill: #78909c !important;
}
.blue-grey-500 {
  background-color: #607d8b !important;
  fill: #607d8b !important;
}
.blue-grey-600 {
  background-color: #546e7a !important;
  fill: #546e7a !important;
}
.blue-grey-700 {
  background-color: #455a64 !important;
  fill: #455a64 !important;
}
.blue-grey-800 {
  background-color: #37474f !important;
  fill: #37474f !important;
}
.blue-grey-900 {
  background-color: #263238 !important;
  fill: #263238 !important;
}

/* Text */

/* Red */
.red-300-t { color: #e57373 !important; }
.red-400-t { color: #ef5350 !important; }
.red-500-t { color: #f44336 !important; }
.red-600-t { color: #e53935 !important; }
.red-700-t { color: #d32f2f !important; }
.red-800-t { color: #c62828 !important; }
.red-900-t { color: #b71c1c !important; }

/* Pink */
.pink-300-t { color: #f06292 !important; }
.pink-400-t { color: #ec407a !important; }
.pink-500-t { color: #e91e63 !important; }
.pink-600-t { color: #d81b60 !important; }
.pink-700-t { color: #c2185b !important; }
.pink-800-t { color: #ad1457 !important; }
.pink-900-t { color: #880e4f !important; }

/* Purple */
.purple-300-t { color: #ba68c8 !important; }
.purple-400-t { color: #ab47bc !important; }
.purple-500-t { color: #9c27b0 !important; }
.purple-600-t { color: #8e24aa !important; }
.purple-700-t { color: #7b1fa2 !important; }
.purple-800-t { color: #6a1b9a !important; }
.purple-900-t { color: #4a148c !important; }

/* Deep Purple */
.deep-purple-300-t { color: #9575cd !important; }
.deep-purple-400-t { color: #7e57c2 !important; }
.deep-purple-500-t { color: #673ab7 !important; }
.deep-purple-600-t { color: #5e35b1 !important; }
.deep-purple-700-t { color: #512da8 !important; }
.deep-purple-800-t { color: #4527a0 !important; }
.deep-purple-900-t { color: #311b92 !important; }

/* Indigo */
.indigo-300-t { color: #7986cb !important; }
.indigo-400-t { color: #5c6bc0 !important; }
.indigo-500-t { color: #3f51b5 !important; }
.indigo-600-t { color: #3949ab !important; }
.indigo-700-t { color: #303f9f !important; }
.indigo-800-t { color: #283593 !important; }
.indigo-900-t { color: #1a237e !important; }

/* Blue */
.blue-300-t { color: #64b5f6 !important; }
.blue-400-t { color: #42a5f5 !important; }
.blue-500-t { color: #2196f3 !important; }
.blue-600-t { color: #1e88e5 !important; }
.blue-700-t { color: #1976d2 !important; }
.blue-800-t { color: #1565c0 !important; }
.blue-900-t { color: #0d47a1 !important; }

/* Light Blue */
.light-blue-300-t { color: #4fc3f7 !important; }
.light-blue-400-t { color: #29b6f6 !important; }
.light-blue-500-t { color: #03a9f4 !important; }
.light-blue-600-t { color: #039be5 !important; }
.light-blue-700-t { color: #0288d1 !important; }
.light-blue-800-t { color: #0277bd !important; }
.light-blue-900-t { color: #01579b !important; }

/* Cyan */
.cyan-300-t { color: #4dd0e1 !important; }
.cyan-400-t { color: #26c6da !important; }
.cyan-500-t { color: #00bcd4 !important; }
.cyan-600-t { color: #00acc1 !important; }
.cyan-700-t { color: #0097a7 !important; }
.cyan-800-t { color: #00838f !important; }
.cyan-900-t { color: #006064 !important; }

/* Teal */
.teal-300-t { color: #4db6ac !important; }
.teal-400-t { color: #26a69a !important; }
.teal-500-t { color: #009688 !important; }
.teal-600-t { color: #00897b !important; }
.teal-700-t { color: #00796b !important; }
.teal-800-t { color: #00695c !important; }
.teal-900-t { color: #004d40 !important; }

/* Green */
.green-300-t { color: #81c784 !important; }
.green-400-t { color: #66bb6a !important; }
.green-500-t { color: #4caf50 !important; }
.green-600-t { color: #43a047 !important; }
.green-700-t { color: #388e3c !important; }
.green-800-t { color: #2e7d32 !important; }
.green-900-t { color: #1b5e20 !important; }

/* Light Green */
.light-green-300-t { color: #aed581 !important; }
.light-green-400-t { color: #9ccc65 !important; }
.light-green-500-t { color: #8bc34a !important; }
.light-green-600-t { color: #7cb342 !important; }
.light-green-700-t { color: #689f38 !important; }
.light-green-800-t { color: #558b2f !important; }
.light-green-900-t { color: #33691e !important; }

/* Lime */
.lime-300-t { color: #dce775 !important; }
.lime-400-t { color: #d4e157 !important; }
.lime-500-t { color: #cddc39 !important; }
.lime-600-t { color: #c0ca33 !important; }
.lime-700-t { color: #afb42b !important; }
.lime-800-t { color: #9e9d24 !important; }
.lime-900-t { color: #827717 !important; }

/* Yellow */
.yellow-300-t { color: #fff176 !important; }
.yellow-400-t { color: #ffee58 !important; }
.yellow-500-t { color: #ffeb3b !important; }
.yellow-600-t { color: #fdd835 !important; }
.yellow-700-t { color: #fbc02d !important; }
.yellow-800-t { color: #f9a825 !important; }
.yellow-900-t { color: #f57f17 !important; }

/* Amber */
.amber-300-t { color: #ffd54f !important; }
.amber-400-t { color: #ffca28 !important; }
.amber-500-t { color: #ffc107 !important; }
.amber-600-t { color: #ffb300 !important; }
.amber-700-t { color: #ffa000 !important; }
.amber-800-t { color: #ff8f00 !important; }
.amber-900-t { color: #ff6f00 !important; }

/* Orange */
.orange-300-t { color: #ffb74d !important; }
.orange-400-t { color: #ffa726 !important; }
.orange-500-t { color: #ff9800 !important; }
.orange-600-t { color: #fb8c00 !important; }
.orange-700-t { color: #f57c00 !important; }
.orange-800-t { color: #ef6c00 !important; }
.orange-900-t { color: #e65100 !important; }

/* Deep Orange */
.deep-orange-300-t { color: #ff8a65 !important; }
.deep-orange-400-t { color: #ff7043 !important; }
.deep-orange-500-t { color: #ff5722 !important; }
.deep-orange-600-t { color: #f4511e !important; }
.deep-orange-700-t { color: #e64a19 !important; }
.deep-orange-800-t { color: #d84315 !important; }
.deep-orange-900-t { color: #bf360c !important; }

/* Brown */
.brown-300-t { color: #a1887f !important; }
.brown-400-t { color: #8d6e63 !important; }
.brown-500-t { color: #795548 !important; }
.brown-600-t { color: #6d4c41 !important; }
.brown-700-t { color: #5d4037 !important; }
.brown-800-t { color: #4e342e !important; }
.brown-900-t { color: #3e2723 !important; }

/* Grey */
.grey-300-t { color: #e0e0e0 !important; }
.grey-400-t { color: #bdbdbd !important; }
.grey-500-t { color: #9e9e9e !important; }
.grey-600-t { color: #757575 !important; }
.grey-700-t { color: #616161 !important; }
.grey-800-t { color: #424242 !important; }
.grey-900-t { color: #212121 !important; }

/* Blue Grey */
.blue-grey-300-t { color: #90a4ae !important; }
.blue-grey-400-t { color: #78909c !important; }
.blue-grey-500-t { color: #607d8b !important; }
.blue-grey-600-t { color: #546e7a !important; }
.blue-grey-700-t { color: #455a64 !important; }
.blue-grey-800-t { color: #37474f !important; }
.blue-grey-900-t { color: #263238 !important; }

.inactive-user {
  color: var(--color-warning);
}
