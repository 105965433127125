@use "src/sass/abstracts/styled-fonts" as *;

.logo {
  position: absolute;
  top: 40px;
  left: 40px;
  height: 50px;
  max-width: 200px;
}

.startOverButton {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 4px 8px;
  border-radius: 4px;

  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.1);

    p {
      text-decoration: underline;
    }
  }

  p {
    color: var(--primary-font-color);
    font-weight: 400;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: var(--primary-background);
  border-radius: 20px;
  box-shadow: 5px 10px 50px rgba(38, 50, 56, 0.2);
  border: 1px solid var(--border-color);

  &.removeBackground {
    background: none;
    box-shadow: none;
  }

  > button {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;

    &:hover p {
      text-decoration: underline;
    }

    i {
      margin-right: 5px;
    }
  }

  h1, h2 {
    color: var(--auth-header-color);
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    @include font-body-medium-regular;
    color: var(--secondary-font-color);
  }

  button[data-role='a'], a, a:visited {
    @include font-body-medium-regular;
    color: var(--primary-font-color);
    text-decoration: underline;
    padding: 2px 4px;
    border-radius: 4px;

    &:hover, &:focus {
      background: var(--secondary-background);
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
  }
}

.authLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: var(--primary-background) !important;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    p {
      font-size: 16px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    rect {
      fill: transparent;
    }

  }
}

.schemaChange {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);

  > div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    background: var(--primary-background);
    opacity: .4;
    width: max-content;
    border-radius: 12px;
    padding: 25px;
    border: 3px dashed var(--border-color);
    transition: .2s;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div {
        &:hover {
          background: var(--primary-background);
        }
      }
    }

    &:hover {
      opacity: 1;
      background: var(--secondary-background);
    }
  }
}
