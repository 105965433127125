

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60px);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60px);
  }
}

@keyframes mini-slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-14px);
  }
}

@keyframes slide-back-left {
  0% {
    transform: translateX(-60px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
  }
}

@keyframes mini-slide-back-left {
  0% {
    transform: translateX(-14px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(60px);
  }
}

@keyframes mini-slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(14px);
  }
}

@keyframes slide-back-right {
  0% {
    transform: translateX(60px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
  }
}

@keyframes mini-slide-back-right {
  0% {
    transform: translateX(14px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bounce-in-fwd {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
  }
  43% {
    transform: scale(0.9);
    animation-timing-function: ease-out;
  }
  52% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  63% {
    transform: scale(0.9);
    animation-timing-function: ease-out;
  }
  90% {
    transform: scale(1.1);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
    height: 0;
  }
}

@keyframes loading-rings {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  animation: fade-in 0.2s linear both;

  .outer-circle {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: var(--secondary-background);
    animation: scale-up-center 0.4s linear both;

    .inner-circle {
      display: flex;
      margin: 125px 0 60px 0;
      animation: rotate-center 0.8s linear infinite 0.3s both;

      > div {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .grey {
        position: absolute;
        background: var(--tertiary-color);
        animation: 0.3s slide-left linear 0.4s both;
      }

      .orange {
        background: var(--secondary-color);
        z-index: 1;
      }

      .red {
        position: absolute;
        background: var(--primary-color);
        animation: 0.3s slide-right linear 0.4s both;
      }

      > img {
        position: absolute;
        top: 115px;
        left: 50px;
        width: 200px;
        z-index: 5;
        transform: scale(0);
      }
    }

    .loading-message {
      text-align: center;
      color: var(--secondary-font-color);
      width: 180px;
      font-size: 14px;
      line-height: 1.3;
      animation: 0.3s fade-in linear 0.5s both;
    }
  }
}

.finish-loading {
  animation: 0.2s fade-out linear 2.3s both;

  .outer-circle {
    animation: 0.4s scale-down-center linear 2s both;

    .inner-circle {
      animation-delay: 0.3s;
      animation-name: none;

      .grey {
        animation: 0.3s slide-back-left linear both;
      }

      .red {
        animation: 0.3s slide-back-right linear both;
      }

      .orange {
        animation: 0.3s fade-out linear both;
      }

      > img {
        animation: 0.7s bounce-in-fwd linear 0.6s both;
      }

      .pulsehd-loader {
        animation: 0.3s fade-out linear both;
      }
    }

    .loading-message {
      animation: 0.3s fade-out linear 0.2s both;
    }
  }
}

.mini-loading-spinner {
  width: 10px;
  margin-right: 20px;
  margin-left: 25px;

  .spinner-container {
    display: flex;
    animation: rotate-center 0.8s linear infinite 0.3s both;

    > div {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      min-height: 10px !important;
    }

    .grey {
      position: absolute;
      background: var(--primary-background);
      animation: 0.3s mini-slide-left linear 0.4s both;
    }

    .orange {
      background: var(--tertiary-color);
      z-index: 1;
    }

    .red {
      position: absolute;
      background: var(--primary-color);
      animation: 0.3s mini-slide-right linear 0.4s both;
    }
  }
}

.mini-finish-loading {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.8s both;

  .spinner-container {
    animation: 0.4s scale-down-center linear 0.6s both;

    .grey {
      animation: 0.3s mini-slide-back-left linear 0.4s both;
    }

    .orange {
      animation: 0.3s fade-out linear 0.4s both;
    }

    .red {
      animation: 0.3s mini-slide-back-right linear 0.4s both;
    }
  }
}

.pulsehd-loading-spinner {
  @extend .loading-spinner;
  background: rgba(71, 53, 93, 0.9);

  .outer-circle {
    position: relative;
    background: none;

    .inner-circle {
      animation: none;

      .pulsehd-loader {
        position: absolute;
        top: 55px;
        transform: scale(1);
      }
    }

    .loading-message {
      color: #fff;
      margin-top: 60px;
    }
  }
}

.loading-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 18px;
    height: 18px;
    margin: 3px;
    border: 3px solid;
    border-radius: 50%;
    animation: loading-rings 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-success) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
