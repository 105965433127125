@use "src/sass/abstracts/styled-fonts" as *;

.notification-bubble {
  display: flex;
  align-items: center;
  padding: 7px;
  height: 17px;
  min-width: 17px;
  background: var(--notification-color);
  border-radius: 30px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;

  p {
    @include font-body-small-regular;
    position: relative;
    color: var(--font-color-white) !important;
    font-size: 10px;
    z-index: 2;
    bottom: 1px;

    span {
      color: var(--font-color-white);
      font-size: 9px;
    }
  }
}
