

#login-congratulations {
  display: flex;
  height: 100vh;

  .container {
    height: unset;

    > img {
      margin: 0 155px 50px 155px;
    }

    .congratulations-message {
      justify-content: center;
      margin: 0 60px;

      > h1 {
        margin-bottom: 20px;
        animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }

      > p {
        font-size: 18px;
        margin-bottom: 20px;
      }

      > div > p {
        margin: 10px 0;
      }

      button {
        color: #fff;
        font-size: 16px;
        width: 340px;
        height: 50px;
        border-radius: 3px;

        &:last-child {
          color: var(--tertiary-color);
        }
      }
    }
  }
}
