.markdown-strong {
  font-weight: 700;
}

.markdown-tag {
  display: inline;
  position: relative;
  cursor: pointer;
  transition: .2s;
  background: var(--primary-color);
  border-radius: 4px;
  padding: 1px 4px;
  margin: 4px;
  outline: 1px solid transparent;
  font-weight: 700 !important;
  text-decoration: none !important;
  font-style: normal !important;
  color: var(--font-color-white) !important;
  &:hover {
      outline: 1px solid var(--border-color);
  }
  * {
    font-style: normal !important;
    text-decoration: none !important;
  }
}
