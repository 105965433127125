@use "src/sass/abstracts/styled-fonts" as *;

.recent-calls {
  flex: 1;

  div[data-filter='true'] {

    width: 400px;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .menu-search {
    background: none;
    margin-right: 10px;

    input {
      background: none;
    }
  }

  .phone_page_empty_display {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > p {
      @include font-body-large-medium;
      color: var(--primary-font-color);
    }

    > span {
      @include font-body-medium-medium;
      color: var(--secondary-font-color);
    }
  }
}
