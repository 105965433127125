button {
  font: 16px var(--font-family), sans-serif;
  font-weight: 400;
  cursor: pointer;
  background: none;

  > p {
    font-family: var(--font-family), sans-serif;
  }
}

.button {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  padding: 12px 20px 10px;
  background: var(--primary-background);
  border: 1px solid var(--border-color);
  color: var(--primary-font-color);
  border-radius: 4px;

  &:hover, &:focus {
    background: var(--secondary-background);
    filter: none;
  }

  span {
    &:first-child {
      animation: dot-pulse 1.4s linear infinite both;
    }

    &:nth-child(2) {
      animation: dot-pulse 1.4s linear 0.2s infinite both;
    }

    &:last-child {
      animation: dot-pulse 1.4s linear 0.4s infinite both;
    }
  }

  &.loading {
    pointer-events: none;
  }

  &.info {
    background: none;
    border: 1px solid var(--color-info);
    color: var(--color-info);

    &:hover,&:focus {
      color: var(--font-color-white);
      background: var(--color-info);
    }
  }

  &.success {
    background: var(--color-success);
    border: 1px solid var(--color-success);
    color: var(--font-color-white);

    &:hover,&:focus {
      color: var(--font-color-white);
      background: var(--color-success-dark);
      border-color: var(--color-success-dark);
    }
  }

  &.warning {
    background: none;
    border: 1px solid var(--color-warning);
    color: var(--color-warning);

    &:hover,&:focus {
      color: var(--font-color-white);
      background: var(--color-warning);
    }
  }

  &.danger {
    background: none;
    border: 1px solid var(--color-danger);
    color: var(--color-danger);

    &:hover,&:focus {
      color: var(--font-color-white);
      background: var(--color-danger-dark);
      border-color: var(--color-danger-dark);
    }
  }

  &.white {
    color: var(--primary-font-color);
    background: var(--primary-background);
    padding: 11px 20px 9px;
    border: 1px solid var(--border-color);

    &:hover {
      color: var(--primary-background);
      background: var(--secondary-color);
    }
  }

  &.shadow {
    color: var(--primary-font-color);
    background: var(--primary-background);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover,&:focus {
      background: #f3f3f3;
    }

    &.active,
    &:active {
      background: #f3f3f3;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &.underline {
    color: var(--primary-font-color);
    text-decoration: underline;
    padding: 0;
    background: none;

    &:hover,&:focus {
      text-decoration: none;
    }
  }

  &.small {
    height: 30px;
    padding: 5px 10px;
    line-height: 1;
  }

  .button__error {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;

    > p {
      color: var(--primary-color);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.15;
      white-space: normal;
    }
  }

  .button__popup {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    max-width: 100%;
    padding: 10px 20px;
    background: #fff;
    border: 1px solid var(--color-success);
    border-radius: 4px;
    box-shadow: 0 5px 13px 0 rgba(51, 41, 37, 0.08);
    pointer-events: none;

    &.no-title {
      top: -43px;

      h6 {
        margin-bottom: 0;
      }
    }

    &:before,
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-top-color: var(--color-success);
      border-width: 11px;
      margin-left: -11px;
    }

    &:after {
      border-top-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }

    h6 {
      color: var(--color-success);
      text-align: center;
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 2px;
    }

    p {
      font-size: 12px;
      line-height: 1.15;
      text-align: center;
      white-space: normal;
    }

    &.success {
      border-color: var(--color-success);

      h6 {
        color: var(--color-success);
      }

      &:before {
        border-top-color: var(--color-success);
      }
    }

    &.warning {
      border-color: var(--color-warning);

      h6 {
        color: var(--color-warning);
      }

      &:before {
        border-top-color: var(--color-warning);
      }
    }

    &.info {
      border-color: var(--tertiary-color);

      h6 {
        color: var(--tertiary-color);
      }

      &:before {
        border-top-color: var(--tertiary-color);
      }
    }
  }

  .loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: 4px;

    svg {
      height: 24px;
    }
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.btn {
  color: #fff;
  background: var(--tertiary-color);

  p {
    color: #fff;
  }
}

.btn--blue {
  @extend .btn;
  background: var(--tertiary-color);
}

.btn--red {
  @extend .btn;
  background: var(--primary-color);
}

.btn--link {
  color: #fff;
  text-decoration: underline;
  background: none;

  &:hover,&:focus {
    color: var(--tertiary-color);
  }
}

.dialpad-button {
  &.active button {
    background: var(--primary-font-color);

    i,
    h3,
    p {
      color: #fff;
    }
  }

  &.beingTransferredTo {
    > button {
      background: var(--tertiary-color);
    }

    i,
    h3,
    p {
      color: #fff;
    }
  }

  > button {
    transition: .2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid white;
    cursor: pointer;

    &:active {
      background: var(--primary-color);
      box-shadow: inset -1px 1px 15px -5px rgba(0, 0, 0, 0.25);

      i,
      h3,
      p {
        color: #fff;
      }
    }

    &:hover {
      background: rgba(0,0,0,0.2);
      i {
        color: var(--font-color-white);
      }
      h3 {
        color: var(--font-color-white);
      }
    }

    i {
      color: var(--font-color-white);
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: var(--font-color-white);
      user-select: none;
    }

    p {
      letter-spacing: 1px;
      font-size: 5px;
      height: 10px;
      margin-top: -2px;
      font-weight: 300;
      user-select: none;
    }
  }

  > p {
    font-size: 10px;
    color: #fff;
    text-align: center;
    margin-top: 10px;
  }
}

.dialpad-button--start-call {
  @extend .dialpad-button;

  button {
    background: var(--color-success);

    &:hover,&:focus {
      background: var(--tertiary-color);
    }

    i {
      font-size: 28px;
      color: #fff;
      margin-left: 2px;
    }
  }
}

.dialpad-button--transfer {
  @extend .dialpad-button;

  button {
    background: var(--tertiary-color);

    &:hover,&:focus {
      background: var(--color-success);
    }

    i {
      font-size: 26px;
      color: #fff;
      margin-left: 2px;
    }
  }
}

.dialpad-button--decline {
  @extend .dialpad-button;

  button {
    background: var(--primary-color);

    &:hover,&:focus {
      background: var(--tertiary-color);
    }

    i {
      font-size: 26px;
      color: #fff;
    }
  }
}

.dialpad-button--text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  margin: 0 auto;

  &:hover,&:focus {
    p {
      color: var(--tertiary-color);
    }
  }

  p {
    font-size: 11px;
  }
}

.dialpad-button--backspace {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 28px;
    cursor: pointer;
  }
}
