
.skeleton {
  background-color: var(--secondary-background);
  overflow: hidden;
  position: absolute;
  border-radius: 4px;

  &:after {
    background: linear-gradient(90deg, transparent, var(--primary-background), transparent);
    transform: translateX(-100%);
    position: absolute;
    content: ' ';
    inset: 0;
    animation: skeleton-wave 1.6s linear 0.5s infinite normal none running;

  }
}

@keyframes skeleton-wave {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}