@use "src/sass/abstracts/mixins" as *;

.container {
  display: flex;
  height: 100vh;
  background: var(--tertiary-background);
  color: var(--primary-font-color);
  justify-content: center;

  @include small-screens {
    justify-content: start;
  }

  &[data-is-browser='true'] {
    @media only screen and (min-width: 1550px) {
      padding: 24px 0;
      >div {
        border-radius: 0 8px 8px 0;
      }
    }

    >div {
      max-width: 1490px;
      overflow: clip;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > main {
      width: 100%;

      aside {
        min-width: 250px;
        transition: width .3s ease-out;

        .resizer_container {
          width: 8px;
          position: absolute;
          top: 0;
          height: 100%;
          cursor: col-resize;
          transition: filter 0.6s, width 0.1s;

          .resizeHandle {
            width: 1px;
            height: 100%;
            background: var(--primary-color);
            box-shadow: 0 0 0 0 var(--primary-color);
            display: block;
            left: calc(50% - 1px);
            position: relative;
            opacity: 0;
            transition: .2s;
            transform: translateX(-50%);
          }
          &:hover, &.active {
            .resizeHandle {
              opacity: 0.5;
              box-shadow: 0 0 0 1px var(--primary-color);
            }
          }
          &.active {
            .resizeHandle {
              box-shadow: 0 0 0 0 var(--primary-color);
              width: 1px;
              opacity: 1;
            }
          }
        }
      }


    }
  }
}
