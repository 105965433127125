

.global-search {
  .contact-card {
    > div:first-of-type p:not(.chat-preview-time) {
      font-weight: 500;
    }
  }

  .empty-placeholder {
    padding: 15px 10px !important;
    border-bottom: 1px solid var(--border-color);
  }
}
