@use "./functions" as *;

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
    @if (type-of($value) == color) {
      #{$key}-rgb: #{hexToRGB($value)};
    }
  }
}
