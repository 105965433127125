

.recent-parking {
  color: var(--primary-font-color);
  height: calc(100% - 136px);
  margin-top: -12px;

  .recent-parking__row {
    display: flex;
    align-items: center;
    align-self: flex-end;
    height: 50px;
    margin: 0 10px;
    border-bottom: solid 1px var(--border-color);

    strong {
      font-weight: 500;
    }

    &.active-call {
      cursor: pointer;

      &:active {
      }
    }

    &:last-child {
      border-bottom: solid 1px var(--border-color);
    }

    > div {
      margin: 0 10px;

      &:last-child {
        width: 20px;
        cursor: pointer;
      }
    }

    button {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      margin: 0 4px;

      path {
        fill: var(--color-success);
      }

      &:hover, &:focus {
        background: var(--secondary-background);
      }
    }

    > p {
      font-size: 14px;

      &:nth-child(2) {
        color: var(--primary-font-color);
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 240px;
      }

      &:nth-child(3) {
        color: var(--primary-font-color);
        margin-right: 20px;
        margin-left: auto;
      }

      &:nth-child(4) {
        color: var(--primary-color);
        width: 26px;
        font-size: 20px;
      }
    }
  }

  .active {
    cursor: initial;
  }
}
