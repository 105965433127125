

.notification-container {
  position: fixed;
  top: 100px;
  right: 20px;
  max-width: 400px;
  z-index: 20;
}

.notification {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  padding: 15px;
  margin-bottom: 10px;
  margin-left: auto;
  background: var(--primary-background);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15), 2px 5px 15px rgba(38, 50, 56, 0.15);

  .notification_close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 12px;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    margin-top: 12px;

    &:hover, &:focus {
      background: var(--secondary-background);
    }

    span {
      height: 20px;
    }

    path {
      fill: var(--color-danger);
    }
  }

  .notification__availability-buttons {
    display: flex;
    gap: 16px;
    margin: 6px auto 0;

    button {
      color: var(--color-success);
      font-size: 14px;
      padding: 4px 6px;
      border-radius: 6px;

      &:nth-of-type(2) {
        color: var(--color-danger);
      }

      &:hover, &:focus {
        text-decoration: underline;
        background: var(--secondary-background);
      }
    }
  }

  &.warning {
    background: var(--tertiary-color);

    i.icon-cross {
      color: var(--primary-background);

      &:hover {
        color: var(--secondary-color);
      }
    }


    p {
      color: var(--font-color-white);
    }
  }

  &.danger {
    padding: 15px;
    background: var(--color-danger);

    i {
      color: var(--secondary-background);
    }

    p {
      color: var(--secondary-background);
    }

    .notification_close {
      path {
        fill: var(--secondary-background);
      }

      &:hover, &:focus {
        background: rgba(255, 255, 255, 0.75);

        path {
          fill: var(--color-danger);
        }
      }
    }
    }

  > div {
    display: flex;

    &.cross {
      padding-right: 24px;
    }

    svg,
    img {
      height: 100%;
      margin-top: -5px;
      margin-right: 10px;
    }

    > div {
      p {
        color: var(--primary-font-color);
        font-size: 14px;
        line-height: 1.15;

        &.notification-title {
          font-family: var(--font-family), sans-serif;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 7px;
        }
      }
    }
  }

  > button {
    margin-top: 10px;
  }

  i.icon-cross {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 10px;
    cursor: pointer;

    &:hover {
      color: var(--tertiary-color);
    }
  }

  &[data-type='chat-message'] {
    transition: .2s;
    background: var(--secondary-color-dark);
    cursor: pointer;
    &:hover {
      background: var(--secondary-color);
    }
    .notification_close {
      span svg path {
        fill: white;
      }
      &:hover {
        span svg path {
          fill: var(--secondary-color);
        }
      }
    }
  }
}
