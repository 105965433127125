@use "src/sass/abstracts/abstracts" as *;

.container {
  position: relative;
  width: 360px;
  margin: 0;

  >div:first-of-type {
    >div {
      margin: 16px 16px 0;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;

    button {
      position: relative;
      display: flex;
      padding: 2px 12px;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      transition: .2s;
      border-radius: 4px;
      height: 34px;

      .optionIcon {
        @include icon-with-background;

        span svg {
          width: 22px;
          path {
            stroke-width: 2.4px;
            stroke: var(--secondary-font-color);
            fill: transparent;
          }
        }
      }

      &:hover {
        background: var(--secondary-background);
      }

      &[data-active='active'] {
        background: var(--secondary-background);

        p {
          color: var(--primary-font-color);
        }

        span svg {
          path {
            fill: var(--primary-font-color);
            stroke: transparent;
          }
        }
      }

      p {
        @include font-body-medium-medium;
        color: var(--secondary-font-color);
      }

      .arrow {
        position: absolute;
        right: 10px;

        path {
          fill: var(--secondary-font-color);
        }
      }
    }
  }

  .extraOptions {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;

    button {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      gap: 10px;
      border-radius: 4px;
      height: 34px;

      span svg {
        width: 27px;
      }

      &:hover, &:focus, &[data-active='active'] {
        background: var(--secondary-background);
      }

      &:disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }

      p {
        font-size: 14px;
        font-weight: 400;

        &.warning {
          color: var(--color-danger);
        }

        span {
          color: var(--secondary-font-color);
          @include font-body-small-regular;
        }
      }

      path {
        fill: var(--border-color);
      }

      &.logout {
        path {
          fill: var(--color-danger);
        }
      }

      &.userSwitchBtn {
        path {
          fill: var(--primary-color);
        }
      }

    }
  }

  .version {
    @include font-body-small-regular;
    color: var(--secondary-font-color);
    position: absolute;
    bottom: 20px;
    left: 8px;
    opacity: 0.8;

    //p {
    //  text-decoration: underline;
    //}
  }
}
