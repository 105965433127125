
@use "src/sass/abstracts/mixins" as *;

#login_verification {
  display: flex;
  height: 100vh;

  .container {
    height: unset;
    text-align: center;

    > div {
      margin: 0 auto 20px;

      > div {
        margin: 0 auto 20px;
      }
    }

    .input-box p {
      text-align: left;
    }

    .row {
      display: flex;

      .exit {
        padding-top: 25px;

        i {
          color: #fff;
          font-size: 24px;
          cursor: pointer;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    .success-message {
      font-size: 20px;
    }

    .verification-message {
      width: 340px;
      height: 50px;
      text-align: center;

      > p {
        font-size: 20px;
        line-height: 1.2;
        color: #ffffff;
      }
    }

    .input-box {
      @include user-text-input-box;
    }

    .login-verification-button > button {
      @include form-button;
      background: var(--secondary-background);
      color: var(--secondary-color);
      &:hover {
        background: var(--secondary-background);
        color: var(--secondary-color-dark);
      }
    }

    .resend-verification {
      display: flex;
      width: 340px;

      > p {
        font-size: 14px;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;

        &:last-of-type {
          margin-left: auto;
        }
      }
    }

    .verification-loading {
      > p {
        width: 250px;
        margin: 30px auto;
        font-size: 14px;
      }

      .circle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        width: 200px;
        height: 200px;
        margin: 0 auto;
        background: #fff;
        border-radius: 50%;

        > p {
          color: #837470;
          font-size: 12px;
          width: 90px;
          margin-bottom: 25px;
        }

        > div {
          display: flex;
          flex-direction: row;
          margin-bottom: 37px;

          .dot {
            width: 26px;
            height: 26px;
            border-radius: 50%;

            &:first-child {
              animation: dot-pulse 1.4s linear infinite both;
            }

            &:nth-of-type(2) {
              margin: 0 10px;
              animation: dot-pulse 1.4s linear 0.2s infinite both;
            }

            &:last-child {
              animation: dot-pulse 1.4s linear 0.4s infinite both;
            }
          }

          .grey {
            background: var(--secondary-font-color);
          }

          .orange {
            background: var(--tertiary-color);
          }

          .red {
            background: var(--primary-color);
          }
        }
      }
    }
  }
}
