

.user-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: solid 2px var(--border-color);
  background-color: #fff;
}

//Margin indents for incoming, outgoing & internal recent calls
.m-l-20 {
  margin-left: 20px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.disable-element {
  cursor: unset !important;
  pointer-events: none !important;
  opacity: 0.6;
}

.no-pointer,
.disabled {
  cursor: unset !important;
  pointer-events: none !important;
}

//When clicking on a phone-book row, changes background color.
.active-row {
  border: none !important;
  background: var(--secondary-background-color);
}

//When applied as class name, changes border colors to respective names - used as a status indicator
.border-green {
  &:after {
    background: var(--color-success) !important;
  }
}

.border-orange {
  &:after {
    background: var(--color-warning) !important;
  }
}

.border-red {
  &:after {
    background: var(--primary-color) !important;
  }
}

.border-default {
  &:after {
    background: var(--border-color) !important;
  }
}

.border-early {
  animation: yay-early-border 0.5s linear 0s infinite alternate;
}

.text-color-red {
  color: var(--color-danger);
}

.text-color-orange {
  color: var(--tertiary-color);
}

.text-color-green {
  color: var(--color-success);
}

.text-color-blue {
  color: var(--secondary-color);
}

.background--green {
  background: var(--color-success);
}

.background--amber {
  background: var(--color-warning);
}

.background--red {
  background: var(--color-danger);

  &-i {
    background: var(--color-danger) !important;
  }
}

.background--blue {
  background: var(--secondary-color);
}

.display-none {
  display: none;

  &-i {
    display: none !important;
  }
}

.border--none {
  border: none !important;
}

.animation--none {
  animation: none !important;
}

.user-input-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: var(--primary-font-color);
  opacity: 0.2;
  border-radius: 4px;
}

.help-bubble {
  visibility: hidden;
  position: absolute;
  display: flex;
  left: -23px;
  bottom: 32px;
  padding: 6px 12px;
  background: #fff;
  border: solid 1px var(--color-danger);
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 50;

  p {
    font-size: 8px;
    width: fit-content;
    white-space: nowrap;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 38%;
    transform: translateY(10px);
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::before {
    top: 10px;
    border-color: var(--color-danger) transparent transparent transparent;
    border-width: 8px;
  }

  &::after {
    top: 9px;
    border-color: #fff transparent transparent transparent;
    border-width: 8px;
  }
}

@keyframes yay-early-border {
  0% {
    border-color: var(--color-success);
  }
  20% {
    border-color: var(--color-success);
  }
  80% {
    border-color: var(--color-warning);
  }
  100% {
    border-color: var(--color-warning);
  }
}
