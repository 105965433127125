.audio_recorder_container {
  position: fixed;
  z-index: 5;

  .audio_recorder {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    background: var(--primary-background);
    transform: translateX(-50%);
    border-radius: 4px;
    padding: 12px;
    border: 1px solid var(--border-color);
    left: 0;
    bottom: 0;
    width: 300px;
    height: 50px;

    .loader {
      width: 180px;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;

      svg {
        width: 20px;
        path {
          stroke: var(--primary-color);
        }
      }
    }

    .audio_duration {
      color: var(--secondary-font-color);
      width: 40px;
      text-align: left;
      position: absolute;
      right: 30px;
      top: 19px;
      font-size: 12px;
    }

    .button_close {
      border-radius: 4px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      transition: .2s;
      position: absolute;
      right: 10px;

      span svg {
        path {
          fill: var(--secondary-font-color);
        }
      }

      &:hover {
        background: var(--secondary-background);
      }
    }
  }
}
