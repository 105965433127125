
.container {
  position: relative;
  width: max-content;
  display: grid;
  grid-template-columns: [one] 1fr [two];
  grid-template-rows: [one] 1fr [two];
  justify-content: flex-end;

  p {
    grid-column: one / two;
    grid-row: one / two;
    text-align: right;

    &[data-animate='true'] {
      &:nth-of-type(1) {
        animation: textCycle 12s infinite ease;
      }

      &:nth-of-type(2) {
        animation: textCycle 12s infinite ease;
        animation-delay: -6s;
      }
    }
  }
}

@keyframes textCycle {
  0% {
    opacity: 1;
  }
  17.5% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 1
  }
  100% {
    opacity: 1;
  }
}

