@use "src/sass/abstracts/styled-fonts" as *;

.container {

  .meeting_row {
    padding: 0 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    height: 60px;
    margin: 0 16px 8px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    &:hover, &:focus-within, &:focus, [data-active='true'] {
      background: var(--secondary-background);
    }

    p {
      @include font-body-medium-medium;
      color: var(--primary-font-color);
    }

    span {
      @include font-body-small-medium;
      color: var(--secondary-font-color);
    }

    &[data-skeleton='true'] {
      pointer-events: none;
      border: none;
      position: relative;
    }
  }

  .id_join {
    position: relative;

    .id_join_pop {
      position: absolute;
      top: calc(100% + 8px);
      width: 420px;
      background: var(--primary-background);
      z-index: 1;

      > div {
        align-items: flex-start;
        padding: 24px;
      }
    }
  }
}