.container {
  width: 100%;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  .main_info {
    text-align: center;
    position: relative;
    width: 100%;
    padding-top: 25px;
    margin-bottom: -15px;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -16px;
      p {
        font-size: 18px;
        font-weight: 500;
        color: var(--primary-font-color);
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
      }

      span {
        font-weight: 400;
        letter-spacing: 0.01em;
        font-size: 14px;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .actions_container {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 0;

      button {
        border-radius: 4px;
        width: 32px;
        height: 32px;
        transition: .2s;
        z-index: 1;

        span svg path {
          transition: .2s;
        }

        &:hover:not(:disabled) {
          background: var(--secondary-background);
        }

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }

        &.button_add:not(:disabled) {
          span svg {
            path {
              fill: var(--color-success);
            }
          }
        }

        &.button_cancel {
          span svg path {
            fill: var(--color-danger);
          }
        }
      }
    }
  }

  .details_list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: -12px;
    padding-bottom: 12px;

    .detail_row_container {
      background: blue;
      background: var(--secondary-background);
      padding: 12px;
      border-radius: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: .2s;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &:hover {
        border-color: var(--border-color);
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
      }

      span {
        font-size: 14px;
        letter-spacing: 0.01em;
        color: var(--primary-color);
        font-weight: 400;
      }

      &[data-is-selected="false"] {
        opacity: 0.6;
        background: var(--secondary-background);
      }
    }
  }
}
