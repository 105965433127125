@use "src/sass/abstracts/styled-fonts" as *;

.voicemail_section {
  width: 100%;
  max-height: calc(100% - 72px);
  container: voicemail-container / inline-size;

  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-areas: "tabs" "messages";

  > .opts {
    max-width: calc(100% - 16px);
    margin: 0 8px 8px;
    display: grid;
    grid-template-columns: max-content 1fr;
    place-items: center left;
    gap: 4px;
  }

  .tab_node {
    display: flex;
    align-items: baseline;
    gap: 4px;
    max-width: 100%;

    i {
      @include font-body-small-bold;
      background: var(--notification-color);
      border-radius: 23px;
      padding: 2px 4px;
      height: 14px;
      font-size: 10px;
      color: var(--font-color-white);
      display: flex;
      align-items: center;
      margin-left: 4px;
      width: max-content;
    }
  }

  > .messages {
    grid-area: messages;
    padding: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    flex: 1;
    max-height: 100%;
    overflow: hidden auto;

    @container (width < 640px) {
      grid-template-columns: 1fr;

      .divider {
        grid-column: 1/2 !important;
      }
    }

    .divider {
      width: 100%;
      margin: 0 0 0 -8px;
      grid-column: 1 / 3;
    }

    .voicemail_item + .divider {
      margin-top: 12px;
    }

    .voicemail_item {
      width: 100%;
      border: 1px solid var(--border-color);
      padding: 12px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: max-content;
      overflow: hidden;

      &[data-error='true'] {
        border-color: var(--color-danger);
      }

      .call_data {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        > div {
          > p {
            display: flex;
            align-items: baseline;
            gap: 4px;

            > button {
              width: max-content;
              height: max-content;

              > span {
                background: var(--notification-color);
                color: var(--font-color-white);
                border-radius: 50px;
                padding: 2px 4px;
              }
            }
          }
        }

        p {
          @include font-body-large-regular;
          display: flex;
          align-items: baseline;
          transition: opacity 0.2s ease-out;
        }

        span {
          @include font-body-small-medium;
          color: var(--secondary-font-color);
        }

        .options {
          position: absolute;
          right: -64px;
          top: 0;
          display: flex;
          align-items: center;
          gap: 4px;
          opacity: 0;
          transition: opacity 0.2s ease-out, right 0.2s ease-out;

          button {
            width: 26px;
            height: 26px;

            > div > svg {
              margin-top: 2px;
              width: 20px;
              height: 20px;
            }

            span, svg {
              width: 24px;
              height: 24px;
            }

            &:not([data-context='danger']) {
              path {
                fill: var(--secondary-font-color);
              }
            }
          }
        }
      }

      .player {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;

        &[data-error='true'] {
          height: 28px;

          p {
            color: var(--color-danger);
          }
        }

        > button {
          width: 22px;
          height: 22px;
          border-radius: 50%;

          > div {
            display: grid;
            align-items: center;

            > svg {
              width: 16px;
              height: 16px;
            }
          }

          > div, span, svg {
            width: 20px;
            height: 20px;

            path {
              fill: var(--secondary-font-color);
            }
          }
        }

        > p {
          @include font-body-small-medium;
          color: var(--secondary-font-color);
        }
      }

      .transcription {
        cursor: pointer;

        &:has(h6) {
          cursor: auto;
        }

        > button {
          margin: 0 0 4px -8px;

          &:hover {
            background: none;
          }

          > p {
            color: var(--secondary-font-color);
            @include font-body-small-medium;
          }

          span, svg {
            width: 16px;
            height: 16px;

            path {
              fill: var(--secondary-font-color);
            }
          }
        }

        > h6 {
          @include font-body-small-medium;
          color: var(--secondary-font-color);
          padding: 4px 8px;
          margin: 0 0 4px -8px;
        }

        > p {
          @include font-body-small-medium;
          letter-spacing: 0.048px;

          &.clamp {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      &:hover, &:focus-within {
        .call_data {
          > p {
            opacity: 0;
          }

          .options {
            opacity: 1;
            right: 0;
          }
        }
      }
    }
  }

  > [data-skeleton='true'] {
    overflow: hidden;

    span {
      position: relative !important;
    }

    .divider {
      width: 140px;
    }

    .voicemail_item {
      border: none;
      height: 164px;

      .call_data {
        > div {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }
    }
  }
}