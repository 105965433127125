
.visualizer_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4px;
  height: 30px;
  width: 210px;

  &[data-is-mini="true"] {
    width: 180px;
  }
}

.bar {
  border-radius: 8px;
  content: "";
  width: 4px;
  background: var(--primary-color);
  transition: .2s;
}

.bar_empty {
  opacity: 1 !important;
  height: 33% !important;
  border-radius: 5px;
  content: "";
  width: 4px;
  background: var(--border-color);
}
