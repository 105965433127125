.reply_to_container {
  position: relative;
  padding: 4px;
  width: fit-content;
  .primary_action_container {
    position: absolute;
    top: -8px;
    right: 18px;
    z-index: 1;

    &[data-is-download="false"] {

      button {
        position: absolute;
        border: 1px solid var(--secondary-font-color);
        background: var(--primary-background);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transition: .2s;

        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          z-index: -1;
          opacity: 0;
        }

        span {
          svg {
            position: relative;
            bottom: 3px;
            width: 14px;
            transition: .2s;

            path {
              fill: var(--secondary-font-color);
            }
          }
        }

        &:hover {
          border-color: var(--color-danger);

          span svg {
            path {
              fill: var(--color-danger);
            }
          }
        }
      }
    }
  }
}
