@use "src/sass/abstracts/styled-fonts" as *;

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px;
  gap: 24px;
  background: var(--primary-background);
  border: 1px solid var(--border-color);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 444px;
  color: var(--primary-font-color);
  max-height: 90%;
  overflow: hidden;

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-height: 100%;
  }

  .formHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 32px 1fr 32px;
    grid-template-areas: 'back title next' 'p p p';
    align-items: center;
    justify-items: center;

    p {
      @include font-body-small-regular;
      color: var(--color-danger);
      grid-area: p;
    }

    h2 {
      @include font-body-large-regular;
      color: var(--primary-font-color);
      grid-area: title;
    }

    [data-pos='right'] {
      grid-area: next;
    }
  }

  .formControls {
    margin-top: auto;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      height: 28px;
      border-radius: 4px;
      width: max-content;
      align-self: end;

      svg {
        width: 16px;
        height: 16px;
      }

      &[data-edite-state='error'] {
        color: var(--color-danger);
      }

      &[data-edite-state='success'] {
        color: var(--color-success);
      }
    }
  }
}