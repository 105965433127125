@use "src/sass/abstracts/styled-fonts" as *;

.reply_container {
  padding: 8px;
  border-radius: 4px;
  outline: 1px solid var(--border-color);
  position: relative;
  overflow: clip;
  width: fit-content;
  z-index: 1;
  min-width: 250px;
  min-height: 47px;
  cursor: pointer;
  background: var(--secondary-background);

  [data-date-space]:after {
    @include font-body-small-regular;
    content: attr(data-date-space);
    opacity: 0;
    padding: 0 4px;
  }

  &:hover, &:focus {
    outline: 1px solid var(--primary-font-color);
    filter: brightness(0.95);
  }

  &:focus-visible {
    outline: 2px solid rgba(51, 195, 238, 0.5)
  }

  .vertical_line {
    height: calc(100% - 16px);
    width: 3px;
    background: var(--secondary-font-color) !important;
    display: block;
    position: absolute;
    border-radius: 4px;
    opacity: 0.5;
  }

  > div {
    padding: 0 0 0 10px;
    line-height: 22px;


    > h4 {
      font-weight: 700 !important;
      font-size: 16px;
      margin-bottom: 3px;
      margin-left: 1px;
      color: var(--secondary-font-color) !important;
    }

    .message_content {
      margin-left: 2px;
      max-height: 120px;
      overflow: hidden auto;
      margin-right: -8px;
      padding-right: 8px;

      & ~ .attachments {
        margin-top: 4px;
      }

      > * {
        line-height: 22px;

        &:last-of-type {
          display: inline-block;
        }
      }

      ol, ul, li {
        list-style-type: auto;
        margin-left: 15px;
      }

      div, p, span, ul, ol, li {
        color: var(--secondary-font-color) !important;
        @include font-body-medium-regular;
      }

      a {
        color: #027ffe;
      }

      > p {
        color: var(--primary-font-color) !important;
      }
    }
  }

  .status {
    @include font-body-small-regular;
    position: absolute;
    right: 12px;
    bottom: 4px;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    padding: 2px;
    border-radius: 4px;
    background: var(--secondary-background);

    p {
      color: var(--secondary-font-color) !important;
      white-space: nowrap;
      opacity: .5;
    }

    i {
      &.reload {
        font-style: initial;
        color: var(--primary-color);
        height: 10px;
        margin-top: -18px;
        margin-right: -2px;
      }
    }

    span {
      position: relative;
      top: 5px;

      &[data-is-read=true] {
        path {
          fill: var(--color-success);
          opacity: 1;
        }
      }

      span path {
        opacity: 0.4;
      }
    }
  }

  .attachments {
    display: flex;

    ul {
      border: 1px solid var(--border-color);
      width: max-content;
      padding: 4px 8px;
      border-radius: 4px;
      height: max-content;

      li {
        list-style: none;
      }

      p {
        @include font-body-small-regular;
        color: var(--secondary-font-color) !important;
        font-style: italic;
      }
    }
  }
}
