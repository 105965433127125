@use "src/sass/abstracts/abstracts" as *;

.container {
  width: var(--_size);
  height: var(--_size);
  border-radius: var(--_border-radius);

  margin: 0;

  &[data-can-click='true'] {
    cursor: pointer;
  }

  > figure {
    width: var(--_size);
    height: var(--_size);
    container-type: inline-size;

    > .user_icon {
      display: flex;
      position: relative;
      width: var(--_size);
      height: var(--_size);

      .user_svg {
        width: var(--_size);
        height: var(--_size);
      }

      .blf_icon {
        position: absolute;
        top: -6.25%;
        left: calc(var(--_size) * 0.75);
        height: max-content;
        width: max-content;
        display: flex;
        gap: 4px;
        align-items: center;
        z-index: 99;
        max-height: calc(var(--_size) * 0.3125);
        --_timing: 0.5s;
        pointer-events: none;

        > svg {
          width: calc(var(--_size) * 0.3125);
          height: calc(var(--_size) * 0.3125);
          min-width: calc(var(--_size) * 0.3125);
          min-height: calc(var(--_size) * 0.3125);

          &[data-comp='loading-spinner'] {
            width: calc(var(--_size) * 0.3);
            height: calc(var(--_size) * 0.3);
            min-width: calc(var(--_size) * 0.3);
            min-height: calc(var(--_size) * 0.3);
          }
        }

        [data-early='true'] {
          animation: iconBreathing 1.875s infinite ease;

          @keyframes iconBreathing {
            0% {
              transform: scale(0.875);
            }
            65% {
              transform: scale(1.215);
            }
            100% {
              transform: scale(0.875);
            }
          }
        }

        > .glider {
          position: absolute;
          background: rgba(var(--font-color-black-rgb), 0);
          height: calc(100% + 8px);
          width: 20px;
          left: -4px;
          border-radius: 240px;
          transition: width var(--_timing), background var(--_timing);
          z-index: -1;
        }

        > div {
          opacity: 0;
          transition: opacity var(--_timing);
        }

        > svg {
          pointer-events: all;
        }

        &:not(:hover) {
          p {
            animation: none !important;
          }
        }

        p {
          @include font-body-small-regular-imp;
          line-height: 12px;
          color: var(--font-color-white);
          opacity: 0;
          margin: 0 !important;
        }

        > p {
          transition: opacity calc(var(--_timing) - 0.1s);
          transition-delay: -0.1s;
          transition-timing-function: ease-out;
          position: relative;
          display: flex;

          > span {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-135deg);

            path {
              stroke: var(--font-color-white);
            }
          }
        }

        &:hover {
          pointer-events: all;

          > div {
            opacity: 1;
          }

          > .glider {
            background: rgba(var(--font-color-black-rgb), 1);
            width: calc(100% + 12px);
          }

          > p {
            transition: opacity calc(var(--_timing) + 0.1s);
            transition-delay: 0.1s;
            opacity: 1;
          }
        }
      }

      > span {
        @include font-body-medium-regular;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: var(--font-color-white);
      }

      @container (max-width: 36px) {
        span {
          color: var(--font-color-white);
          @include font-body-small-regular;
        }

        > .blf_icon {
          pointer-events: none !important;
        }
      }
      @container (max-width: 25px) {
        span {
          font-size: 9px;
        }
      }

      @container (min-width: 44px) {
        span {
          color: var(--font-color-white);
          @include font-body-large-regular;
        }

        > .blf_icon {
          pointer-events: none;
        }
      }


      > img {
        width: var(--_size);
        height: var(--_size);
        border-radius: var(--_border-radius);
        overflow: hidden;
        object-fit: cover;
      }

      .initials_only {
        width: 100%;
        height: 100%;
        border-radius: var(--_border-radius);
        display: grid;
        place-items: center;

        span {
          width: max-content;
          height: max-content;
          color: var(--font-color-white);
        }
      }
    }

    > .group_icon {
      width: var(--_size);
      height: var(--_size);
      position: relative;

      > div {
        position: absolute;
        width: 75%;
        height: 75%;
        display: grid;
        place-items: center;
        top: 0;
        left: 0;
        border-radius: 3px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        > span {
          color: var(--font-color-white);
          @include font-body-small-regular;
        }

        &:nth-of-type(2) {
          top: revert;
          left: revert;
          bottom: 0;
          right: 0;
        }
      }
    }

    > .phonebook_icon {
      width: var(--_size);
      height: var(--_size);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      path {
        fill: var(--font-color-white);
      }

      mark {
        background: var(--tertiary-color) !important;
        filter: grayscale(100%);
        width: calc(2500% / 32);
        height: calc(2500% / 32);
        position: absolute;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        span, svg {
          width: 90%;
          height: 90%;
        }

        &[data-pbct='3'] {
          opacity: 0.3;
          top: 0;
          right: 0;
        }

        &[data-pbct='2'] {
          opacity: 0.6;
        }

        &[data-pbct='1'] {
          bottom: 0;
          left: 0;
        }

        &:hover {

        }
      }
    }
  }
}