@use "src/sass/abstracts/styled-fonts" as *;

.container_wrapper {
  position: relative;

  .pfp {
    display: flex;
    padding: 3px;
    align-items: center;
    gap: 10px;
    position: absolute;
    border-radius: 10px;
    background: var(--primary-background);
    z-index: 1;
    left: 50%;
    top: -78px;
    transform: translateX(-50%);

    > img {
      display: flex;
      width: 95px;
      height: 94px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

.contact_container {
  position: relative;
  display: flex;
  width: 431px;
  padding: 28px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--primary-background);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.10);
  max-height: 90%;
  overflow: hidden auto;

  .header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    place-items: center left;

    > .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      text-align: center;

      p {
        @include font-body-large-medium;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      span{
        width: 80%;
      }
      span > p {
        @include font-body-medium-medium;
        color: var(--secondary-font-color);
      }
    }

    .opts {
      place-self: center right;
      display: flex;
      gap: 4px;
    }

    [data-is-fav="true"] {
      path {
        fill: var(--tertiary-color);
      }
    }

    [data-is-fav="false"] {
      path {
        fill: transparent;
        stroke: var(--primary-font-color);
        stroke-width: 2px;
      }
    }
  }

  .options {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 8px;
    width: 100%;
    justify-content: center;

    grid-template-areas: "x main extra";

    .mainOptions {
      grid-area: main;
      display: flex;
      flex-direction: row;
      gap: 8px;

      > button {
        width: 36px;
        height: 36px;
        padding: 4px;
        background: var(--secondary-background);
        border: 1px solid transparent;

        &:hover, &:focus {
          border: 1px solid var(--focus-border-color);
        }
      }
    }

    .extra_opts {
      grid-area: extra;
      display: flex;
      align-items: center;

      > button {
        width: max-content;
        min-width: 0;
        padding: 4px 8px;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    &[data-input="true"] {
      > div {
        padding: 4px;

        > input {
          width: 100%;
        }

        > span {
          width: 28px;
          height: 28px;
        }

        > svg {
          width: 28px;
          height: 28px;
        }
      }
    }

    &.dropdown {

      > div {
        padding: 0;
        width: 100%;
        border: none;

        > button {
          width: 100%;
          padding: 12px;
          height: auto;
          border-radius: 8px;
        }

        > ul {
          width: 373px;
          max-height: 240px;
        }
      }
    }

    > label {
      margin-left: 4px;
      @include font-body-small-medium;
      color: var(--secondary-font-color);

      &:first-letter {
        text-transform: capitalize;
      }
    }

    > div {
      padding: 12px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: 1px solid var(--border-color);
      border-radius: 8px;

      > p {
        @include font-body-medium-medium;
      }
    }

    .departments {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .contacts {
      max-height: 400px;
      overflow: hidden auto;

      &[data-loading-tc="true"] {
        > div {
          min-height: 40px !important;

          > span {

            &:nth-of-type(1) {
              transform: translateX(-20px) translateY(-4px);
              width: 28px !important;
              height: 28px !important
            }

            &:nth-of-type(2) {
              transform: translateX(-28px) translateY(-5px);
            }
          }
        }
      }

      > div {
        margin: 0;
        padding: 4px;
      }
    }

    .phonebook_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        gap: 4px;
        place-items: center left;
      }
    }
  }
}