//Used by incoming calls while on a call

@keyframes bounce-in-right {
  0% {
    transform: translateX(600px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateX(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateX(68px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateX(32px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateX(8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
}

//used by keypad transitions.
@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

//Used to display call/voice mail etc. over recent calls
@keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

//Used for logging in and calling "..."
@keyframes dot-pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

//Used incoming chat message
@keyframes bounce-top {
  0% {
    transform: translateY(-3px);
    animation-timing-function: ease-in;
    opacity: 1;
    background: var(--tertiary-color);
  }
  50% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(-3px);
    animation-timing-function: ease-out;
    opacity: 1;
    background: var(--secondary-background-color);
  }
}

//Used for chat message
@keyframes slide-in-right {
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

//Used for error message
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

@keyframes flip-diagonal-2-br {
  0% {
    transform: rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    transform: rotate3d(-1, 1, 0, 180deg);
    opacity: 0;
  }
  100% {
    transform: rotate3d(-1, 1, 0, 0deg);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slit-out-vertical {
  0% {
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes fade-out-fade-in {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fade-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

//Congrats h1 on login
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

//hide element
@keyframes visibility-none {
  to {
    visibility: hidden;
  }
}

@keyframes typing-slide-in {
  0% {
    transform: translateY(35px);
    z-index: initial;
  }

  100% {
    transform: none;
    z-index: 10;
  }
}

@keyframes ringing {
  0% {
    color: var(--color-success);
  }
  20% {
    color: var(--color-success);
  }
  80% {
    color: var(--color-warning);
  }
  100% {
    color: var(--color-warning);
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
}
