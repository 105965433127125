@use "src/sass/abstracts/styled-fonts" as *;

.aria_label {
  @include font-body-small-regular;
  grid-area: label;
  color: var(--secondary-font-color);
  padding: 4px;
}

.description {
  position: absolute;
  @include font-body-small-regular;
  width: 100%;
  text-align: end;
  padding: 2px;
}

.errorMessage {
  @extend .description;
  color: var(--color-danger);
  position: relative;
}

.aria_text {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.aria_top_of_container {
  //background: yellow;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  z-index: 1;
  border-radius: 4px;
  pointer-events: none;
}

.input_widgets {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: max-content;
}

.radio_group {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;

  > label {
    display: flex;
    align-items: baseline;
    gap: 8px;

    > p {
      @include font-body-medium-regular;
      color: var(--primary-font-color);
      display: flex;
      align-items: center;
      grid-gap: 4px;
      gap: 4px;
      margin: 0;
    }

    >input[type="radio"] {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 1px solid var(--border-color);
      border-radius: 999px;
      display: grid;
      place-content: center;
      transform-origin: center;

      &:before {
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 7px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background: var(--primary-color);
        transform-origin: center;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }


}