@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;


.styled_button {
  @include font-body-small-regular;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: max-content;
  padding: 6px 8px;
  border-radius: 4px;
  gap: 4px;

  color: var(--primary-font-color);

  p {
    color: var(--primary-font-color);
  }

  svg, span:has(svg) {
    width: 28px;
    height: 28px;
  }

  &[data-size='large'] {
    padding: 8px 16px;

    &[data-icon-only='true'] {
      padding: 0;
      width: 32px;
      height: 32px;

      span:has(svg), svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &[data-size='medium'] {
    padding: 4px 12px;
    height: 28px;
    min-width: 28px;

    &[data-icon-only='true'] {
      padding: 2px;
      width: 28px;
      height: 28px;

      span:has(svg), svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  &[data-size='small'] {
    padding: 4px 8px;
    height: 24px;

    &[data-icon-only='true'] {
      padding: 0;
      width: 24px;
      height: 24px;
    }

    span:has(svg), svg {
      width: 18px;
      height: 18px;
    }
  }

  &[data-font-weight='medium'] {
    font-weight: 500;
  }

  &[data-font-weight='medium'] {
    font-weight: 500;
  }

  &[data-button-style='primary'] {
    background: var(--tertiary-color);
    color: var(--font-color-white);

    p {
      color: var(--font-color-white);
    }

    path {
      fill: var(--font-color-white);
    }

    &:hover, &:focus, &[data-is-active='true'] {
      background: var(--tertiary-color-low-a);
    }
  }

  &[data-button-style='secondary'] {
    background: lime;
    color: darkorchid;

    p {
      color: darkorchid;
    }


    &:hover, &:focus, &[data-is-active='true'] {
      background: green;
    }
  }

  &[data-button-style='tertiary'] {
    background: var(--primary-background);

    &:hover, &:focus, &[data-is-active='true'] {
      background: var(--secondary-background);
    }
  }

  &[data-button-style='text'] {
    background: var(--primary-background);

    &:hover, &:focus, &[data-is-active='true'] {
      background: var(--secondary-background);
    }
  }

  &[data-icon-only='true'] {
    padding: 0;
    width: 32px;
  }

  &[data-context='danger'] {
    p {
      color: var(--color-danger) !important;
    }

    path {
      fill: var(--color-danger);
    }

    &:hover, &:focus, &[data-is-active='true'] {
      > p {
        color: var(--color-danger);
      }

      > path {
        fill: var(--color-danger);
      }
    }
  }

  &[data-context='branded'] {
    p {
      color: var(--tertiary-color);
    }

    path {
      fill: var(--tertiary-color);
    }
  }

  &[data-no-bg='true'] {
    background: none;

    &:hover, &:focus, &[data-is-active='true'] {
      p {
        text-decoration: underline;
      }
    }
  }

  &[data-keypad='true'] {
      background: var(--keypad-background);

      path {
          fill: var(--font-color-white);
          opacity: 0.9;
      }

      &:hover, &:focus, &[data-is-active='true'] {
          background: rgba(255, 255, 255, 0.15) !important;

          path {
              opacity: 1;
          }
      }
  }

  .loading_container {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: none !important;
      }
    }
  }

  &:disabled {
    filter: grayscale(0.1);

    &[data-context='danger'] p {
        color: var(--secondary-font-color) !important;
    }
  }
}

.tab_selector {
  position: relative;
  display: flex;
  width: 100%;
  grid-gap: 4px;
  padding: 2px;
  min-height: 32px;
  place-items: center;
  background: var(--border-color);
  max-width: 100%;
  overflow: auto hidden;
  border-radius: 4px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .tab_glider {
    position: absolute;
    //grid-column: 1 / 2;
    width: 100px;
    height: calc(100% - 4px);
    border-radius: 2px;
    transition: 0.25s ease-out;
    background: var(--primary-background);
  }

  button {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid transparent;
    padding: 0 8px;

    > p {
      @include font-body-small-medium;
      max-width: 140px;
      overflow: hidden visible;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[data-glder-style='primary'] {
    .tab_glider {
      background: var(--tertiary-color);
    }

    button[data-is-active='true'] {
      p {
        color: var(--font-color-white);
      }
    }
  }
}


.styled_paginator {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-template-areas: 'backbtn title nextbtn';
  place-items: center;
  width: 100%;

  p {
    grid-area: title;
    color: var(--secondary-font-color);
    @include font-body-small-regular;
  }
}

.styled_tag {
  @include font-body-medium-medium;
  padding: 2px 4px;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  &[data-context='beta'] {
    color: var(--tertiary-color);
    border-color: currentColor;
    filter: brightness(1.1);
  }

  &[data-size='small'] {
    padding: 1px 2px;
    @include font-body-small-medium;
  }
}

.styled_dropdown {
  position: relative;
  width: max-content;

  &[data-loading='true'] {
    > button {
      justify-content: space-between;
    }
  }

  &[data-is-open='true'] {
    .arrow_button {
      transform: rotate(180deg);
    }
  }

  > button {
    padding: 8px 12px;
    min-width: 120px;
    display: flex;
    gap: 8px;

    &:disabled {
      opacity: 0.8;

      &:hover {
        background: none !important;
        cursor: default !important;
      }
    }

    &[data-icon-only='true'] {
      min-width: 24px;
    }

    p {
      @include font-body-medium-regular;
      flex: 1;
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span, svg {
      width: 14px;
      height: 14px;
    }
  }

  > ul {
    position: fixed !important;
  }
}

.styled_list {
  min-width: 124px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--primary-background);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: max-content;
  max-height: 420px;
  overflow: hidden auto;
  z-index: 100;
  cursor: auto;

  &[data-menu-direction='right'] {
    right: 0;
  }

  > p {
    @include font-body-small-medium;
    padding: 8px 12px;
    color: var(--primary-font-color) !important;
  }

  li > button, > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px;
    height: 32px;
    min-height: 32px;
    border-radius: 0;

    &:disabled {
      pointer-events: none;

      p {
        color: var(--primary-font-color);
        opacity: 0.75;
      }
    }

    &[data-is-large='true'] {
      height: 48px;
      min-height: 48px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 0;

      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: max-content;
      }

      :nth-child(2) {
        @include font-body-small-regular;
        color: var(--secondary-font-color);
      }
    }

    &[data-is-danger] {
      p {
        color: var(--color-danger) !important;
      }
    }

    p {
      @include font-body-medium-regular;
      color: var(--primary-font-color);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: max-content;
    }

    span, svg {
      display: flex;
      width: 14px;
      height: 14px;
    }

    &:hover, &:focus, &[data-is-selected='true'] {
      background: var(--secondary-background);

      p {
        color: var(--primary-font-color);
      }
    }
  }
}


.audio_slider {
  flex: 1;

  &:hover {
    .thumb {
      opacity: 1;
    }
  }

  .rail {
    color: var(--border-color) !important;
    opacity: 1;
  }

  span {
    color: inherit;
  }

  .thumb {
    opacity: 0;
    transition: opacity 0.2s;
    color: inherit;
    box-shadow: none !important;

    &:hover:before {
      transition: scale 0.2s;
      scale: 1.7;
      background: currentColor;
      opacity: 0.16;
    }

    &.active:before {
      scale: 2;
      background: currentColor;
      opacity: 0.16;
    }
  }
}

.styled_page_tabs {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;
  padding-left: 16px;
  overflow: auto hidden;

  > button {
    border-radius: 0;
    height: 100%;
    padding: 0 12px;
    border-bottom: 2px solid transparent;
    outline: none !important;
    background: none !important;

    &[data-selected="true"] {
      border-bottom: 2px solid var(--primary-color);

      h2 {
        color: var(--primary-font-color);
      }
    }

    &[disabled] {
      //pointer-events: none;
      cursor: not-allowed;
    }

    &:hover, &:focus {

      h2 {
        color: var(--primary-font-color);
      }
    }

    h2 {
      color: var(--secondary-font-color);
      @include font-body-medium-medium;
      display: flex;
      align-items: center;
      gap: 4px;

      > i {
        background: var(--notification-color);
        border-radius: 23px;
        padding: 2px 4px;
        height: 14px;
        @include font-body-small-bold;
        font-size: 10px;
        color: var(--font-color-white);
        display: flex;
        align-items: center;
        margin-left: 4px;
      }
    }
  }
}

.fading_text {
  display: grid;
  grid-template-columns: max-content;
  grid-template-areas: "a";

  > p {
    grid-area: a;
    text-align: left;

    &:nth-of-type(1) {
      animation: textCycle 10s infinite ease;
      animation-delay: -2s;
    }

    &:nth-of-type(2) {
      animation: textCycle 10s infinite ease;
      animation-delay: 3s;
    }
  }

  @keyframes textCycle {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 1
    }
    50% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

}

.styled_checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 0;

  &:hover, &:focus, &:focus-within {
    input[type="checkbox"]:not([readonly]) {
      border-color: var(--focus-border-color);
    }
  }

  p {
    @include font-body-medium-medium;
    color: var(--secondary-font-color);
  }

  input[type="checkbox"] {
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 14px;
    height: 14px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    display: grid;
    place-content: center;
    transition: 0.2s background, 0.2s border;
    transform-origin: center;

    &:checked {
      background: var(--primary-color);
      border: 1px solid var(--primary-color) !important;
    }

    &[readonly] {
    }

    &:before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--font-color-white);
      transform-origin: center;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
