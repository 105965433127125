@use "src/sass/theming/variables" as *;
@import "src/sass/theming/_variables.scss";

$theme-yay-brand: (
        $theme-font-family: $lato,
        $theme-primary-color: $color-amaranth,
        $theme-primary-color-low-a: rgba($color-amaranth, 0.4),
        $theme-secondary-color: $color-picton-blue,
        $theme-secondary-color-dark: $color-picton-blue-light,
        $theme-tertiary-color: $color-bittersweet,
        $theme-tertiary-color-low-a: rgba($color-bittersweet, 0.85),
);

$theme-call-switch-brand: (
        $theme-font-family: Roboto,
        $theme-primary-color: $csSecondaryBlue,
        $theme-primary-color-low-a: rgba($csSecondaryBlue, 0.4),
        $theme-secondary-color: $csPrimaryRed,
        $theme-secondary-color-dark: darken($csPrimaryRed, 5%),
        $theme-tertiary-color: $csSecondaryBlue,
        $theme-tertiary-color-low-a: rgba($csSecondaryBlue, 0.85),
);

$theme-pulse-hd-brand: (
        $theme-font-family: $inter,
        $theme-primary-color: $color-pulse-purple,
        $theme-primary-color-low-a: rgba($color-pulse-purple, 0.4),
        $theme-secondary-color: $color-pulse-violet,
        $theme-secondary-color-dark: darken($color-pulse-violet, 5%),
        $theme-tertiary-color: $color-pulse-violet,
        $theme-tertiary-color-low-a: rgba($color-pulse-violet, 0.85),
);

$theme-gen-rand-cs: (
        $theme-font-family: Roboto,
);
