@use "src/sass/abstracts/styled-fonts" as *;

#login-unsupported {
  display: flex;
  height: 100vh;

  .container {
    height: 300px;

    .logo {
      margin: 0 auto 50px auto;
      display: block;
    }

    .unsupported-message {
      justify-content: center;
      margin: 0 60px;

      > p {
        text-align: center;
        color: var(--primary-font-color);
        font-size: 18px;
        margin-bottom: 20px;
      }

      > div > p {
        text-align: center;
        color: var(--primary-font-color);
        font-size: 22px;
        margin: 20px 0;
      }

      > div a {
        @include font-body-large-regular;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: var(--color-success);
        width: 340px;
        height: 50px;
        margin: 0 10px;
        border-radius: 3px;
        text-decoration: none;

        &:hover {
          background: var(--secondary-color);
        }
      }
    }
  }

  #login-unsupported-logo{
    max-width: 200px;
  }

  @media only screen and (max-width: 400px) {
    .container {
      width: 100vw;

      .logo {
        display: block;
        margin: 0 auto 35px;
      }

      .unsupported-message {
        width: 100vw;
        margin: 0;
      }
    }
  }
}
