.regular_typing_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 25px;
  border-radius: 6px;

  &[data-place='chat'] {
    position: relative;
    left: -10px;
  }

  &[data-iw='true'] {
        >.loader>div {
            background: white;
        }
    }

  .loader {
    display: flex;
    gap: 5px;

    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--primary-font-color);
      opacity: 0;
    }

    .dot1 {
      animation: typing 2s infinite;
    }

    .dot2 {
      animation: typing 2s 0.5s infinite;
    }

    .dot3 {
      animation: typing 2s 1s infinite;
    }

    .dot4 {
      animation: typing 2s 1.5s infinite;
    }

    @keyframes typing {
      0%, 100% {
        opacity: 0.1;
      }
      25% {
        opacity: 0.6;
      }
      50% {
        opacity: 0.4;
      }
      75% {
        opacity: 0.2;
      }
    }
  }
}

.mini_typing_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 5px;

  &[data-iw='true'] {
      >.loader>div {
          background: white;
      }
  }

  .loader {
    display: flex;
    gap: 4px;

    div {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: var(--primary-font-color);
      opacity: 0.2;
    }

    .dot1 {
      animation: typing 2s infinite;
    }

    .dot2 {
      animation: typing 2s 0.5s infinite;
    }

    .dot3 {
      animation: typing 2s 1s infinite;
    }

    @keyframes typing {
      0%, 100% {
        opacity: 0.1;
      }
      33% {
        opacity: 0.6;
      }
      66% {
        opacity: 0.4;
      }
    }
  }
}
