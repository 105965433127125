@use "src/sass/abstracts/styled-fonts" as *;

body {
  font-family: var(--font-family), sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: var(--primary-font-color);

  code {
      font-family: monospace;
  }
}

textarea, input[type="text"], input[type="password"] {
  background: var(--secondary-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: initial;
  color: var(--secondary-font-color);
}

p {
  color: var(--primary-font-color);
}

mark {
  font-weight: 400;
}

mark {
  color: var(--primary-color);
  background-color: initial;
}

input {
  background: transparent;
  font-family: var(--font-family), sans-serif;
  color: var(--primary-font-color);

  &::placeholder {
    font-family: var(--font-family), sans-serif;
    color: var(--secondary-font-color);
  }

  // Gets rid of the blue surround on click
  &:focus {
    outline-width: 0;
  }

  // Gets rid of the blue highlight on autofill
  //&:-webkit-autofill {
  //  box-shadow: 0 0 0 30px rgba(var(--primary-color-rgb), 0.2) inset !important;
  //  -webkit-box-shadow: 0 0 0 30px rgba(var(--primary-color-rgb), 0.2) inset !important;
  //  -webkit-text-fill-color: var(--primary-font-color) !important;
  //}

}

textarea {
  font-family: var(--font-family), sans-serif;
  color: var(--primary-font-color);
  line-height: 1.3;

  &::placeholder {
    color: var(--secondary-font-color);
  }
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100px;
  margin: 0 10px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    transform: translateY(-5px);
    border-radius: 50%;
    cursor: pointer;
    transition: .2s;
    background: var(--primary-color);
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0;
    background: none;
    border: solid 1px var(--border-color);
    cursor: pointer;
  }

  &:hover {
    &::-webkit-slider-thumb {
      background: var(--secondary-color-dark);
    }
  }

  &:focus {
    outline: none;
  }
}

.container {
  width: 480px;
  height: 100%;
  margin: auto;
  justify-self: center;
  align-items: center;
}

.empty-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 20px 10px;

  p {
    @include font-body-medium-regular;
    color: var(--secondary-font-color);
  }

  > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    > div {
      position: absolute;
    }
  }

  &:hover {
    background: inherit !important;
  }

  rect {
    fill: transparent;
  }
}

.divider {
  width: 1px;
  height: 30px;
  background-color: var(--theme-secondary-background);
}
