

.volume-slider {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  border-bottom: none;

  i {
    cursor: pointer;
  }

  .slider-input {
    flex-direction: column;
    margin-top: 17px;

    > input[type='range'] {
      -webkit-appearance: none;
      width: 100px;
      height: unset;
      margin: 0 10px;

      &::-webkit-slider-thumb {
        background: #fff;
        border: 1px solid var(--tertiary-color);
      }

      &::-webkit-slider-runnable-track {
        border: solid 1px #fff;
      }
    }

    > p {
      font-size: 12px;
      text-align: center;
      color: #fff;
      margin-top: 5px;
      margin-right: 0;
      transform: translateY(5px);
    }
  }
}
