@use "src/sass/abstracts/styled-fonts" as *;

.app_notification {
  background: var(--primary-background);
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p, h3 {
    color: var(--primary-font-color);
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    > h3 {
      @include font-body-medium-bold;
    }
  }

  > p {
    @include font-body-medium-medium;
  }

  .controls {
    display: flex;
    gap: 8px;
    padding-top: 12px;
    align-items: center;
  }
}


.call_queued_notification {

}