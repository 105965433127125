.main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (max-height: 820px) {
    align-items: center;
    height: 100vh;
    padding-top: 0;
  }
}
