@use "src/sass/abstracts/styled-fonts" as *;


.callFeedbackContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--backdrop-color);
  z-index: 100;

  .formContainer {

    .call_info {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {

        &:nth-of-type(1) {
          > p {
            @include font-body-large-regular;

            &:nth-of-type(2) {
              @include font-body-small-regular;
              color: var(--secondary-font-color);
            }
          }
        }

        &:nth-of-type(2) {
          @include font-body-large-regular;
          display: flex;
          gap: 8px;
        }
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;


      .call_mood {
      }
    }
  }
}