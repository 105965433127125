

.audio-visualiser {
  display: flex;
  align-items: center;
  margin: 10px 0;

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: 10px;
      height: 20px;
      border-radius: 4px;
      background: #d6d4d3;

      &.active {
        background: var(--secondary-color);
      }

      &.top-level {
        background: var(--color-success);
      }
    }
  }

  > button {
    height: 22px;
    margin-right: 10px;

    i {
      color: var(--secondary-color);
      font-size: 22px;

      &:hover {
        color: var(--tertiary-color);
      }
    }
  }
}
