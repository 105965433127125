@use "src/sass/abstracts/styled-fonts" as *;

.department {
  padding: 2px 4px !important;
  height: max-content !important;
  max-width: 240px;

  &:disabled {
    pointer-events: none;
  }

  p {
    @include font-body-medium-regular;
    max-width: 232px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-is-dark='true'] {
    background: rgba(255, 255, 255, 0.1);
  }

  &[data-is-dark='false'] {
    background: rgba(0, 0, 0, 0.05);
  }

  &[data-is-leaf='true'] {
    border-radius: 4px;
  }

  &[data-allow-click='true'] {
    cursor: pointer;

    &:hover {
      background: var(--secondary-background);
    }
  }
}

.department_span {

  .department {
    border-radius: 4px;
  }

  & ~ .department_span {
    &:before {
      content: ', ';
    }
  }
}