@use 'src/sass/theming/pallete' as *;
@use 'src/sass/theming/mixins' as *;
@use 'src/sass/theming/sizes' as *;
@use 'src/sass/theming/semantic-colors' as *;
@use 'src/sass/theming/brand-colors' as *;

body {
  background: var(--body-background);

  #root {
    height: 100%;
  }

  ::backdrop {
    background: rgba(0, 0, 0, 0.1);
  }

  &[data-theme='dark'] {
    ::backdrop {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &[data-is-browser='true'] {
    @media only screen and (min-width: 1550px) {

      #root > div {
        background: #E7E7E7;

        > nav {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        > div > main {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }

      &[data-theme='dark'] {
        #root > div {
          background: #171717;
        }
      }
    }
  }


  // Yay Theme
  &[data-schema='yddab88d'] {
    @include spread-map($yay-sizes);
    @include spread-map($theme-yay-brand);
    @include spread-map($theme-yay-semantics);

    &[data-theme='light'] {
      @include spread-map($theme-map-yay);
    }

    &[data-theme='dark'] {
      @include spread-map($theme-map-yay-dark);
    }
  }

  // PulseHd Theme
  &[data-schema='pe12cd8c'] {
    @include spread-map($pulsehd-sizes);
    @include spread-map($theme-pulse-hd-brand);
    @include spread-map($theme-pulse-hd-semantics);

    &[data-theme='light'] {
      @include spread-map($theme-map-pulse-hd);
    }

    &[data-theme='dark'] {
      @include spread-map($theme-map-pulse-hd-dark);
    }
  }

  // CallSwitchOne Theme
  &[data-schema='cef527d1'] {
    @include spread-map($callswitch-sizes);
    @include spread-map($theme-call-switch-brand);
    @include spread-map($theme-call-switch-semantics);

    &[data-theme='light'] {
      @include spread-map($theme-map-callswitch);
    }

    &[data-theme='dark'] {
      @include spread-map($theme-map-callswitch-dark);
    }
  }

  &[data-schema='x'] {
    @include spread-map($callswitch-sizes);
    @include spread-map($theme-gen-rand-cs);
    @include spread-map($theme-gen-sem-cs);
  }
}
