@use "src/sass/abstracts/styled-fonts" as *;

.attachment_container {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  gap: 12px;
  transition: .2s;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  height: 60px;
  position: relative;
  background: rgba(var(--secondary-background-rgb), 0.2);

  &:hover {
    border-color: var(--chat-message-color);
  }

  &:hover, &:focus {
    border-color: var(--secondary-font-color);

    .primary_action_container {
      display: flex;
      opacity: 1;
    }
  }

  > span, > button {
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.pdf {
      height: 36px;
      width: 36px;
      min-width: 36px;
      margin: 0 6px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.1);
      color: var(--focus-border-color);
      border: 1px solid transparent;

      &:hover, &:focus {
        border-color: var(--border-color);

        mark {
          color: var(--primary-color);
        }
      }

      mark {
        @include font-body-small-regular;
        color: var(--primary-font-color);
      }
    }
  }

  &[data-is-large="true"] {
    height: 200px;
    overflow: clip;

    .preview_container {
      img {
        max-height: 200px;
      }
    }

    .file_content_container {
      background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
      width: 100%;
      margin: -14px 0;
      height: 50%;
      padding: 0 14px 14px;
      position: absolute;
      cursor: pointer;
      border-radius: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;

      > div {
        &.file_info {
          max-width: 210px;
        }
      }

      > button {
        position: absolute;
        right: 14px;
      }
    }

    .download_button {
      color: var(--font-color-white) !important;
      border-color: var(--font-color-white) !important;

      path {
        stroke: var(--font-color-white) !important;
      }

      &:hover {
        background: rgb(white, 0.3) !important;
      }
    }

    p, span {
      color: var(--font-color-white) !important;
    }
  }

  button {
    background: transparent;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    position: relative;
    transition: .2s;

    &:hover, &[data-is-active="true"] {
      background: var(--secondary-background);
    }
  }

  .audio_attachment {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 24px;
      width: 24px;
      height: 24px;
    }

    .audio_display {
      display: grid;
      grid-template-columns: 48px 1fr 48px;
      grid-template-areas: 'title title title' 'audio_start audio_slider audio_end';
      grid-template-rows: 24px 28px;
      place-items: center;
      width: 200px;
      max-width: 200px;
      height: 52px;

      .file_info {
        grid-area: title;

        p {
          max-width: 100%;
        }
      }

      .audio_title {
        @include font-body-medium-regular;
        grid-area: title;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .audio_start {
        grid-area: audio_start;
      }

      [data-comp='audio-attachment__player'] {
        grid-area: audio_slider;

        span {
          color: inherit;
        }
      }

      .audio_end {
        grid-area: audio_end;
      }

      > span {
        @include font-body-small-regular;
        color: var(--secondary-font-color);
      }
    }
  }

  .preview_button_container {
    position: relative;
    width: 58px;
    height: 58px;
    min-width: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    .file_type {
      @include font-body-small-regular;
      display: flex;
      justify-content: center;
      color: var(--primary-font-color);
      background: rgba(white, 0.15);
      border: 1px solid var(--border-color);
      height: 36px;
      width: 36px;
      align-items: center;
      border-radius: 4px;
      font-weight: 700;

      &[data-generic='true'] {
        border: none;
      }

      path {
        fill: var(--primary-font-color);
      }
    }

    > div {
      position: absolute;
      height: 28px;
    }

    button {
      background: var(--primary-color);
      border-radius: 50%;

      span svg {
        position: relative;
        width: 18px;
        left: 1px;

        path {
          fill: var(--font-color-white)
        }
      }

      &.loading {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 18px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .activate_preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;

    > button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--primary-background);
      opacity: 0.9;
      color: var(--primary-font-color);
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      border-radius: inherit;
    }
  }

  .primary_action_container {
    position: absolute;
    top: -12px;
    right: -12px;
    display: none;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;

    .download_button {
      border-color: var(primary-font-color);

      path {
        stroke: var(--primary-font-color);
      }
    }

    &[data-is-download="true"] {
      right: 8px;
      top: 0;
      overflow: clip;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &[data-is-download="false"] {
      right: 12px;

      button {
        position: absolute;
        border: 1px solid var(--secondary-font-color);
        background: var(--primary-background);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transition: .2s;

        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          z-index: -1;
          opacity: 0;
        }

        span {
          svg {
            position: relative;
            bottom: 3px;
            width: 14px;
            transition: .2s;

            path {
              fill: var(--secondary-font-color);
            }
          }
        }

        &:hover {
          border-color: var(--color-danger);

          span svg {
            path {
              fill: var(--color-danger);
            }
          }
        }
      }
    }
  }

  .preview_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: clip;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      object-fit: cover;
      max-width: 300px;
      width: 300px;
      height: 100%;
      position: absolute;
      max-height: 60px;
      top: 0;
    }
  }

  .preview_container_mini {
    position: relative;
    height: 58px;
    width: 58px;
    min-width: 58px;
    min-height: 58px;
    border-radius: 4px;
    cursor: pointer;
    overflow: clip;
    box-sizing: content-box;

    img {
      background: rgba(white, 0.2);
      object-fit: cover;
      width: 58px;
      height: 58px;
      position: absolute;
      box-sizing: content-box;
    }

    &::after {
      content: "";
      background: black;
      border-radius: 4px;
      width: 58px;
      height: 58px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: .2s;
      pointer-events: none;
    }

    &:hover {
      &::after {
        opacity: 0.3;
      }
    }
  }

  .file_content_container {
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    width: 100%;
    border-radius: 4px;
    position: relative;

    &.with_background {
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      padding: 0 14px;
      backdrop-filter: blur(10px);
      position: absolute;
      cursor: pointer;

      > div {
        &.file_info {
          p, span {
            color: var(--font-color-white);
          }
        }
      }
    }

    > div {
      position: absolute;
    }
  }

}

.file_info {
  max-width: 180px;

  p {
    @include font-body-medium-regular;
    display: grid;
    grid-template-columns: 1fr max-content;
    width: max-content;
    max-width: 180px;

    > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: max-content;
      max-width: 16ch;
    }
  }

  > span {
    @include font-body-small-regular;
    color: var(--secondary-font-color);
    max-width: inherit;
  }
}

.download_button {
  color: var(--primary-font-color);
  border: 2px solid currentColor;
  border-radius: 50% !important;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;

  span svg {
    width: 24px;
    height: 24px;
  }

  span {
    color: inherit;
    position: relative;
    top: 2px;

    svg {
      color: inherit;

      path {
        color: inherit;
        fill: none;
        stroke: currentColor;
      }
    }
  }

  &:hover {
    filter: brightness(1.1);
  }
}
