@use "src/sass/abstracts/styled-fonts" as *;

.inputBase {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-template-areas: 'label mark' 'input input' 'extra extra';
  width: 100%;

  label {
    @include font-body-small-regular;
    grid-area: label;
    color: var(--secondary-font-color);
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  mark {
    padding-top: 8px;
    text-align: end;
    grid-area: mark;
    color: var(--color-danger);
    height: 23px;
  }
}

.inputBox {
  grid-area: input;
  width: 100%;
  height: 36px;
  background: var(--primary-background) !important;
  border-radius: 4px;
}

.stateBorders {
  //&[data-is-dirty='true'] {
  //  border-color: var(--notification-color);
  //}

  &[data-error='true'] {
    border-color: var(--color-danger);
  }

  &:focus, &:focus-within {
    border-color: var(--focus-border-color);
  }
}

.selectDropDownItems {
  min-width: 0;

  > div {
    background: var(--primary-background);
    border: 1px solid var(--border-color);
    border-radius: 4px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 4px 8px;
      width: 100%;
      height: 24px;
      cursor: pointer;
    }
  }
}

.form_text_field {
  position: relative;
  @extend .inputBase;

  .input_wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    grid-area: input;
  }

  .input_container {
    @extend .stateBorders;
    padding: 4px 4px 4px 12px;
    width: 100%;
    height: max-content;
    min-height: 36px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--border-color);

    &[data-is-search='true'] {
      padding: 4px;
    }

    &[data-no-input] {
      border: 1px solid transparent !important;
    }

    span, svg {
      width: 24px;
      height: 24px;
    }

    input, textarea {
      flex: 1;
      min-width: 84px;
      background: var(--primary-background) !important;
      height: 24px;
    }

    textarea {
      min-height: 36px;
      height: auto;

      &:focus {
        outline: none;
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      max-height: 200px;
      overflow: hidden auto;
    }
  }

  textarea {
    height: 128px;
    width: 100%;
    resize: none;
  }

  div {
    grid-area: extra;
  }
}

.form_select_input {
  @extend .inputBase;
  position: relative;
  color: var(--primary-font-color);

  .selectDrop{
    @extend .stateBorders;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    min-height: 36px;
  }

  > div {
    @extend .stateBorders;
    grid-area: input;
    border-radius: 4px;

    > div:nth-of-type(1) {
      @extend .inputBox;
      cursor: pointer;
      position: relative;

      > div {

        &:nth-of-type(1) {
          @include font-body-medium-regular;
          min-height: 0;
          height: 100%;
          cursor: pointer;
        }

        &:nth-of-type(2) {
          @extend .selectDropDownItems;
          width: 360px;

          > div {
            min-width: 0;
            max-height: 240px;
            width: 360px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            > div {
              @include font-body-medium-regular;
              max-width: 100%;
              min-height: 28px;
              overflow: hidden;
              padding: 0 8px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.form_select_combo {
  display: flex;
  flex-direction: row;
  position: relative;
  color: var(--primary-font-color);

  > div {
    height: 100%;

    &:nth-of-type(1) {
      @include font-body-medium-regular;
      border: 1px solid var(--border-color);
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 108px;
      height: 100%;
      display: flex;
      justify-content: center;

      > div {
        height: 100%;
        cursor: pointer;

        &:nth-of-type(1) {
          height: 100%;
          min-height: 34px;
        }

        &:nth-of-type(2) {

          @extend .selectDropDownItems;
        }
      }
    }

    &:nth-of-type(2) {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.dropDownIcon {
  width: 24px;
  height: 24px;
  align-self: center;
  border-radius: 4px;

  &:hover, &:focus {
    background: var(--secondary-background);
  }
}

.form_select_multiple_creatable_input {
  @extend .inputBase;
  position: relative;
  color: var(--primary-font-color);

  > div {
    @extend .stateBorders;
    grid-area: input;
    border: 1px solid var(--border-color);
    border-radius: 4px;

    > div:nth-of-type(1) {
      @extend .inputBox;
      cursor: pointer;
      position: relative;

      > div {

        &:nth-of-type(1) {
          @include font-body-medium-regular;
          min-height: 0;
          height: 100%;
          cursor: pointer;
        }

        &:nth-of-type(2) {
          @extend .selectDropDownItems;
          width: 360px;

          > div {
            min-width: 0;
            max-height: 240px;
            width: 360px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            > div {
              @include font-body-medium-regular;
              //max-width: 100%;
              //min-height: 28px;
              //overflow: hidden;
              //padding: 0 8px;
              //text-overflow: ellipsis;
              //white-space: nowrap;
              justify-content: flex-start;
              align-items: center;

              &:before {
                position: relative;
                top: -5px;
              }
            }
          }
        }
      }
    }
  }
}

.form_radio_group {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  //&[data-is-dirty='true'] > div {
  //  border-color: var(--notification-color);
  //}

  &[data-error='true'] > div {
    border-color: var(--color-danger);
  }

  &:focus > div, &:focus-within > div {
    border-color: var(--focus-border-color);
  }

  > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 24px;
    padding: 4px;
    border-radius: 4px;
    width: max-content;
    gap: 8px;
    border: 1px solid transparent;


    > span {
      @include font-body-medium-medium;
      position: absolute;
      top: 0;
      color: var(--secondary-font-color);
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      opacity: 0.75;
      border: 1px solid transparent;
      position: relative;

      &:hover, &:focus, &:focus-within, &[data-is-active='true'] {
        opacity: 1;
        background: var(--secondary-background);
        border-color: var(--border-color);
      }

      label {
        cursor: pointer;
        width: 45px;
        height: 45px;
      }

      input {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
  }
}