@use "src/sass/abstracts/abstracts" as *;

.container {
  flex: 1;
  overflow: hidden auto;
  width: 335px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  button {
    @include font-body-medium-regular;
    width: 100%;
    height: 32px;
    color: var(--primary-font-color);
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:hover, &:focus {
      background: var(--secondary-background);
      cursor: pointer;
    }

    [data-comp='entity-icon'] {
      margin: 0 8px 0 4px;

      span {
        @include font-body-small-regular;
        font-size: 10px;
      }
    }

    span {
      max-width: 275px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &[data-exception='true'] {
      opacity: 0.75;
    }
  }
}
