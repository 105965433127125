

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--secondary-font-color);
}

.status-dot--online {
  @extend .status-dot;
  background: var(--color-success);
}

.status-dot--away {
  @extend .status-dot;
  background: var(--color-warning);
}

.status-dot--offline {
  @extend .status-dot;
  background: var(--secondary-font-color);
}
