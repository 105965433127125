@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.section_group {
  @include settings-page-section-group;
  align-items: center;
  min-height: 110px;
  margin-bottom: 20px;
  grid-template-areas: "profile btn" "dept x";

  .profile {
    grid-area: profile;
    display: flex;
    flex-direction: row;
    align-items: center;

    figure > div {
      width: 80px;
      height: 80px;
      margin: 0;
    }

    h2 {
      @include font-body-large-medium;
      color: var(--primary-font-color);
    }

    p {
      @include font-body-medium-medium;
      color: var(--secondary-font-color);
    }
  }

  .departments {
    grid-area: dept;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    padding: 12px 0 0 0;
    margin-bottom: -44px;

  }

  > button {
    grid-area: btn;
    width: 114px;
  }
}

