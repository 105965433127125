
.nav-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--nav-background);
  width: 60px;
  padding: 10px 10px;
  gap: 10px;
  position: relative;

  &::after {
    background: var(--nav-background-overlay);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .nav-bar__user-settings {
    z-index: 1;
    position: relative;

    .nav-bar__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &.nav-bar__dnd-warning {
        position: relative;
        outline: 2px solid var(--secondary-color);
        border-radius: 6px;
      }
    }
  }

  .nav-bar__menu-item {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: .2s;
    border-radius: 8px;
    border: 1px solid transparent;


    > span svg {
      opacity: 0.8;

      path {
        fill: var(--nav-icon-color);
      }
    }

    &.active, &:hover, &:focus {
      > span svg {
        opacity: 1;
      }

      background: rgba(white, 0.2);
    }

    &:focus {
      border-color: rgba(white, 0.6);
    }

    &[data-user-icon='true'] {
      border-radius: 6px;
      width: 43px;
      height: 43px;
      opacity: 1;
    }

    .nav-bar__chat-notification {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 12px;
      height: 12px;
      background: var(--notification-color);
      border-radius: 50%;
    }

    > span {
      width: 32px;
      height: 32px;

      path {
        fill: var(--nav-icon-color);
      }
    }
  }
}
