.emoji-menu {
  background: var(--primary-background);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: clip;
  border: 1px solid var(--border-color);

  .emoji-mart {

    button {
      background: none;

      &:hover, &:focus {
        background: none !important;
      }
    }

    .emoji-mart-anchors {
      display: none;
    }

    .emoji-mart-search {
      display: flex;
      width: calc(100% - 24px);
      border-radius: 4px;
      padding: 8px 12px;
      margin: 8px 12px;
      border: 1px solid var(--border-color);
      flex-direction: row-reverse;
      gap: 12px;

      button {
        svg path {
          fill: var(--primary-font-color);
        }
      }

      input {
        flex: 1;
        background: transparent;
        color: var(--primary-font-color);

        &::-webkit-search-cancel-button {
          display: none;
        }
      }

      label {
        display: none;
      }
    }

    .emoji-mart-scroll {
      padding-left: 11px !important;

      > div {
        width: 100% !important;
        overflow-x: clip !important;

        > div {
          width: 100% !important;
          display: flex;
          justify-content: space-between;

          > div {
            color: var(--secondary-font-color);
            font-size: 12px;
            margin: 10px 12px 0 5px;
            padding-bottom: 6px;
            font-weight: 500;
            text-align: left;
            text-transform: uppercase;

            cursor: default;
          }

          > li {
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            padding-top: 3px;
            border-radius: 3px;
            cursor: pointer;
            overflow: hidden;
            background: transparent;
            transition: .2s;

            &:hover, &:focus-within {
              background: var(--secondary-background);
            }
          }
        }

        &.emoji-mart-no-results {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          gap: 12px;
          display: flex;
          flex-direction: column;
          background: var(--primary-background);

          > div {
            color: var(--secondary-font-color);
            justify-content: center;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
    }
  }
}
