@use "src/sass/abstracts/abstracts" as *;

.selector_container {
  //display: grid !important;
  //grid-template-columns: repeat(3, max-content);
  max-width: 600px;
  grid-column: 1 / 3;
  margin: 8px 0;
  display: flex;
  width: 100%;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;
  //@include large-screens {
  //  grid-template-columns: repeat(2, max-content);
  //}
  //@media only screen and (max-width: 800px) {
  //  grid-template-columns: repeat(1, max-content);
  //}
}

.selector {
  object-fit: fill;
  position: relative;
  overflow: clip;
  border-radius: 6px;
  height: fit-content;
  width: 100%;
  min-height: 101px;
  min-width: 154px;
  border: 1px solid transparent;
  transition: .2s;

  .theme_info {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    p {
      color: var(--font-color-white);
      padding: 6px;
      font-size: 12px;
      letter-spacing: 0.01em;
    }
  }
}

.label {
  cursor: pointer;
  flex: 1;
  max-width: 180px;

  .selector {
    border-color: var(--border-color);
  }

  p {
    color: var(--secondary-font-color);
    font-size: 14px;
    line-height: 20px;
    transition: .2s;
    margin-top: 2px;
  }

  &:hover {
    p {
      color: var(--primary-font-color);
    }

    .selector {
      border-color: var(--primary-font-color);
    }
  }

  &[data-is-active="true"] {
    .selector {
      border-color: var(--primary-color);
    }

    p {
      color: var(--primary-color);
    }
  }
}
