

.video-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .container {
    flex: 1;
  }
}
