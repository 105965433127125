@use "src/sass/abstracts/styled-fonts" as *;

.tag_popup_container {
  position: fixed;
  z-index: 10;

  .option_container {
    max-width: 320px;

    > button {
      height: 28px;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border-radius: 0;
    }

    .contact_tag {
      &[data-large='true'] {
        height: 40px;
        min-height: 40px;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        overflow: hidden;

        > p:nth-of-type(2) {
          @include font-body-small-regular;
          color: var(--secondary-font-color);
        }
      }
    }
  }
}
