@use "src/sass/abstracts/styled-fonts" as *;

// User tables
@mixin user-table(
  $first-child-width: 60px,
  $second-child-width: 200px,
  $row-height: 60px,
  $table-height: 360px
) {
  table {
    display: block;
    border-spacing: 0;
    overflow-y: scroll;
    height: $table-height;
    color: var(--primary-font-color);
    border-radius: 0;

    &::-webkit-scrollbar-thumb {
      background: var(--tertiary-color) !important;
      border-radius: 2.5px;
    }

    tr {
      height: $row-height;

      &:nth-child(odd) {
        background: var(--primary-background-color);
      }

      &:nth-child(even) {
        background: #fff;
      }

      td:first-child {
        width: $first-child-width;
      }

      td:nth-child(2) {
        width: $second-child-width;
      }

      td:last-child {
        @include font-body-medium-regular;
        width: calc(340px - (#{$first-child-width} + #{$second-child-width}));
        padding-right: 10px;
        color: #ff7461;
      }
    }
  }
}

// Buttons
@mixin btn(
  $width: 50px,
  $height: 50px,
  $br: 5px,
  $color: #33c3ee,
  $font-size: 14px,
  $font-weight: 400
) {
  width: $width;
  height: $height;
  border-radius: $br;
  color: #fff;
  background: $color;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  width: 340px;
  height: 50px;
  margin: 0 auto;
  border-radius: 3px;

  span {
    &:first-child {
      animation: dot-pulse 1.4s linear infinite both;
    }

    &:nth-child(2) {
      animation: dot-pulse 1.4s linear 0.2s infinite both;
    }

    &:last-child {
      animation: dot-pulse 1.4s linear 0.4s infinite both;
    }
  }
}

//User form
@mixin user-text-input-box {
  position: relative;
  width: 340px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 10px;
  background: #fff;
  border-radius: 4px;

  .popup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 150px;
    padding: 10px;
    background: #fff;
    border: 1px solid var(--color-warning);
    border-radius: 4px;
    box-shadow: 0 5px 13px 0 rgba(51, 41, 37, 0.08);

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before {
      border-color: rgba(255, 116, 97, 0);
      border-top-color: var(--color-warning);
      border-width: 11px;
      margin-left: -11px;
    }

    h6 {
      font-family: var(--font-family), sans-serif;
      color: var(--color-warning);
      font-size: 16px;
      margin-bottom: 5px;
    }

    p {
      color: var(--primary-font-color);
      font-size: 10px;
      text-align: center;
    }
  }

  > p,
  > label {
    display: block;
    color: var(--primary-font-color);
    font-size: 10px;
    height: 20px;
    padding-top: 10px;
  }

  span {
    color: var(--primary-color);
    float: right;
  }

  input {
    font-size: 14px;
    width: 100%;
    height: 28px;
    padding-top: 2px;
  }
}

@mixin text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// BREAKPOINTS

@mixin mobile {
  @media only screen and (max-width: 479px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin small-screens {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin large-screens {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}


@mixin row-options {
  position: absolute;
  z-index: 100;
  right: 20px;
  display: none;
  margin: 3px 0 auto 10px;
  animation: slide-in-right 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  path, circle {
    fill: var(--primary-font-color);
  }
}

@mixin options-list {

  > div {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--primary-background);
    border: 1px solid var(--border-color);
    padding: 4px;
    min-width: 154px;
    max-width: 300px;
    max-height: 400px;
    overflow: hidden auto;
    width: max-content;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    button {
      @include font-body-medium-regular;
      flex: 1;
      background: transparent;
      text-align: left;
      color: var(--primary-font-color);
      padding: 8px 8px 12px;
      border-radius: 2px;
      width: 100%;
      min-height: 32px;
      max-width: 284px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer !important;
      height: max-content;
      transition: .2s;

      &[data-is-large='true'] {
        display: block;
        min-height: 60px;
      }

      &[data-warning='true'] {
        padding: 3px 6px;
        width: max-content;
        color: var(--color-danger);
        position: absolute;
        top: 0;
        right: 0;
      }

      > span {
        color: var(--primary-font-color);
      }

      &:hover, &:focus {
        background: var(--secondary-background);
      }

      &[data-warning-button='true'] {
        color: var(--color-danger);
      }
    }
  }
}

@mixin list-fade {
  position: absolute;
  display: block;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  pointer-events: none;
  background: linear-gradient(180deg, transparent 0%, var(--primary-background) 100%);
}

@mixin settings-page-section-group {
  display: grid;
  grid-template-columns: 355px 1fr;
  padding: 24px;
  grid-column-gap: 48px;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  @include large-screens {
    grid-template-columns: 1fr;
  }
  @include small-screens {
    grid-template-columns: 1fr;
    grid-gap: 4px;
    ~ div {
      margin-top: 24px;
    }
  }
}

@mixin settings-page-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;

  p {
    @include font-body-medium-regular;
    color: var(--primary-font-color);

    &:nth-child(2) {
      color: var(--primary-font-color);
    }
  }

  button {
    display: flex;
    align-items: center;
  }
}

@mixin settings-page-divider {
  display: flex;
  gap: 5px !important;
  width: 100%;

  > h2 {
    @include font-body-medium-medium;
    color: var(--primary-font-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &:nth-child(2) {
    padding: 0 20px 6px 20px;
  }

  > p {
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    color: var(--secondary-font-color);
  }
}

@mixin icon-with-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;

  span {
    width: 28px;
    height: 28px;

    svg path {
      fill: var(--primary-font-color);
    }
  }
}

@mixin list-chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;

  height: 28px;

  //background: #FFFFFF;
  border: 1px solid var(--border-color);
  border-radius: 33px;

  span {
    display: block;
    width: 20px;
    height: 20px;
  }

  p {
    @include font-body-medium-regular;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    border-radius: 6px;

    &:hover, &:focus {

      path {
        fill: var(--color-danger);
      }
    }
  }
}

@mixin header-title-pos-buttons {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 0;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;

    > button {
      @include font-body-medium-regular;

      &[data-warning='true'] {
        color: var(--color-danger);
      }

      &:hover, &:focus {
        background: var(--secondary-background);
      }
    }

    &[data-pos='left'] {
      left: 8px;

      button {
        width: 32px;
        height: 32px;
        border-radius: 6px;
      }
    }

    &[data-pos='right'] {
      right: 8px;

      button {
        padding: 4px 8px;
        border-radius: 6px;
        color: var(--primary-font-color);
      }

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: none;
        }
      }
    }
  }
}

@mixin input-focus {
  position: relative;
  &::before {
    border-radius: inherit;
    content: "";
    position: absolute;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    transform-origin: center center;
    background: var(--primary-color);
    transition: none;
  }

  &:focus-visible {
    opacity: 1;
    transition: none;
  }
}
