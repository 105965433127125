.drag_and_drop {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 10000;
  border-radius: 20%;
  animation: fade-in .2s;
  pointer-events: none;
  overflow: clip;
  box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, var(--tertiary-color) 0 0 0 2px;
  background: var(--primary-background);

  svg path {
    fill: var(--font-color-white);
  }
}

@keyframes fade-in {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
