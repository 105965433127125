@use "src/sass/abstracts/styled-fonts" as *;


.container {

  .error_Section {
    border-radius: 4px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--color-danger-rgb), 0.5);;
    @include font-body-medium-regular;
    width: 100%;
    background: rgba(var(--color-danger-rgb), 0.05);

    p:nth-of-type(1) {
      @include font-body-medium-bold;
      margin-bottom: 8px;
    }

    p, li {
      color: var(--color-danger);
    }

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-left: 4px;

      &:before {
        content: "";
        display: flex;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: currentColor;
      }
    }
  }

  .device_selector {

    > div {
      padding: 0;
    }

    .device {
      height: 36px;
      width: 384px;

      > button {
        width: 100%;
        height: 100%;
        border: none;
      }

      ul {
        min-width: 384px !important;
        max-width: 384px !important;
      }
    }
  }
}