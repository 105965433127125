@use "src/sass/theming/variables" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.toast_container {
  position: fixed;
  z-index: 999;
  top: 16px;
  overflow: visible hidden;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    background: var(--primary-background);
    border-radius: 4px;
    width: max-content;

  }
}


.toast_item {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
  gap: 8px;
  width: max-content;
  max-width: 360px;
  color: var(--_toast-colour);

  background: rgba(var(--_toast-colour), 0.05);
  border: 1px solid rgba(var(--_toast-colour), 0.25);

  path {
    fill: rgba(var(--_toast-colour), 1) !important;
  }

  p, h3 {
    color: rgba(var(--_toast-colour), 1);
  }

  p {
    @include font-body-medium-regular;
  }

  h3 {
    @include font-body-medium-bold;
  }

  .toast_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    button {
      width: 16px;
      height: 16px;
    }
  }

  .toast_content {

  }
}