@use "src/sass/abstracts/styled-fonts" as *;


.menu_actions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button, .danger_select > button:nth-of-type(1) {
    width: 100%;
    min-width: max-content;

    display: grid;
    grid-template-columns: 18px 1fr max-content;
    grid-template-areas: 'icon title slider';
    place-items: center left;
    gap: 8px;

    > span, > span > svg {
      width: 18px;
      height: 18px;
    }

    p {
      @include font-body-medium-regular;
    }
  }

  > button > p {
    color: var(--secondary-font-color);
  }

  .danger_select {
    display: flex;

    > button {
      &:not(:nth-of-type(1)) {
        margin-left: 4px;
        width: max-content;
      }
    }

  }
}

.chat_sidebar_data {
  //background: lavenderblush;
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  grid-template-areas: 'icon data controls';
  grid-gap: 8px;
  place-items: center left;

  .icon_container {
    width: 40px;
    height: 40px;
    grid-area: icon;


    * {
      margin: 0;
    }

    > button {
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;

      > div > img {
        margin: 0;
        padding: 0;
        width: 40px;
        height: 40px;
      }
    }

    figure > div, figure > div > svg {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
    }

    figure > div > i {
      width: 12px;
      height: 12px;
      top: -1px;
      right: -3px;
    }
  }

  .chat_sidebar_name {
    display: flex;
    flex-direction: column;

    > p {
      @include font-body-large-regular;
    }

    > div > p {
      @include font-body-medium-regular;
      color: var(--secondary-font-color);
    }
  }
}

.channel_members {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 4px 16px;
  flex: 1;
  height: clamp(600px, fit-content, 100%);
  overflow: hidden auto;
  margin: -4px;

  > button {
    span, svg {
      width: 18px;
      height: 18px;
    }
  }

  .group_member {
    display: flex;
    min-height: 32px;
    max-height: 32px;
    padding: 0 4px;
    align-items: center;
    gap: 8px;

    .group_member_icon {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      overflow: hidden;

      > img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      > span {
        @include font-body-small-regular;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > p {
      @include font-body-large-regular;
    }

    .group_member_option {
      margin-left: auto;
    }
  }
}

.chat_side_input {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 4px 0 4px 8px;
  width: 100%;
  height: 36px;
  max-height: 40px;
  border-radius: 4px;
  border: 1px solid var(--border-color);

  &:focus-within {
    border: 1px solid var(--focus-border-color);
  }

  > div {
    flex-direction: row;
    align-items: center;
    flex: 1;

    > span {
      width: 28px;
      height: 28px;
    }

    > svg {
      min-height: 24px;
      min-width: 24px;
      max-height: 24px;
      max-width: 24px;
      margin-right: 4px;
    }

    input {
      @include font-body-medium-regular;
      width: 100%;
      background: none !important;
    }
  }
}

.search_results {
  flex: 1;
  height: 100%;
  width: 100%;
}