.preview_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 20%), rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);

  .ghost_exit {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    min-height: 300px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0, transparent 100%);
  }

  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    min-height: 300px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0, transparent 100%);
  }

  .preview {
    z-index: 1;
    position: relative;
    padding: 28px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(100vh - 60px - 60px - 46px - 24px) 60px;
    grid-gap: 12px;
    pointer-events: none;

    .preview_info {
      display: flex;
      flex-direction: column;
      gap: 3px;
      pointer-events: all;
      width: max-content;

      h1 {
        color: var(--font-color-white);
        font-size: 16px;
      }

      p {
        color: var(--font-color-white);
        font-size: 12px;
      }

      > button {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 16px;
        span svg {
          path {
            transition: .2s;
            fill: var(--font-color-white);
          }
        }
        &:hover {
          span svg {
            path {
              fill: var(--color-danger);
            }
          }
        }
      }
    }

    .preview_download {
      pointer-events: all;
      display: flex;
      gap: 8px;
      align-items: center;

      p {
        font-size: 12px;
        color: var(--font-color-white);
      }

      button {
        border-color: var(--font-color-white);

        path {
          stroke: var(--font-color-white);
        }

        &:hover, &:focus {
          background: rgba(white, 0.3);
        }
      }
    }

    .preview_content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      img, video, .iframe_container {
        max-height: 600px;
        max-width: 1000px;
        min-height: 200px;
        min-width: 200px;
        position: relative;
        object-fit: contain;
        pointer-events: all;
        border-radius: 4px;
        background: rgba(white, 0.1);
        border: 1px solid rgba(white, 0.4);
      }

      .iframe_container {
        border-width: 3px;
        box-sizing: content-box;
      }

      iframe {
        width: 100vw;
        height: calc(100vh - 200px);
        max-height: 600px;
        max-width: 1000px;
        min-height: 200px;
        min-width: 200px;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        overflow: overlay !important;
      }
    }
  }
}
