@use "src/sass/abstracts/styled-fonts" as *;

.quick_settings {
  position: absolute;
  left: 52px;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 344px;
  padding: 12px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--primary-background);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

  .user_details {
    margin-bottom: 8px;
    width: 100%;
    position: relative;

    > p {
      @include font-body-large-medium;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    >.departments {
        margin-top: 8px;
    }

    .data_refresh {
        position: absolute;
        top: -2px;
        right: 8px;

        > div {
            @include font-body-small-regular;
            position: absolute;
            top: calc(100% + 8px);
            left: -12px;
            max-width: 200px;
            padding: 8px;
            background: var(--primary-background);
            border-radius: 4px;
            box-shadow: 0 5px 13px 0 rgba(51, 41, 37, 0.08);
            z-index: 10;
            border: 1px solid var(--border-color);
            /* opacity: 0; */
            /* transition: opacity 0.2s; */

            > p {
              display: flex;
              max-width: 100%;
              width: max-content;
              color: var(--primary-font-color)
            }

            &:after,
            &:before {
              bottom: 100%;
              left: 24px;
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              transform: rotate(180deg);
            }

            &:before {
              border-color: transparent;
              border-top-color: var(--border-color);
              border-width: 6px;
              margin-left: 4px;
            }
          }
        }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 7px 0;
    height: 30px;
    //overflow: hidden;
    transform-origin: bottom center;

    & ~ .item {
      margin-top: 4px
    }

    > label {
      @include font-body-medium-medium;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .dropdown {
      > button {
        height: 28px;

        p {
          @include font-body-small-regular;
        }
      }

      ul {
        max-height: 280px;
        max-width: 280px;
      }
    }

    .loading {
      display: flex;
      gap: 4px;

      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
