.drag_and_drop_keypad {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: .2s;
  z-index: 10000;
  overflow: clip;
  pointer-events: all;
  padding: 20px;
  backdrop-filter: blur(8px);

  &:not([data-continuing='true']) {
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;

    > button {
      flex: 1;
      position: relative;
      width: 100%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--font-color-white);
      transition: .2s;
      border-radius: 15px;
      background: rgba(0, 0, 0, 0.3);

      &[data-active='true'], &:hover {
        background: rgba(0, 0, 0, 0.3);
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        transform: scale(1.02);
      }

      > span:first-of-type {
        width: 40px;
        height: 40px;

        svg {
          height: 100%;
          width: 100%;

          path {
            fill: white;
          }
        }
      }
    }
  }

  &[data-continuing='true'] {
    gap: 8px;
    background: rgba(0, 0, 0, 0.4);
    max-height: 430px;
    overflow: hidden auto;
    border-radius: 20px;

    > button {
      width: max-content;
      padding: 4px 6px;
      border-radius: 6px;
      color: var(--color-danger);
      font-size: 14px;

      &:hover, &:focus {
        background: var(--secondary-background);
      }
    }

    p {
      color: var(--font-color-white);
    }

    > div {

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > button {
          width: 28px;
          height: 28px;
          border-radius: 6px;

          &:hover, &:focus {
            background: var(--secondary-background);
          }

          path {
            fill: var(--color-success);
          }
        }
      }
    }
  }

  &[data-active-call='true'] {
    .call {
      height: 250px;
    }

    > button {
      background: rgba(255, 255, 255, 0.2);

      &[data-active='true'], &:hover {
        background: rgba(255, 255, 255, 0.3);
      }

      > span, span svg path {
        color: var(--font-color-white);
        fill: white;
        pointer-events: none;
      }
    }
  }

  span {
    pointer-events: none;
  }
}
