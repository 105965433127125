.attachments_grand_container {
  height: fit-content;
  display: grid;
  overflow: clip;
  margin-top: -6px;
  .attachments_container {
    left: -6px;
    position: relative;
    display: flex;
    gap: 10px;
    padding: 12px 8px 6px 8px;
    overflow-y: clip;
    overflow-x: auto;
    margin-top: -4px;
  }
}
