@use "src/sass/abstracts/styled-fonts" as *;

.app_crash_container {
  position: fixed;
  top: 50%;
  background: var(--primary-background);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 24px;
  border-radius: var(--border-radius-large);
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid var(--border-color);

  h2 {
    color: var(--primary-font-color);
    margin: 0 auto;
  }

  > p {
    display: flex;

    > a {
      color: var(--notification-color);

      &:hover, &:focus {
        filter: brightness(0.9);
      }
    }

    &:nth-of-type(2) {
      @include font-body-medium-regular;
      color: var(--secondary-font-color);
    }
  }

  >button {
    margin-bottom: 24px;
  }
}
