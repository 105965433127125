@use "src/sass/abstracts/styled-fonts" as *;


.container {

  > .data {
    width: 100%;

    > h2 {
      color: var(--primary-font-color);
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    > p {
      @include font-body-large-medium;
      color: var(--secondary-font-color);
    }
  }
}