@use "src/sass/abstracts/styled-fonts" as *;

.container {
  width: 544px;
  min-height: 395px;
  justify-content: flex-start;

  form {
    flex: 1 !important;
  }

  .member_list {

    > ul {
      grid-area: input;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border: 1px solid transparent;
      border-radius: 4px;

      &[data-error='true'] {
        border-color: var(--color-danger);
      }

      > div {
        height: 40px;
        padding: 4px 12px;
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        place-items: center left;

        > p {
          @include font-body-medium-regular;
        }

        figure > div {
          max-width: 32px;
          max-height: 32px;
          min-height: 32px;
          min-width: 32px;

          span {
            @include font-body-small-regular;
          }
        }
      }
    }

    > p {
      grid-area: extra;
      @include font-body-small-regular;
      width: 100%;
      text-align: right;
      color: var(--color-danger);
      margin-top: 4px;
    }
  }

  .formControls {
    margin-top: auto;
  }
}

.member_selector {
  position: relative;
  width: 100%;

  > ul {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 240px;
    overflow: hidden auto;
    background: var(--primary-background);
    border: 1px solid var(--border-color);
    z-index: 1;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    margin-top: 2px;
    border-radius: 4px;

    > button {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 32px;
      padding: 4px 12px;

      > div {
        margin: 0;
      }

      > p {
        @include font-body-medium-regular;
      }

      &[data-is-selected='true'] {
        background: var(--tertiary-color);

        > p {
          color: var(--font-color-white);
        }
      }

      &:hover, &:focus {
        background: var(--secondary-background);
      }
    }
  }

  .user_chip {
    height: 28px;
    padding: 0 4px;
    border: 1px solid var(--border-color);
    display: grid;
    grid-template-columns: 18px max-content max-content;
    grid-gap: 4px;
    place-items: center left;
    border-radius: 4px;
    background: var(--secondary-background);

    p {
      @include font-body-small-regular;
      max-width: 96px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div {
      grid-area: revert;
    }

    > button {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: var(--secondary-background);

      span, svg {
        width: 14px !important;
        height: 14px !important;

        path {
          fill: var(--tertiary-background);
        }
      }

      &:hover, &:focus {
        background: var(--primary-background);

        path {
          fill: var(--primary-font-color);
        }
      }
    }
  }
}