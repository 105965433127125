@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.container {
  position: relative;
  display: flex;
  padding: 10px 25px 10px 20px;
  gap: 10px;
  height: 69px;
  width: calc(100% - 48px);
  margin: 0 24px;
  border-radius: 4px;
  transition: .2s;
  //cursor: pointer;

  &:hover, &:focus, &:focus-within {
    background: var(--secondary-background);

    &[data-has-options='true'] {
      .timeData {
        animation: hideTimeData 0.25s ease;
        animation-fill-mode: forwards;

        @keyframes hideTimeData {
          0% {
            opacity: 1
          }
          100% {
            opacity: 0;
            display: none
          }
        }
      }

      .hoverMenu {
        visibility: visible;
        z-index: unset;
        animation: showHoverMenu 0.4s ease;
        opacity: 1;

        button {
          >svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            background: var(--tertiary-background);
          }
        }

        @keyframes showHoverMenu {
          0% {
            opacity: 0
          }
          100% {
            opacity: 1
          }
        }
      }
    }
  }

  .callStatus {
    height: 100%;

    path {
      fill: var(--color-success);
    }
  }

  .dataRows {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--primary-font-color);

    p {

      color: var(--primary-font-color);

      svg {
        width: 14px;
        height: 14px;
      }
    }

    p:nth-child(2) {
      @include font-body-medium-regular;
      color: var(--secondary-font-color);
      display: flex;
      align-items: center;
      gap: 4px;

      >span {
        isolation: isolate;
        opacity: 1;
        color: var(--primary-font-color);
      }
    }
  }

  .caller {
    flex: 1;

    > div {
      display: flex;
      gap: 6px;
      align-items: baseline;
    }
  }

  .timeData {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      @include font-body-large-regular;
    }

    p:nth-child(2) {
      @include font-body-small-regular;
    }

  }

  &[data-answered='false'] {
    .callStatus {
      path {
        fill: var(--color-danger);
      }
    }

    .caller {
      .callerName {
        p {
          color: var(--color-danger);
          opacity: 1;
        }
      }
    }
  }

  &[data-elsewhere='true'] {
    .callStatus {
      path {
        fill: var(--notification-color);
      }
    }
  }

  .hoverMenu {
    position: absolute;
    right: 20px;
    //visibility: hidden;
    display: flex;
    gap: 10px;
    z-index: -1;
    opacity: 0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      cursor: pointer;

      &:hover, &:focus {
        background: var(--primary-background);
      }

      span {
        height: 22px;
      }
    }
  }
}
