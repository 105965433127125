
* {
  &[data-def-no-border='true'] {
    border: none !important;
  }
}

dialog[open] {
  width: 100%;
  height: 100%;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}