@use "src/sass/abstracts/styled-fonts" as *;

.list-container {

  &.contacts-list, &.call-history__list, &.recent__chat-list {
    border-radius: 6px;
  }

  &.contacts-list {
    height: calc(100% - 192px);
    position: relative;
    transition: width .3s ease-out;

    &::after {
      @include list-fade();
    }

    .windowed-contact-list-container > div {
      min-height: 100%;
    }

    //> div > div > div {
    //  overflow: visible !important;
    //  overflow-x: hidden !important;
    //}

    //* {
    //  overflow: visible !important;
    //  z-index: unset !important;
    //}
  }

  &.chat-header__messages-search {
    width: 352px;
    padding: -2px;
    border-radius: 4px;
    align-self: center;
    margin-left: 2px;
  }

  &.meeting-list {
    height: calc(100% - 138px);
    width: calc(100% - 1px);
    overflow: hidden auto;
    color: var(--primary-color);
    margin: 0 -2px 0 0;
  }

  &.call-history__list {
    height: calc(100% - 138px);
    width: calc(100% - 1px);
    overflow: hidden auto;
    color: var(--primary-color);
    margin: 0 -2px 0 0;

    .windowed-call-history-list-container {
      overflow: hidden auto !important;
    }

    .call_history_loader {
      margin: 0 auto;
      padding: 4px 0 12px;
      height: 48px;
      display: flex;
      justify-content: center;
    }
  }

  .list-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    padding: 20px;

    p {
      @include font-body-medium-regular;
      color: var(--secondary-font-color);
      margin-bottom: 10px;
    }

    svg {
      width: 28px;
    }
  }

  .windowed-in-chat-search-results-container {
    > div > div ~ div {
      padding-top: 4px;
    }
  }

  .windowed-contact-list-container-center {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    //overflow: clip !important;
    //overflow-clip-margin: 1000px;

    > div {
      min-height: 100%;
      overflow: visible !important;
    }
  }

  .windowed-chat-list-container {
    padding-bottom: 30px;
  }

  .windowed-contact-list-container {
    overflow: overlay !important;
    padding-bottom: 30px;
    transition: width .3s ease-out;

    > div {
      transition: width .3s ease-out;

      > div {
        transition: width .3s ease-out;
      }
    }
  }
}

.list-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 0 10px 10px;

  > p {
    margin-left: 10px;
    color: var(--secondary-font-color);
  }
}

.list-divider {
  display: flex;
  align-items: center;
  width: auto;
  height: 24px;
  box-sizing: border-box;
  margin: 0 20px;

  p {
    @include font-body-medium-medium;
    text-align: center;
    color: var(--secondary-font-color);
    text-transform: uppercase;
  }
}

.message-preview {
  display: flex;
  align-items: flex-start;

  > img {
    margin-right: 2px;
  }

  > p {
    @include font-body-small-regular;
    color: var(--secondary-font-color);
    word-break: break-word;
    margin-right: 30px;
  }
}

.contact-list__container {
  height: 400px;

  .windowed-contact-list-container > div {
    min-height: 100%;
  }
}