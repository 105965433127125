@use "src/sass/abstracts/styled-fonts" as *;

.calls_widget {
  position: absolute;
  left: 0;
  background: var(--tertiary-background);
  width: 100%;
  height: 475px !important;
  bottom: 0 !important;
  border-radius: 20px;
  padding: 10px 10px 30px;
  color: var(--font-color-white);
  pointer-events: all;
  z-index: -1;
  box-sizing: border-box;

  > div {
    &.header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 5px;

      p {
        color: var(--font-color-white);
      }

      button {
        @include font-body-small-regular;
        background: var(--primary-color);
        border-radius: 25px;
        padding: 3px 10px;
        color: var(--font-color-white);

        &:hover {
          background: white;
          color: var(--primary-color);
        }
      }
    }
    &.call_list {
      transition: .2s ease-out;
      height: fit-content;
      max-height: 334px;
      overflow: hidden auto;
      padding: 10px 0;
      pointer-events: none;
    }
  }

  &[data-is-active='true'] {
    > div {
      &.header {
        button {
          background: transparent;
        }
      }
      &.call_list {
        pointer-events: all;
      }
    }
  }
  &[data-is-visible='false'] {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.calls_widget_call_info {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  padding: 10px 15px;
  border-radius: 15px;
  opacity: 0.7;
  transition: .2s;

  &[data-group='true'] {
    grid-template-columns: 32px auto 42px;
    align-items: center;

    ul {
      max-height: 68px;
      overflow: hidden auto;

      p {
        font-size: 14px;
        color: var(--font-color-white);
        max-width: 120px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  path {
    fill: white;
  }

  > h3 {
    color: var(--font-color-white);
    grid-column: 1/3;
    font-weight: 500;
    font-size: 16px;
  }

  p {
    color: var(--font-color-white);
    font-size: 12px;
    &.counter {
      color: var(--font-color-white);
      text-align: right;
    }
  }

  &:hover {
    opacity: 1;
    background: rgba(black, 0.2);
  }
}
