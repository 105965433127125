

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-size: 20px;
  font-style: normal !important;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down {
  color: var(--secondary-font-color);

  &:before {
    content: "\e97a";
  }
}

.icon-arrow-left {
  color: var(--secondary-font-color);

  &:before {
    content: "\e97b";
  }
}

.icon-arrow-right {
  color: var(--secondary-font-color);

  &:before {
    content: "\e97c";
  }
}

.icon-arrow-up {
  color: var(--secondary-font-color);

  &:before {
    content: "\e97d";
  }
}

.icon-ellipse-green .path1:before {
  content: "\e97e";
  color: var(--color-success);
  opacity: 0.7;
}

.icon-ellipse-green .path2:before {
  content: "\e97f";
  color: var(--color-success);
  opacity: 0.2;
  margin-left: -1em;
}

.icon-eye-fill-slash {
  color: var(--secondary-font-color);

  &:before {
    content: "\e980";
  }
}

.icon-eye-fill {
  color: var(--secondary-font-color);

  &:before {
    content: "\e981";
  }
}

.icon-information {
  color: var(--secondary-font-color);

  &:before {
    content: "\e982";
  }
}

.icon-link {
  color: var(--secondary-font-color);

  &:before {
    content: "\e983";
  }
}

.icon-repeat {
  color: var(--secondary-font-color);

  &:before {
    content: "\e984";
  }
}

.icon-status-accepted .path1:before {
  content: "\e985";
  color: var(--color-success);
}
.icon-status-accepted .path2:before {
  content: "\e986";
  color: var(--primary-background);
  margin-left: -1em;
}

.icon-status-pending .path1:before {
  content: "\e987";
  color: var(--primary-font-color);
}
.icon-status-pending .path2:before {
  content: "\e988";
  color: var(--primary-background);
  margin-left: -1em;
}

.icon-status-rejected .path1:before {
  content: "\e989";
  color: var(--primary-color);
}
.icon-status-rejected .path2:before {
  content: "\e98a";
  color: var(--primary-background);
  margin-left: -1em;
}

.icon-user-add {
  color: var(--secondary-font-color);

  &:before {
    content: "\e98b";
  }
}

.icon-video-add {
  color: var(--secondary-font-color);

  &:before {
    content: "\e98c";
  }
}

.icon-pulse-hd .path1:before {
  content: "\e976";
  color: var(--primary-font-color);
  opacity: 0.74;
}
.icon-pulse-hd .path2:before {
  content: "\e977";
  color: var(--primary-font-color);
  opacity: 0.26;
  margin-left: -2.6328125em;
}
.icon-pulse-hd .path3:before {
  content: "\e978";
  color: var(--primary-font-color);
  margin-left: -2.6328125em;
}
.icon-pulse-hd .path4:before {
  content: "\e979";
  color: var(--primary-font-color);
  margin-left: -2.6328125em;
}

.icon-yay {
  color: var(--secondary-font-color);

  &:before {
    content: "\e975";
  }
}

.icon-messanger {
  color: var(--secondary-font-color);

  &:before {
    content: "\e904";
  }
}
.icon-sms {
  color: var(--secondary-font-color);

  &:before {
    content: "\e903";
  }
}

.icon-whatsapp .path1:before {
  content: "\e900";
  color: var(--secondary-font-color);
}
.icon-whatsapp .path2:before {
  content: "\e901";
  color: var(--secondary-font-color);
  margin-left: -1em;
}
.icon-whatsapp .path3:before {
  content: "\e902";
  color: var(--secondary-font-color);
  margin-left: -1em;
}

.icon-pin-outline {
  color: var(--secondary-font-color);

  &:before {
    content: "\e974";
  }

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-pin {
  color: var(--tertiary-color);

  &:before {
    content: "\e973";
  }
}

.icon-paperclip {
  color: var(--secondary-color);

  &:before {
    content: '\e947';
  }

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-right-arrow {
  color: #80cd42;

  &:before {
    content: '\e954';
  }

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-thread {
  color: #d7c6c2;

  &:before {
    content: '\e95a';
  }
}

.icon-thread--hover {
  @extend .icon-thread;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-threads {
  color: #d7c6c2;

  &:before {
    content: '\e960';
  }
}

.icon-threads--hover {
  @extend .icon-threads;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-left-arrow {
  color: var(--primary-color);

  &:before {
    content: '\e938';
  }
}

.icon-left-arrow--hover {
  @extend .icon-left-arrow;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-chat-no-entry {
  color: #d7c6c2;

  &:before {
    content: '\e91a';
  }
}

.icon-chat-groups {
  color: #d7c6c2;

  &:before {
    content: '\e919';
  }
}

.icon-chat-cog {
  color: #d7c6c2;

  &:before {
    content: '\e918';
  }
}

.icon-chat-bubble {
  color: var(--secondary-color);

  &:before {
    content: '\e917';
  }
}

.icon-chat-bubble--hover {
  @extend .icon-chat-bubble;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-add-user {
  color: #d7c6c2;

  &:before {
    content: '\e912';
  }
}

.icon-add-user--hover {
  @extend .icon-add-user;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-listen {
  color: var(--tertiary-color);

  &:before {
    content: '\e93a';
  }
}

.icon-listen--hover {
  @extend .icon-listen;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-barge {
  color: var(--primary-color);

  &:before {
    content: '\e909';
  }
}

.icon-barge--hover {
  @extend .icon-barge;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-whisper {
  color: var(--secondary-color);

  &:before {
    content: '\e966';
  }
}

.icon-whisper--hover {
  @extend .icon-whisper;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-chat-notifications-enabled {
  color: var(--color-success);

  &:before {
    content: '\e91c';
  }
}

.icon-chat-notifications-enabled--hover {
  @extend .icon-chat-notifications-enabled;

  &:hover {
    color: var(--primary-color);
  }
}

.icon-chat-notifications-disabled {
  color: var(--primary-color);

  &:before {
    content: '\e91b';
  }
}

.icon-chat-notifications-disabled--hover {
  @extend .icon-chat-notifications-disabled;

  &:hover {
    color: var(--color-success);
  }
}

.icon-contacts {
  color: #d7c6c2;

  &:before {
    content: '\e921';
  }
}

.icon-create-chat {
  color: var(--secondary-color);

  &.disable {
    color: #d7c6c2;
    user-select: none;
    cursor: default;

    &:hover {
      color: #d7c6c2;
    }
  }

  &:before {
    content: '\e923';
  }
}

.icon-create-chat--hover {
  @extend .icon-create-chat;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-cross {
  color: var(--primary-color);

  &:before {
    content: '\e924';
  }
}

.icon-cross--hover {
  @extend .icon-cross;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-favourite-enabled {
  color: var(--tertiary-color);

  &:before {
    content: '\e92f';
  }
}

.icon-favourite-enabled--hover {
  @extend .icon-favourite-enabled;

  &:hover {
    color: var(--primary-font-color);

    &:before {
      content: '\e92e';
    }
  }
}

.icon-favourite-disabled {
  color: var(--primary-font-color);

  &:before {
    content: '\e92e';
  }
}

.icon-favourite-disabled--hover {
  @extend .icon-favourite-disabled;

  &:hover {
    color: var(--tertiary-color);

    &:before {
      content: '\e92f';
    }
  }
}

.icon-phone-call {
  color: var(--color-success);

  &:before {
    content: '\e94a';
  }
}

.icon-phone-call--hover {
  @extend .icon-phone-call;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-phone-pickup {
  color: var(--color-success);

  &:before {
    content: '\e94c';
  }
}

.icon-phone-pickup--hover {
  @extend .icon-phone-pickup;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-logout {
  color: var(--primary-color);

  &:before {
    content: '\e93b';
  }
}

.icon-logout--hover {
  @extend .icon-logout;

  &:hover {
    cursor: pointer;
    color: rgba(255,255,255,0.9);
  }
}

.icon-swap {
  color: var(--secondary-color);

  &:before {
    content: '\e95f';
  }
}

.icon-swap--hover {
  @extend .icon-swap;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-minimise {
  color: #fff;

  &:before {
    content: '\e940';
  }
}

.icon-minimise--hover {
  @extend .icon-minimise;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-maximise {
  color: #fff;
  transform: rotate(180deg);

  &:before {
    content: '\e93b';
  }
}

.icon-maximise--hover {
  @extend .icon-maximise;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-mute {
  color: var(--primary-color);

  &:before {
    content: '\e96c';
  }
}

.icon-mute--hover {
  @extend .icon-mute;

  &:hover {
    color: #fff;

    &:before {
      content: '\e937';
    }
  }
}

.icon-mute-off {
  color: var(--primary-color);

  &:before {
    content: '\e93c';
  }
}

.icon-mute-off--hover {
  @extend .icon-mute;

  &:hover {
    color: #fff;

    &:before {
      content: '\e967';
    }
  }
}

.icon-microphone {
  color: #fff;

  &:before {
    content: '\e93f';
  }
}

.icon-microphone--hover {
  @extend .icon-microphone;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-volume-speaker {
  color: #fff;

  &:before {
    content: '\e92e';
  }
}

.icon-volume-speaker--hover {
  @extend .icon-volume-speaker;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-keypad {
  color: var(--primary-color);

  &:before {
    content: '\e937';
  }
}

.icon-keypad--hover {
  @extend .icon-keypad;

  &:hover {
    color: #fff;
  }
}

.icon-hold {
  color: var(--primary-color);

  &:before {
    content: '\e931';
  }
}

.icon-hold--hover {
  @extend .icon-hold;

  &:hover {
    color: #fff;
  }
}

.icon-add {
  color: var(--primary-color);

  &:before {
    content: '\e906';
  }
}

.icon-add--hover {
  @extend .icon-add;

  &:hover {
    color: #fff;
  }
}

.icon-transfer {
  color: var(--secondary-color);

  &:before {
    content: '\e962';
  }
}

.icon-transfer--hover {
  @extend .icon-transfer;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-speaker {
  color: var(--primary-color);

  &:before {
    content: '\e933';
  }
}

.icon-speaker--hover {
  @extend .icon-speaker;

  &:hover {
    color: #fff;
  }
}

.icon-phone-start-call {
  color: #fff;

  &:before {
    content: '\e94d';
  }
}

.icon-phone-start-call--hover {
  @extend .icon-phone-start-call;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-phone-end-call {
  color: #fff;

  &:before {
    content: '\e94b';
  }
}

.icon-phone-end-call--hover {
  @extend .icon-phone-end-call;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-dialpad {
  color: var(--primary-font-color);

  &:before {
    content: '\e927';
  }
}

.icon-dialpad--hover {
  @extend .icon-dialpad;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-reply-private {
  color: var(--secondary-color);

  &:before {
    content: '\e951';
  }
}

.icon-reply-private--hover {
  @extend .icon-reply-private;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-reply {
  color: var(--color-success);

  &:before {
    content: '\e952';
  }
}

.icon-reply--hover {
  @extend .icon-reply;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-copy {
  color: #837470;

  &:before {
    content: '\e922';
  }
}

.icon-copy--hover {
  @extend .icon-copy;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-burger {
  color: #fff;

  &:before {
    content: '\e90c';
  }
}

.icon-burger--hover {
  @extend .icon-burger;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-blf-online {
  color: var(--primary-font-color);

  &:before {
    content: '\e90b';
  }
}

.icon-blf-online--hover {
  @extend .icon-blf-online;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-missed-calls {
  color: var(--primary-font-color);

  &:before {
    content: '\e941';
  }
}

.icon-missed-calls--hover {
  @extend .icon-missed-calls;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-search {
  color: var(--primary-color);

  &:before {
    content: '\e953';
  }
}

.icon-search--hover {
  @extend .icon-search;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-queuing {
  color: var(--primary-font-color);

  &:before {
    content: '\e950';
  }
}

.icon-queuing--hover {
  @extend .icon-queuing;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-parking {
  color: var(--primary-font-color);

  &:before {
    content: '\e948';
  }
}

.icon-parking--hover {
  @extend .icon-parking;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-all {
  color: var(--primary-font-color);

  &:before {
    content: '\e907';
  }
}

.icon-all--hover {
  @extend .icon-all;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-settings-alt {
  color: #fff;

  &:before {
    content: '\e955';
  }
}

.icon-settings-alt--hover {
  @extend .icon-settings-alt;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-backspace {
  color: var(--primary-color);

  &:before {
    content: '\e908';
  }
}

.icon-backspace--hover {
  @extend .icon-backspace;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-doc {
  color: var(--tertiary-color);

  &:before {
    content: '\e928';
  }
}

.icon-mp3 {
  color: var(--tertiary-color);

  &:before {
    content: '\e942';
  }
}

.icon-mp4 {
  color: var(--tertiary-color);

  &:before {
    content: '\e943';
  }
}

.icon-pdf {
  color: var(--tertiary-color);

  &:before {
    content: '\e949';
  }
}

.icon-txt {
  color: var(--tertiary-color);

  &:before {
    content: '\e963';
  }
}

.icon-html {
  color: var(--tertiary-color);

  &:before {
    content: '\e932';
  }
}

.icon-wav {
  color: var(--tertiary-color);

  &:before {
    content: '\e965';
  }
}

.icon-ppt {
  color: var(--tertiary-color);

  &:before {
    content: '\e94f';
  }
}

.icon-xls {
  color: var(--tertiary-color);

  &:before {
    content: '\e967';
  }
}

.icon-img {
  color: var(--tertiary-color);

  &:before {
    content: '\e934';
  }
}

.icon-file {
  color: var(--tertiary-color);

  &:before {
    content: '\e930';
  }
}

.icon-zip {
  color: var(--tertiary-color);

  &:before {
    content: '\e968';
  }
}

.icon-exe {
  color: var(--tertiary-color);

  &:before {
    content: '\e92d';
  }
}

.icon-csv {
  color: var(--tertiary-color);

  &:before {
    content: '\e925';
  }
}

.icon-download {
  color: var(--tertiary-color);

  &:before {
    content: '\e92b';
  }
}

.icon-download--hover {
  @extend .icon-download;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-status-available {
  color: var(--color-success);

  &:before {
    content: '\e95b';
  }
}

.icon-status-available--hover {
  @extend .icon-status-available;
}

.icon-status-ringing {
  color: var(--color-warning);
  animation: ringing 0.5s linear 0s infinite alternate;

  &:before {
    content: '\e95e';
  }
}

.icon-status-ringing--hover {
  @extend .icon-status-ringing;

  &:hover {
    color: var(--color-success);
  }
}

.icon-status-busy {
  color: var(--color-warning);

  &:before {
    content: '\e95c';
  }
}

.icon-status-busy--hover {
  @extend .icon-status-busy;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-status-offline {
  color: var(--color-danger);

  &:before {
    content: '\e95d';
  }
}

.icon-status-offline--hover {
  @extend .icon-status-offline;

  &:hover {
    color: var(--color-danger);
  }
}

.icon-circle-play {
  color: var(--tertiary-color);

  &:before {
    content: '\e90f';
  }
}

.icon-circle-play--hover {
  @extend .icon-circle-play;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-circle-stop {
  color: var(--color-danger);

  &:before {
    content: '\e911';
  }
}

.icon-circle-stop--hover {
  @extend .icon-circle-stop;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-circle-close {
  color: var(--color-danger);

  &:before {
    content: '\e91d';
  }
}

.icon-circle-close--hover {
  @extend .icon-circle-close;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-circle-duplicate {
  color: var(--tertiary-color);

  &:before {
    content: '\e91e';
  }
}

.icon-circle-duplicate--hover {
  @extend .icon-circle-duplicate;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-circle-tick {
  color: var(--color-success);

  &:before {
    content: '\e91f';
  }
}

.icon-circle-tick--hover {
  @extend .icon-circle-tick;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-single-tick {
  font-size: 14px;
  color: #baaeab;

  &:before {
    content: '\e93e';
  }
}

.icon-single-tick--red {
  @extend .icon-single-tick;
  color: var(--color-danger);
}

.icon-double-tick {
  font-size: 14px;
  color: #baaeab;

  &:before {
    content: '\e93d';
  }
}

.icon-double-tick--green {
  @extend .icon-double-tick;
  color: var(--color-success);
}

.icon-code {
  color: var(--tertiary-color);

  &:before {
    content: '\e920';
  }
}

.icon-code--hover {
  @extend .icon-code;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-double-cog {
  color: var(--tertiary-color);

  &:before {
    content: '\e929';
  }
}

.icon-double-cog--hover {
  @extend .icon-double-cog;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-small-speaker {
  color: #fff;

  &:before {
    content: '\e957';
  }
}

.icon-small-speaker--hover {
  @extend .icon-small-speaker;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-small-speaker-muted {
  color: #fff;

  &:before {
    content: '\e958';
  }
}

.icon-small-speaker-muted--hover {
  @extend .icon-small-speaker-muted;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-incoming {
  color: var(--color-success);

  &:before {
    content: '\e935';
  }
}

.icon-incoming--hover {
  @extend .icon-incoming;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-internal {
  color: var(--secondary-color);

  &:before {
    content: '\e936';
  }
}

.icon-internal--hover {
  @extend .icon-internal;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-outgoing {
  color: var(--color-danger);

  &:before {
    content: '\e946';
  }
}

.icon-outgoing--hover {
  @extend .icon-outgoing;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-shortcode {
  color: var(--secondary-color);

  &:before {
    content: '\e956';
  }
}

.icon-shortcode--hover {
  @extend .icon-shortcode;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-pencil {
  color: var(--color-danger);

  &:before {
    content: '\e92c';
  }
}

.icon-pencil--hover {
  @extend .icon-pencil;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-down-arrow {
  font-size: 6px;
  color: var(--primary-font-color);

  &:before {
    content: '\e92a';
  }
}

.icon-down-arrow--hover {
  @extend .icon-down-arrow;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-bin {
  color: var(--color-danger);

  &:before {
    content: '\e90a';
  }
}

.icon-bin--hover {
  @extend .icon-bin;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-edit {
  color: var(--secondary-color);

  &:before {
    content: '\e90e';
  }
}

.icon-edit--hover {
  @extend .icon-edit;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-lightbulb {
  color: var(--secondary-color);

  &:before {
    content: '\e939';
  }
}

.icon-lightbulb--hover {
  @extend .icon-lightbulb;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-delete {
  color: var(--color-danger);

  &:before {
    content: '\e926';
  }
}

.icon-delete--hover {
  @extend .icon-delete;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-plus {
  color: var(--tertiary-color);

  &:before {
    content: '\e910';
  }
}

.icon-plus--hover {
  @extend .icon-plus;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-add-contact {
  color: var(--secondary-color);

  &:before {
    content: '\e94e';
  }
}

.icon-add-contact--hover {
  @extend .icon-add-contact;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-voicemail {
  color: var(--tertiary-color);

  &:before {
    content: '\e964';
  }
}

.icon-voicemail--hover {
  @extend .icon-voicemail;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-transcription {
  color: var(--secondary-font-color);

  &:before {
    content: '\e961';
  }
}

.icon-transcription--hover {
  @extend .icon-transcription;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-headset {
  color: var(--tertiary-color);

  &:before {
    content: '\e945';
  }
}

.icon-headset--hover {
  @extend .icon-headset;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-about {
  color: var(--tertiary-color);

  &:before {
    content: '\e905';
  }
}

.icon-about--hover {
  @extend .icon-about;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-camera {
  color: var(--font-color-white);

  &:before {
    content: '\e944';
  }
}

.icon-camera-off {
  color: var(--font-color-white);

  &:before {
    content: '\e96b';
  }
}

.icon-camera-outline {
  color: var(--font-color-white);

  &:before {
    content: '\e969';
  }
}

.icon-chat-bubble-inverted {
  color: var(--font-color-white);

  &:before {
    content: '\e96a';
  }
}

.icon-users {
  color: var(--font-color-white);

  &:before {
    content: '\e094';
  }
}

.icon-calendar {
  color: var(--font-color-white);

  &:before {
    content: '\6a';
  }
}

.icon-mail {
  color: var(--secondary-color);

  &:before {
    content: '\e048';
  }
}

.icon-mail--hover {
  @extend .icon-mail;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-check {
  color: var(--color-success);

  &:before {
    content: '\e00a';
  }
}

.icon-check--hover {
  @extend .icon-check;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-search-2 {
  color: var(--color-danger);

  &:before {
    content: '\e96e';
  }
}

.icon-search-2--hover {
  @extend .icon-search-2;

  &:hover {
    color: var(--tertiary-color);
  }
}

.icon-plus-inverted {
  color: var(--tertiary-color);

  &:before {
    content: '\e96f';
  }
}

.icon-plus-inverted--hover {
  @extend .icon-plus-inverted;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-cross-2 {
  color: var(--color-danger);

  &:before {
    content: '\e972';
  }
}

.icon-cross-2--hover {
  @extend .icon-cross-2;

  &:hover {
    color: var(--secondary-color);
  }
}

.icon-eye {
  color: var(--primary-font-color);

  &:before {
    content: '\e971';
  }
}

.icon-eye--hover {
  @extend .icon-eye;

  &:hover {
    color: var(--color-success);
  }
}

.icon-eye-cross {
  color: var(--primary-font-color);

  &:before {
    content: '\e970';
  }
}

.icon-eye-cross--hover {
  @extend .icon-eye-cross;

  &:hover {
    color: var(--color-success);
  }
}
