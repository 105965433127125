@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.default_settings_item {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  place-items: center left;
  min-height: 36px;

  label {
    display: flex;
    align-items: center;
    gap: 4px;
    @include font-body-medium-regular;
    color: var(--primary-font-color);
  }
}

.setting_dropdown {
  @extend .default_settings_item;

  > div > button {
    min-width: 150px;
    max-width: 250px;
    width: max-content;
    padding: 6px 12px !important;
    min-height: 36px;

    > p {
      @include font-body-small-regular;
    }
  }

  ul {
    min-width: 150px;
  }

  .description {
    grid-column: 1 / 3;
    padding: 12px;

    > p {
      @include font-body-medium-regular;

      > span {
        @include font-body-medium-medium;
      }
    }
  }
}

.setting_slider {
  @extend .default_settings_item;

  > svg {
    width: 24px;
    height: 24px;
  }
}

.selection_list {
  @extend .default_settings_item;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  > label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        @include font-body-small-regular;
        color: var(--secondary-font-color);
      }
    }
  }
}

.settings_volume {
  @extend .default_settings_item;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;

    > p {
      @include font-body-medium-regular;
      color: var(--secondary-font-color);
    }

    > div {
      width: 116px;
      height: 28px;
      padding: 0 8px;
      border-radius: 4px;

      &:hover, &:focus-within {
        background: var(--secondary-background);
      }

      .thumb {

        &:hover {
          box-shadow: 0 0 0 5px rgba(var(--primary-color-rgb), 0.16);
        }

      }

      .active {
        box-shadow: 0 0 0 8px rgba(var(--primary-color-rgb), 0.16) !important;
      }
    }
  }
}

.info_item {
  position: relative;

  > mark {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--primary-background);
    background: var(--notification-color);
    border-radius: 50%;
  }

  > div {
    @include font-body-small-regular;
    display: none;
    position: absolute;
    bottom: 24px;
    left: -10px;
    max-width: 240px;
    padding: 8px;
    background: var(--font-color-black);
    border-radius: 4px;
    box-shadow: 0 5px 13px 0 rgba(51, 41, 37, 0.08);
    z-index: 10;
    border: 1px solid var(--border-color);
    opacity: 0;
    transition: opacity 0.2s;

    > p {
      display: flex;
      max-width: 100%;
      width: max-content;
      color: var(--font-color-white)
    }

    &:after,
    &:before {
      top: 100%;
      left: 15px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-color: transparent;
      border-top-color: var(--font-color-black);
      border-width: 6px;
      margin-left: -4px;
    }
  }

  > div[data-keypad='true'] {
      bottom: 24px;
      left: unset;
      right: -32px;
      max-width: 200px;
      border-color: var(--font-color-white);

      &:after,
      &:before {
        left: unset;
        right: 32px;
      }

      &:before {
          border-top-color: var(--font-color-white);
      }
  }

  &:hover, &:focus {
    > div {
      display: flex;
      flex-wrap: wrap;
      opacity: 1;
    }
  }
}
