@use "src/sass/abstracts/styled-fonts" as *;

.chat-container {
  position: relative;
  display: flex;
  align-items: center;

  > div {
    &:first-of-type {
      height: 100%;
      width: 100%;
    }

    ~ div:not(.chat-image-preview) {
      flex: 1;
      max-width: 400px;
      padding-left: 5px;
      border-left: solid 1px var(--border-color);
    }
  }

  .chat__message-details {
    overflow: hidden auto;
  }

  .chat-image-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    user-select: none;

    .background {
      height: 100%;
      width: 100%;
      cursor: pointer;
      backdrop-filter: blur(4px);
      animation: fade-out 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      user-select: none;

      &.show {
        animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }
    }

    .image-modal {
      position: absolute;
      left: 50%;
      top: 50%;
      background: var(--primary-font-color) fff;
      border-radius: 4px;
      padding: 10px;
      transform: translate(-50%, -50%);
      animation: fade-out 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      width: max-content;
      max-width: 90%;

      iframe,
      img {
        max-width: 100%;
        max-height: 800px;
        border-radius: 4px;
      }

      iframe {
        width: 100vw;
      }

      &.show {
        animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }
    }
  }
}

.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;

  .chat__header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid var(--border-color);
    background: var(--primary-background);
    height: 70px;

    .chat__header-details {
      flex: 1;
      display: flex;
      align-items: center;
      z-index: 1;
      height: 100%;
      gap: 8px;

      .chat__header-name {
        display: flex;
        align-items: center;
        width: 100%;
        height: 80%;

        > div {
          h1,
          input {
            @include font-body-large-medium;
            color: var(--primary-font-color);

            &:disabled {
              cursor: pointer;
            }
          }

          h1 {
            cursor: default;

            > span {
              @include font-body-medium-medium;
              color: var(--secondary-font-color);
            }
          }

          input {
            width: 100%;
          }
        }

        p {
          @include font-body-medium-medium;
          color: var(--secondary-font-color);
          white-space: nowrap;
          text-overflow: ellipsis;
          animation: status-fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) alternate both;
          cursor: default;
          opacity: 0.5;
          line-height: 20px;
        }

        @keyframes status-fade-in {
          from {
            opacity: 0
          }
          to {
            opacity: 0.5
          }
        }

        > div > div p {
          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: block;
          }
        }
      }

      .avatar {
        border: none;

        img {
          max-width: 30px;
          border-radius: 4px;
        }
      }

      .notification-bubble {
        margin-right: 20px;
      }
    }

    .chat__header-options {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-left: auto;

      .chat__header-group-call {
        position: relative;

        > div {
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 100;
          width: max-content;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          padding: 4px 8px;
          background: var(--primary-background);
          border-radius: 6px;
          border: 1px solid var(--border-color);

          > button {
            width: 28px;
            height: 28px;

            span, svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  &.no-chat {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div {
      font-size: 16px;
      color: var(--primary-font-color);
      text-align: center;

      img {
        width: 200px;
        height: 70px;
        margin: 0 auto 50px;
      }

      h1 {
        font-weight: 500;
        font-size: 28px;
        max-width: 500px;
        padding: 0 20px;
        margin-bottom: 20px;
      }

      p {
        color: var(--primary-font-color);
        margin-bottom: 5px;
      }
    }
  }

  .chat__loading-spinner {
    position: absolute;
    top: 60px;
    left: calc(50% - 15px);
    padding: 8px;
    background: var(--primary-font-color);
    border-radius: 25px;
    z-index: 10;
    box-shadow: 0 5px 13px 0 rgba(51, 41, 37, 0.08);

    &.thread {
      top: 100px;
    }
  }

  .chat__status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 43px;
    padding: 5px 10px;
    border-bottom: 1px solid var(--border-color);
    z-index: 15;
    overflow-y: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > p {
      color: var(--tertiary-color);
      font-size: 11px;
      margin-right: 16px;
    }

    > div:nth-of-type(2) .participant__contact-card {
      display: flex;
    }

    .participant {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 5px;
      white-space: nowrap;

      > div:not(.participant__contact-card) {
        width: 8px;
        height: 8px;
        margin-top: -3px;
        margin-right: 5px;
        border-radius: 50%;

        &.online {
          background: var(--color-success);
        }

        &.away {
          background: var(--secondary-background-color);
        }
      }

      > p {
        font-size: 11px;
        color: var(--secondary-font-color);
        cursor: pointer;
      }

      .participant__contact-card {
        position: absolute;
        display: none;
        align-items: center;
        top: 20px;
        left: 0;
        padding: 10px;
        background: var(--primary-font-color);
        border-radius: 4px;
        border: 1px solid var(--border-color);
        box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.16);

        &:after,
        &:before {
          bottom: 100%;
          left: 20px;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: var(--primary-font-color) fff;
          border-width: 10px;
          margin-left: -10px;
        }

        &:before {
          border-color: rgba(248, 239, 237, 0);
          border-bottom-color: var(--border-color);
          border-width: 12px;
          margin-left: -12px;
        }

        p {
          font-size: 12px;
          margin-right: 20px;
        }

        i {
          margin-right: 10px;
          cursor: pointer;
        }

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .chat__welcome {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .chat__welcome-gif,
    .chat__welcome-text {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.2;

      p {
        color: var(--primary-font-color);
      }

      svg {
        path, circle {
          fill-opacity: 1;
          fill: var(--primary-font-color);
        }
      }
    }

    .chat__welcome-gif > img {
      max-width: 100%;
    }

    .chat__welcome-text {
      padding: 0 20px;
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;

      > img {
        width: 100%;
        max-width: 280px;
      }

      > p {
        color: var(--primary-font-color);
        font-size: 78px;
        user-select: none;
      }
    }

    @include small-screens {
      .chat__welcome-text {
        p {
          font-size: 80px;
        }
      }
    }

    @include tablet {
      .chat__welcome-text {
        display: flex;
        justify-content: flex-start;

        p {
          display: none;
        }
      }
    }
  }

  .chat__view {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;

    .chat__view-top-divider {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 50;
      padding: 4px 8px;
      border-radius: 4px;
      background: var(--primary-background);
      border: 1px solid var(--border-color);
      text-transform: capitalize;
    }

    &::before {
      @include list-fade();
      top: 0;
      bottom: revert;
      transform: rotate(180deg);
      z-index: 2;
      opacity: 0.5;
    }

    &[data-is-compact="true"] {
      .chat__view-message-container {
        padding: 0 0 24px 0;

        .chat__date-divider {
          margin: 0 0 -8px 8px;
        }
      }

      .chat__unread-count {
        //margin: 0 15px -7.5px;
        height: 15px;
        //z-index: 2;
        //pointer-events: none;
      }

      .chat__date-divider,
      .chat-message__content, > div {
        background: transparent;
      }

      .chat-message__reactions {
        margin: 0;
      }
    }

    &[data-is-compact="false"] {
      .chat__date-divider {
        hr {
          opacity: 0;
        }
      }
    }

    .chat__view-message-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 2px);
      max-width: calc(100% - 2px);
      padding: 24px 16px;
      min-width: 240px;
      overflow: clip overlay;
      margin: 0 -2px -2px 0;

      &:focus-visible {
        border-radius: 4px;
      }

      &[data-is-thread='true'] {
        padding-top: 48px;
      }

      .chat-message__start-of-chat {
        margin-top: auto;
        margin-bottom: 20px;
        text-align: center;
        color: var(--secondary-font-color);
      }
    }

    .chat__coming-soon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 30px 50px;
      background: url('../../img/backgrounds/browser-bg.jpg') no-repeat center / cover fixed;

      > div {
        font-size: 16px;
        color: var(--primary-font-color);
        text-align: center;

        img {
          width: 170px;
          height: 70px;
          margin: 0 auto 50px;
        }

        h1 {
          font-weight: 500;
          font-size: 32px;
          margin-bottom: 20px;
        }

        p {
          color: var(--primary-font-color);
          margin-bottom: 5px;
        }
      }
    }

    .chat__date-divider {
      width: 100%;
      padding: 9px 10px 8px;
      margin: 20px auto 0 8px;
      color: var(--primary-font-color);
      font-size: 16px;
      border-radius: 4px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 20px;

      hr {
        background: var(--border-color);
        width: 100%;
        flex: 1 1;
        height: 1px;
      }

      &[data-is-first='true'] {
        margin-top: 0;
      }
    }

    .chat__unread-count {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      place-items: center;
      margin: 0 15px 12px;
      width: 100%;

      hr {
        width: 100%;
        height: 1px;
        background: var(--color-danger);
      }

      > button {
        padding: 4px 8px;
        border-radius: 4px;
        background: var(--primary-background);

        &:hover, &:focus {
          background: var(--secondary-background);
        }

        p {
          @include font-body-small-regular;
          color: var(--color-danger);
          cursor: pointer;
        }
      }
    }

    .chat-original-message {
      color: var(--secondary-font-color);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      margin-bottom: 10px;
      background: var(--secondary-background);
      width: inherit;
      font-size: 14px;
      line-height: 1.1;
      z-index: 10;

      > i {
        font-size: 14px;
        color: var(--primary-font-color);
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: var(--secondary-color);
        }
      }

      button {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        margin-right: 4px;
        background: transparent;
        transition: .2s;

        path {
          transition: .2s;
          fill: var(--primary-font-color);;
        }

        &:hover, &:focus {
          background: var(--primary-background);

          path {
            fill: var(--color-danger);
          }
        }
      }

      &:hover p, &:focus-within p {
        white-space: normal;
      }

      > p {
        @include font-body-medium-regular;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--primary-font-color);

        strong {
          font-weight: 500;
        }
      }

      > div:not(.message-date) {
        display: flex;
        flex: 100%;
        overflow: hidden;

        > .image-preview {
          display: flex;
          min-width: 150px;
          margin: 10px 0 0 24px;

          > img {
            max-width: 150px;
            max-height: 100px;
            margin-right: 10px;

            &.image-preview {
              min-width: initial;
              margin: 0 10px 0 0;
            }

            &:nth-child(n + 2) {
              margin-left: 10px;
            }
          }

          > div p {
            font-size: 12px;
            color: var(--primary-font-color);

            &:last-of-type {
              font-size: 10px;
              margin-top: 5px;
            }
          }

          ~ .image-preview {
            margin-left: 10px;
          }
        }
      }

      .message-date {
        @include font-body-small-regular;
        padding-left: 10px;
        margin-left: auto;
        white-space: nowrap;
        margin-top: 2px;
      }
    }

    .chat__view-messages-after-loader {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      pointer-events: none;

      .lottie-player {
        position: relative;
        top: 48px;

        rect {
          fill: transparent;
        }
      }
    }
  }

  .chat__incoming-message {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    margin-top: -30px;
    background: var(--tertiary-color);
    z-index: 10;

    p,
    > div {
      font-size: 11px;
      color: var(--primary-font-color);
    }

    > div {
      &:first-of-type {
        animation: dot-pulse 1.5s linear infinite both;
        margin-left: 1px;
      }

      &:nth-of-type(2) {
        animation: dot-pulse 1.5s linear 0.5s infinite both;
        margin: 0 1px;
      }

      &:last-of-type {
        animation: dot-pulse 1.5s linear 1s infinite both;
      }
    }
  }

  .chat__sending-info {
    @extend .chat__incoming-message;
    background: var(--secondary-color);
  }

  .chat__message-reply {
    position: absolute;
    bottom: calc(100% + 1px);
    left: 0;
    display: flex;
    width: 100%;
    min-height: 65px;
    padding: 15px 10px;
    background: var(--secondary-background);
    z-index: 10;

    p {
      font-size: 14px;
      color: var(--primary-font-color);
    }

    i {
      font-size: 14px;
      cursor: pointer;
    }

    .icon-cross--hover {
      color: var(--primary-font-color);
      margin-right: 30px;

      &:hover {
        color: var(--color-danger);
      }
    }

    .icon-reply-private {
      color: var(--color-success);
      margin-right: 10px;
    }

    .quoted-message-container {
      max-width: calc(100% - 85px);

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        > div {
          display: flex;
          align-items: center;
        }

        p {
          font-weight: 500;
        }

        .divider {
          color: var(--primary-font-color);
          height: 15px;
          margin: -3px 10px 0;
        }

        .quoted-message-time {
          font-family: var(--font-family), sans-serif;
          font-size: 10px;
          margin-left: 10px;
        }
      }

      > p {
        overflow: hidden visible;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        padding-bottom: 10px;
      }

      .image-preview {
        margin-right: 10px;
      }

      .filesDisplayed {
        overflow-x: scroll;

        i {
          font-size: 38px;
          margin-right: 10px;
        }
      }
    }
  }

  .chat__scroll-to-bottom {
    position: absolute;
    top: -100px;
    left: calc(50% - 28px);
    border-radius: 50%;
    background: var(--secondary-color);
    cursor: pointer;
    z-index: 2;

    &:hover {
      background: var(--secondary-color-dark);
    }

    .MuiFab-label {
      transform: rotate(-90deg);

      i {
        color: var(--font-color-white);
      }
    }
  }

  .chat__input {
    position: relative;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid var(--border-color);
    display: grid;
    grid-template-columns: 128px 1fr 62px;

    > div > button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      transition: 0.1s;

      &:hover, &:focus, &:focus-within {
        background: var(--secondary-background);
      }

      span {
        height: 28px;
        width: 28px;
      }

      img {
        margin-right: 0 !important;
      }
    }

    .chat__input-options {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 8px;
      gap: 8px;

      > button {
        &:hover {
          background: var(--secondary-background);
        }
      }

      > .paperclip,
      > button > img {
        position: relative;
        cursor: pointer;
        transition: .2s;

        &.is-integration {
          margin: 0 15px;
        }

        svg path {
          transition: .2s;
        }
      }

      > .paperclip:first-of-type {
        cursor: pointer;

        &:hover, &:focus {
          svg path {
            fill: var(--primary-color);
          }
        }
      }

      > .record_button {
        &[data-is-recording='true'] {
          span {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              content: '';
              width: 14px;
              height: 14px;
              position: absolute;
              border-radius: 50%;
              animation: recording_circle_outline_pulse infinite 2s ease-in-out;
              left: 7px;
              bottom: 8px;
            }

            svg {
              path {
                animation: recording_circle_pulse infinite 2s ease-in-out;
              }
            }
          }
        }

        @keyframes recording_circle_pulse {
          0% {
            fill: #1C1C1E;
          }
          50% {
            fill: #ff0030;
          }
          100% {
            fill: #1C1C1E;
          }
        }
        @-webkit-keyframes recording_circle_outline_pulse {
          0% {
            -webkit-box-shadow: 0 0 0 0 rgba(#ff0030, 0.4);
          }
          70% {
            -webkit-box-shadow: 0 0 0 10px rgba(#ff0030, 0);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(#ff0030, 0);
          }
        }
        @keyframes recording_circle_outline_pulse {
          0% {
            -moz-box-shadow: 0 0 0 0 rgba(#ff0030, 0.4);
            box-shadow: 0 0 0 0 rgba(#ff0030, 0.4);
          }
          70% {
            -moz-box-shadow: 0 0 0 10px rgba(#ff0030, 0);
            box-shadow: 0 0 0 10px rgba(#ff0030, 0);
          }
          100% {
            -moz-box-shadow: 0 0 0 0 rgba(#ff0030, 0);
            box-shadow: 0 0 0 0 rgba(#ff0030, 0);
          }
        }
      }

      > button > img {
        width: 20px;
      }

      .emoji-menu {
        bottom: 50px;
        left: 17px;
      }

      .gif-icon {
        color: var(--secondary-color);
        font-weight: 500;
        border: 2px solid var(--secondary-color);
        border-radius: 4px;
        font-size: 8px;
        padding: 5px 5px 3px;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .chat__inline-emoji-picker {
      position: absolute;
      bottom: 60px;
      left: 75px;
      background: var(--primary-font-color);
      min-width: 100px;
      border: 1px solid var(--tertiary-color);
      border-radius: 3px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      z-index: 30;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 10px;
        transform: translateY(10px);
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::before {
        bottom: -6px;
        border-color: var(--tertiary-color) transparent transparent transparent;
        border-width: 8px;
      }

      &::after {
        bottom: -5px;
        border-color: var(--primary-font-color) transparent transparent transparent;
        border-width: 8px;
      }

      ul {
        padding: 8px 5px 5px;

        li {
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 4px;
          border-radius: 3px;
          cursor: pointer;

          &:focus,
          &:hover,
          &.active {
            // background: var(--primary-background-color);
            background: var(--secondary-color);

            p {
              color: var(--primary-font-color);
            }
          }

          & ~ li {
            margin-top: 2px;
          }

          p {
            &:first-of-type {
              margin-bottom: -2px;
            }

            &:last-of-type {
              font-size: 12px;
              margin-left: 3px;
            }
          }
        }
      }
    }

    .chat__input-textbox {
      background: var(--secondary-background);
      border-radius: 30px;
      padding: 0 15px;
      color: var(--primary-font-color);
      font-size: 16px;
      flex: 1;
      max-height: 50vh;
      overflow: hidden auto;
      border: 1px solid var(--secondary-background);
      position: relative;

      &:focus-within {
        border: 1px solid var(--focus-border-color);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .text-area-container {
        display: flex;
        align-items: center;
        transition: .2s;
        width: 100%;
        position: relative;

        textarea {
          resize: none;
          font-size: 16px;
          width: 100%;
          height: 20px;
          margin: 10px 0 5px;
          padding-top: 2px;
          background: transparent;
          transform: translateY(-2px);
          -webkit-text-fill-color: transparent;

          &::placeholder {
            color: var(--secondary-font-color);
          }

          &:focus::placeholder {
            color: transparent;
          }

          &:focus-visible {
            outline: none;
          }
        }

        .phantom-text-area {
          position: absolute;
          pointer-events: none;
          font-size: 16px;
          max-width: border-box;
          min-height: 20px;
          height: fit-content;
          margin: 10px 0 5px;
          padding-top: 2px;
          background: transparent;
          transform: translateY(-2px);

          p {
            line-break: anywhere;
            display: inline-block;
            position: relative;

            .phantom-text-area-tag {
              height: fit-content;
              line-break: strict;
              white-space: nowrap;

              mark {
                color: var(--primary-color);
              }
            }
          }
        }

        .character-count {
          color: var(--secondary-font-color);
        }
      }

      .chat-pending-files {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        overflow: auto hidden;

        .pending-file {
          display: flex;
          align-items: center;
          margin-right: 10px;
          padding: 5px 0;
          border: 2px solid transparent;
          position: relative;
          width: max-content;
          transition: .6s ease-out;

          > button.audio_preview {
            span {
              position: relative;
              left: 6px;
              top: 2px;

              svg path {
                fill: var(--secondary-color);
              }
            }

            &:hover {
              svg path {
                fill: var(--secondary-color-dark);
              }
            }
          }

          > p {
            font-size: 11px;
          }

          img {
            max-height: 100px;
            max-width: 200px;
          }

          &.error {
            border: 2px solid var(--color-danger);
          }

          .pending-file__remove {
            width: 28px;
            height: 28px;
            border-radius: 6px;

            path {
              fill: var(--color-danger);
            }

            span {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }

    .chat__input-send-plane {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      margin-right: 20px;

      &.disabled svg {
        color: var(--secondary-font-color);
      }

      svg {
        user-select: none;
        cursor: pointer;
        position: relative;

        &:active {
          transform: scale(0.9);
        }

        &:hover {
          path {
            fill: var(--secondary-color);
          }
        }

        path {
          fill: var(--color-danger);
          transition: .1s;
        }
      }
    }
  }

  .chatErrorBoundary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .chat__welcome {
      flex-direction: column;

      img {
        width: 300px;
        margin-bottom: 50px;
      }

      h1 {
        width: 500px;
        line-height: 35px;

        span {
          font-family: var(--font-family), sans-serif;
        }
      }
    }
  }

}

.drag-and-drop {
  > input {
    display: none;
  }

  .target {
    display: none;
    color: green;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    backdrop-filter: blur(4px);
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    pointer-events: none;
    position: absolute;
    z-index: 3;

    .drop-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border: 5px dashed #c9c2c0;
      border-radius: 20px;

      p {
        font-weight: 500;
        color: #b7afad;
        font-size: 36px;
      }
    }
  }

  &.drag-hover .target {
    display: block;
    cursor: copy;
  }
}

.create-chat {
  flex: 1;
  min-width: 360px;
  border-top: solid 1px var(--border-color);

  .create-chat__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .create-chat__confirmation {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > p {
        font-size: 14px;
        color: var(--secondary-font-color);
        margin-bottom: 45px;
      }

      > div {
        cursor: pointer;

        i {
          font-size: 44px;
          display: block;
          width: fit-content;
          margin: 0 auto 10px;

          &:hover {
            color: var(--tertiary-color);
          }
        }

        p {
          font-size: 12px;
          color: var(--secondary-font-color);
        }

        &.disabled i {
          color: var(--secondary-font-color);
        }
      }
    }
  }
}

.small-font .chat {
  .chat__date-divider {
    font-size: 11px;
  }

  .chat__input-textbox {
    font-size: 14px;

    textarea {
      font-size: 14px;
    }
  }
}

.MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
  color: var(--tertiary-color);
  margin: 0 auto;
}
