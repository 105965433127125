@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.draggable_container {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh;
  max-width: revert !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 500;
  position: fixed;
  pointer-events: none;
  transition: .2s;

  .draggable_area {
    left: 0;
    bottom: 0;
    width: calc(100% - 90px);
    height: calc(100% - 90px);
    position: absolute;
    pointer-events: none;
  }

  .keypad_section {
    position: absolute;
    border-radius: 30px;
    transition-timing-function: ease-in;
    transition: .4s;
    bottom: 50px;
    left: 50px;
    width: 250px;
    height: 430px;

    &[data-multiple-calls='true'] {
      height: 475px;
    }

    &[data-widget-active='true'] {
      .move_handler {
        > div {
          border-radius: 20px;

          > div {
            &:first-of-type {
              box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
              bottom: 0;
              height: 100px;
              cursor: pointer;
              opacity: 0.6;

              &:hover {
                height: 110px;
                opacity: 1;
              }

              .call_action {
                bottom: revert;
              }
            }
          }
        }
      }
    }

    .keypad_open_button {
      pointer-events: all;
      box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.15);
      z-index: 15;
      visibility: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -30px;
      bottom: -30px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: .2s;
      cursor: grab;

      > div {
        span {
          background: var(--keypad-toggle-color);
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 60px;
          height: 60px;
          transition: .2s;
          display: flex;
          align-items: center;
          justify-content: center;
          // cursor: pointer;

          svg {
            path {
              fill: white;
            }
          }

          &:hover {
            background: var(--keypad-toggle-color-dark);
          }
        }
      }

      &[data-pulse-type='outbound'] {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
      }

      &[data-pulse-type='initial'] {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse_outbound 2s infinite;
      }

      &[data-pulse-type='active'] {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse_active 2s infinite;
      }

    }

    .move_handler {
      transform: scale(0);
      right: 0;
      transition: .2s ease-out;
      visibility: hidden;
      position: relative;
    }

    .keypad_container {
      background: var(--primary-background);
      border: 1px solid var(--border-color);
      border-radius: 20px;
      height: 430px;
      width: 250px;
      padding: 20px;
      bottom: 0 !important;
      transition: .4s, bottom 0s;
      position: absolute;
      pointer-events: all !important;

      .callInstance {
        color: var(--font-color-white);

        h1, h2, h3, h4, h5, h6 {
          color: var(--font-color-white);
        }
      }

      .call_info {
        display: grid;
        height: 40px;
        box-sizing: border-box;
        padding-bottom: 10px;
        text-shadow: 1px 2px 2px rgb(0 0 0 / 50%);

        > div {
          max-width: 208px;

          > div {
            display: flex;
            max-width: 100%;
          }
        }

        button {
          padding: 0;
          background: transparent;
          height: max-content;

          &:hover, &:focus, &:focus-within {
            > p, > h3 {
              text-decoration: underline !important;
            }
          }
        }


        .firstLine {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 2px;
          color: var(--primary-color);

          span {
            width: 24px;
            height: 24px;

            path {
              fill: white
            }
          }

          h3 {
            @include font-body-large-regular;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 210px;
          }
        }

        .secondLine {
          display: grid;
          grid-template-columns: 1fr max-content;
          gap: 4px;
          width: 208px;
          @include font-body-small-regular;

          button {
            span {
              @include font-body-small-regular;
              color: var(--font-color-white);
              text-decoration: underline;
            }
          }

          p {
            color: var(--font-color-white);
            display: flex;
            align-items: center;

            &:first-of-type {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 100%;
            }
          }
        }
      }

      .group_display {
        height: 42px;
        z-index: 1;
        position: relative;

        .group_display_header {
          @include font-body-medium-regular;
          display: flex;
          align-items: center;
          justify-content: space-between;

          path {
            fill: white;
          }

          > div {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              color: var(--font-color-white);
              max-width: 112px;
            }
          }
        }


        .participant_list {
          margin-top: 12px;
          width: 216px;
          height: 32px;
          position: relative;

          .participants {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            width: 100%;
            z-index: 1000;
            padding: 5px;
            backdrop-filter: blur(6px);

            p {
              @include font-body-medium-medium;
              color: var(--font-color-white);

              display: flex;
              align-items: center;
              gap: 4px;
            }

            > button {
              border-radius: 4px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: -4px;
              padding: 4px;
              height: max-content;

              &:hover, &:focus {
                background: rgba(255, 255, 255, 0.075);
              }

              span, svg {
                height: 18px;
                width: 18px;

                path {
                  fill: var(--font-color-white);
                }
              }
            }

            ul {
              max-height: 240px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 10px 0 4px;
              overflow: hidden scroll;
              width: 100%;

              > li {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > p {
                  opacity: 0.7;
                  transition: opacity 0.1s ease;
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  &[data-speaking='yes'] {
                    opacity: 1;
                  }
                }

                > button {
                  opacity: 0;
                  transition: opacity 0.1s ease;

                  &:hover, &:focus {
                    opacity: 1 !important;
                  }

                  > p {
                    color: var(--color-danger);
                  }
                }

                &:hover {
                  > button {
                    opacity: 0.5;
                  }
                }
              }
            }
          }

        }
      }

      .dtmf_number {
        width: 100%;
        height: 12px;
        box-sizing: border-box;
        position: relative;

        p {
          top: -6px;
          color: var(--font-color-white);
          display: block;
          position: absolute;
          width: 100%;
          text-align: center;

          &:hover {
            color: var(--primary-color);
          }
        }
      }

      .call_participant {
        > p {
          color: var(--primary-font-color);
        }

        button {
          opacity: 1 !important;

          span svg path {
            fill: var(--secondary-color) !important;
          }

          &:hover {
            background: var(--secondary-background) !important;
          }
        }
      }

      &::before {
        opacity: 0;
        pointer-events: none;
        //content: '';
        position: absolute;
        bottom: -300px;
        left: 50%;
        transform: translateX(-50%);
        height: 500px;
        width: 400px;
        border-radius: 50%;
        transition: .2s;
      }

      .header {
        width: 250px;

        .caller_id {
          position: relative;
          display: grid;
          grid-template-columns: 1fr max-content 1fr;
          place-items: center;
          margin: -8px -16px 20px;
          grid-gap: 4px;
          padding: 0 10px;
          width: 250px;
          max-width: 250px;
          transform: translateX(-5px);
          cursor: grab;
          z-index: 100;
          transition: grid-template-columns 0.5s;

          > p {
            @include font-body-small-medium;
          }

          &[data-sm-need='true'] {

            > div:nth-child(3) > button {
              border: 1px solid var(--color-danger);
            }
          }

          &[data-cid-only='true'] {
              grid-template-columns: 1fr 0 0fr;

              > div{
                  &:nth-child(1) {
                  ul {
                      left: unset !important;
                      right: 0 !important;
                  }
                }
              }
          }

          > div {
            max-width: 100%;
            min-width: 100%;
            width: 100%;

            &:nth-child(1) {
              ul {
                left: -12px !important;
              }
            }

            &:nth-child(3) {
              ul {
                right: 0 !important;
              }
            }

            > button {
              min-width: 0;
              width: 100%;
              gap: 0;
              padding: 4px;
              border: none;
              justify-content: space-between;

              &:hover, &:focus {
                background: none;
              }

              p {
                @include font-body-small-regular;
                color: var(--color-danger);
              }

              path {
                fill: var(--color-danger);
              }
            }
          }

          ul {
            position: absolute !important;
            top: 100% !important;
            bottom: revert !important;
            left: -12px !important;
            max-width: 220px;
            max-height: 360px;
            z-index: 100;
          }
        }

        .search_input {
          @include font-body-large-regular;
          background: none;
          width: 210px;
          text-align: center;
          color: var(--primary-font-color);
          padding: 8px 0;
          border-radius: 4px;
          transition: .3s;
          margin-bottom: 8px;
          //border: 1px solid var(--border-color);

          &::placeholder {
            opacity: 1;
            transition: .2s;
          }

          &:focus {
            border-color: var(--focus-border-color);

            &::placeholder {
              opacity: 0;
            }
          }
        }
      }

      .keypad_content {
        > div {
          opacity: 0;
          transition: .2s;
          visibility: hidden;

          &.contact_list {
            position: absolute;
            top: 115px;
            width: 100%;
            left: 0;
            color: var(--primary-font-color);
            height: 50px;
            display: block;
            z-index: 1;

            > ul {
              background: white;

              > div {
                height: 100%;
                padding-bottom: 20px;

                > div > div {
                  > p {
                    color: var(--primary-font-color) !important;
                  }

                  > div > div > div {
                    p {
                      display: block;
                      text-align: left;
                      max-width: 150px;
                      width: 150px;
                    }

                    &:hover, &:focus, &:focus-within {
                      p {
                        max-width: 128px;
                      }
                    }
                  }
                }
              }
            }

            &[data-is-invitation-list='true'] {
              > ul {
                position: relative;
                top: -6px;
                height: 250px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 20px;
                width: fit-content;
                margin-left: -1px;
                border: 1px solid var(--border-color);
                box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

                > div {
                  width: 210px;

                  > div > div > div {
                    width: 210px !important;

                    > div > div > div > div {
                      p {
                        width: 100px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.active_tab {
            display: flex;
            flex-direction: column;
            justify-content: center;
            visibility: visible;
            opacity: 1;

            .callCreationControls {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              padding: 8px 24px 0;
              position: relative;
              place-items: center;

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 46px;
                height: 46px;
                border-radius: 50%;

                > span {
                  font-weight: 500;
                  font-size: 8px;
                  line-height: 9px;
                  letter-spacing: 0.04em;
                  color: var(--secondary-font-color);
                }
              }
            }
          }

          &.group_call {
            .participants_list {
              height: 255px;
              margin: 0 -20px 10px;
            }

            > div > button {
              width: 100%;
              padding: 6px;
              border-radius: 6px;
              color: var(--font-color-white);
              transition: .2s;
              background: var(--color-success);

              &:hover {
                background: var(--color-success-dark);
                color: var(--font-color-white);
              }
            }

            button:disabled {
              cursor: not-allowed;
              color: var(--font-color-white);
              background: var(--color-disabled);

              &:hover {
                color: var(--font-color-white);
                background: var(--color-disabled);
              }

            }
          }

          .call_participant {
            display: flex;
            align-items: center;
            transition: .2s;
            padding: 10px 16px 10px 10px;
            border-bottom: 1px solid var(--border-color);
            border-radius: 0;

            > p {
              color: var(--font-color-white);
              max-width: 130px;
              overflow: clip;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            > button {
              margin-left: auto;
              opacity: 0;
              transition: .2s;
              border-radius: 4px;
              height: 25px;
              width: 25px;

              span {
                position: relative;
                bottom: 1px;

                svg {
                  width: 20px;

                  path {
                    fill: white;
                  }
                }
              }

              &:hover {
                background: rgba(white, 0.2);
              }
            }

            &:hover {
              border-color: transparent;
              background: rgba(white, 0.2);
              border-radius: 8px;

              > button {
                opacity: 1;
              }
            }
          }
        }
      }

      > * {
        position: relative;
        z-index: 2;
      }

      .page_heading {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        place-items: center;
        margin-bottom: 4px;

        > p {
          color: var(--font-color-white);
        }

        > button {
          place-self: center left;
          background: none;
          padding: 2px 4px;
          gap: 0;

          &:hover, &:focus {
            background: rgba(255, 255, 255, 0.1);
          }

          > p {
            color: var(--font-color-white);
          }

          span, svg {
            width: 18px;
            height: 18px;

            path {
              fill: var(--font-color-white);
            }
          }
        }
      }

      > div > div > div > div > div {

        &.options_menu {
          margin: 10px 0 0;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 12px;
          position: relative;
          transition: opacity 0.2s;

          &[data-hidden='true'] {
            opacity: 0.6;
          }

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &.listed {
              position: relative;

              > ul {
                position: absolute;
                bottom: 0;
                max-height: 240px;
              }
            }

            > button {
              > span {
                svg {
                  padding: 8px;
                  height: 45px;
                  width: 45px;
                  background: rgba(white, 0.15);
                  border-radius: 50%;
                  transition: .2s;

                  path {
                    fill: white;
                    transition: .2s;
                  }
                }
              }

              &:hover {
                span {
                  svg {
                    background: rgba(white, 0.3);
                  }
                }
              }

              &.active_button {
                span {
                  svg {
                    background: white;

                    path {
                      fill: black;
                    }
                  }
                }
              }
            }

            > p {
              text-shadow: none;
              transition: .2s;
              margin-top: 5px;
              color: var(--font-color-white);
              font-weight: 400;
              text-align: center;
              font-size: 12px;
            }
          }

          .transferring_display {
            margin-top: 8px;
            grid-column: 1 / 4;

            p {
              font-size: 14px;
            }
          }

          .group_error {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -64px;
            color: var(--font-color-white);
            text-align: center;
          }
        }

        &.number_keypad {
          padding: 0 20px;
          margin: 3px 0 0;

          .shortcodes_btn {

            p, span, h3 {
              color: var(--font-color-white);
            }

            &:hover {
              p, span, h3 {
                color: var(--primary-color);
              }
            }
          }

          .dial {
            button {
              border-color: var(--font-color-white);

              p, span {
                color: var(--font-color-white);
              }

              span {
                opacity: 0.75;
              }
            }
          }
        }

        &.switch_calls {
          height: 100%;

          > div {
            border-top: 1px solid white;
            margin-top: 20px;
            width: 110%;
            left: -5%;
            position: relative;
            max-height: 235px;

            > button {
              position: relative;
              border-bottom: 1px dashed white;
              transition: .2s;
              opacity: .5;
              width: 100%;
              padding: 4px 5% 4px;
              background: rgba(255, 255, 255, 0.2);

              &:last-of-type {
                border-bottom: none;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }

              &:hover {
                background: rgba(255, 255, 255, 0.3);
                opacity: 1;
              }
            }
          }
        }

        &.shortcodes {
          height: 230px;
          margin-bottom: 40px;
          border-bottom-left-radius: 5px;

          > div:last-of-type {
            padding: 4px;
            height: 100%;
            overflow: hidden auto;

            > button {
              width: 100%;
              padding: 4px 8px;
              border-radius: 4px;

              &:hover, &:focus {
                background: rgba(255, 255, 255, 0.1);
              }

              > div {
                display: flex;
                align-items: center;
                gap: 4px;

                > h2 {
                  @include font-body-large-regular;
                  color: var(--font-color-white);
                }

                > div {
                  flex: 1;
                  justify-content: flex-end;
                  display: flex;
                  flex-direction: column;

                  p {
                    text-align: right;
                  }

                  > p {
                    @include font-body-medium-regular;
                    color: var(--primary-color);
                  }

                  > div {
                    > p {
                      @include font-body-small-regular;
                      color: var(--font-color-white);
                    }
                  }
                }
              }
            }
          }
        }

        &.add_call {
          height: 250px;
          margin-bottom: 40px;
          border-bottom-left-radius: 5px;
          position: relative;

          input[type='text'] {
            background: rgba(254, 254, 254, 0.1);
            width: 100%;
            padding: 10px 15px;
            color: var(--font-color-white);
            font-size: 14px;
            border-radius: 10px;
            margin-bottom: 6px;

            &::placeholder {
              color: var(--font-color-white);
              transition: .2s;
            }

            &:focus {
              &::placeholder {
                opacity: 0;
              }
            }
          }

          .add_call_button {
            position: absolute;
            right: 0;
            width: 28px;
            height: 28px;
            z-index: 100;
            transition: .2s;
            margin: 5px 5px 0 0;
            border-radius: 6px;

            i {
              color: var(--secondary-color);
            }

            svg path {
              fill: var(--color-success);
            }

            &:hover {
              background: var(--secondary-background);

              svg path {
                fill: var(--color-success-dark);
              }
            }
          }

          .number_invite_btn {
              position: absolute;
              top: 86px;
              left: unset;
              right: 4px;
              background: none;
          }

          .group_call_btn {
            position: relative;
            top: 12px;
            left: 50%;
            transform: translateX(-50%);
            color: var(--secondary-color);
            border-radius: 4px;
            transition: 0.2s;
            padding: 4px 8px;
            border: 1px solid var(--secondary-color);

            &:hover, &:focus {
              background: var(--secondary-color);
              color: var(--font-color-white);
            }
          }
        }

        &.transfer {
          input[type='text'] {
            background: rgba(254, 254, 254, 0.1);
            width: 100%;
            padding: 10px 15px;
            color: var(--font-color-white);
            font-size: 14px;
            border-radius: 10px;
            margin-bottom: 6px;

            &::placeholder {
              color: var(--font-color-white);
              transition: .2s;
            }

            &:focus {
              &::placeholder {
                opacity: 0;
              }
            }
          }

          > div:first-of-type {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 6px;

            p {
              color: var(--font-color-white);
              font-size: 16px;
            }

            button {
              margin-left: auto;
            }
          }

          > div:last-of-type {
            position: static;
            height: 210px;

            > div:last-of-type {
              margin: 0;
              position: static;
              height: 100%;

              > div {
                padding: 4px;
                width: 210px;

                &:hover, &:focus, &:focus-within {
                  background: rgba(0, 0, 0, 0.2);

                  > div > div p {
                    max-width: 124px;
                  }
                }

                > div:last-of-type {
                  right: 2px;
                }

                > div > button, > div > div > button {
                  &:hover, &:focus {
                    background: var(--primary-background);
                  }
                }

                > div > div p {
                  max-width: 100px;
                  width: 100px;
                  display: block;
                  color: var(--font-color-white);
                }
              }
            }
          }

          .transfer_call_button {
            position: absolute;
            right: 10px;
            width: 28px;
            height: 28px;
            z-index: 100;
            transition: .2s;
            margin: 5px 5px 0 0;
            border-radius: 6px;

            svg path {
              fill: var(--secondary-color);
            }

            &:hover {
              background: var(--secondary-background);

              svg path {
                fill: var(--secondary-color-dark);
              }
            }
          }
        }

        &.add_to_call {
          > div {
            > div {
              &:first-of-type {
                height: 17px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                  color: var(--font-color-white);
                }
              }

              &:nth-of-type(2) {
                > div {
                  margin-top: 10px;

                  &.participants_add {
                    position: relative;
                    z-index: 5;

                    > input[type='text'] {
                      background: none;
                      width: 100%;
                      border: 1px solid rgba(white, .4);
                      padding: 7px;
                      border-radius: 4px;
                      transition: .2s;

                      &::placeholder {
                        color: rgba(white, .4);
                        transition: .2s;
                      }

                      color: var(--font-color-white);

                      &:focus, &[data-is-active='true'] {
                        border-color: rgba(white, 0.6);
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;

                        &::placeholder {
                          color: var(--font-color-white);
                        }
                      }
                    }

                    .add_to_call_options {
                      position: absolute;
                      width: 100%;
                      max-height: 250px;
                      height: fit-content;
                      border-left: 1px solid rgba(white, 0.6);
                      border-bottom: 1px solid rgba(white, 0.6);
                      border-right: 1px solid rgba(white, 0.6);
                      border-bottom-left-radius: 8px;
                      border-bottom-right-radius: 8px;
                      background: rgba(0, 0, 0, 0.6);
                      backdrop-filter: blur(8px);

                      > div {
                        max-height: revert;
                      }

                      > div > div > div > div > div > div > div {
                        padding: 4px;
                        width: 200px;
                        border-radius: 0;
                        border: none;
                        transition: .2s;

                        > div > div p {
                          color: var(--font-color-white);
                        }

                        &:hover, &:focus, &:focus-within {
                          background: rgba(0, 0, 0, 0.4);

                          > div > div p {
                            max-width: 124px;
                          }
                        }

                        > div:last-of-type {
                          right: 2px;

                          > button {
                            path, circle {
                              fill: var(--secondary-background);
                            }

                            &:hover, &:focus {
                              path, circle {
                                fill: var(--primary-font-color);
                              }
                            }
                          }
                        }

                        > div > button, > div > div > button {
                          &:hover, &:focus {
                            background: rgba(white, 0.2);
                          }
                        }

                        > div > div p {
                          max-width: 100px;
                          width: 100px;
                          display: block;
                        }
                      }
                    }
                  }

                  &.participants_list {
                    height: 240px;
                    margin-bottom: 10px;
                    //overflow: overlay !important;

                    .call_participant {
                      p {
                        color: var(--font-color-white);
                      }
                    }
                  }
                }

                > button {
                  background: var(--secondary-background);
                  width: 100%;
                  border-radius: 8px;
                  padding: 6px;
                  color: var(--primary-font-color);
                  font-size: 14px;
                  transition: .2s;

                  &:hover {
                    background: var(--secondary-background);
                  }
                }
              }
            }
          }
        }

        &.incomingOrOutgoingCall {
          height: 430px;

          > div {
            padding-top: 30px;

            > div {
              //background image
              position: relative;
              bottom: 20px;
              margin: -20px;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              height: 430px;

              h3 {
                font-size: 18px;
                font-weight: 500;
                text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
              }

              p {
                font-size: 12px;
                font-weight: 400;
                text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }

        .call_participant {
          display: flex;
          align-items: center;
          transition: .2s;
          padding: 6px;
          border-radius: 8px;

          > p {
            max-width: 130px;
            overflow: clip;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          > button {
            margin-left: auto;
            opacity: 0;
            transition: .2s;
            border-radius: 4px;
            height: 25px;
            width: 25px;

            span {
              position: relative;
              bottom: 1px;

              svg {
                width: 20px;

                path {
                  fill: var(--color-danger) !important;
                }
              }
            }

            &:hover {
              background: rgba(white, 0.2);
            }
          }

          &:hover {
            background: rgba(white, 0.2);

            > button {
              opacity: 1;
            }
          }
        }

        > div > div > * {
          ::-webkit-scrollbar {
            width: 10px;
            cursor: pointer !important;
          }

          ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 10000px;
            background: rgba(254, 254, 254, 0.4);
          }
        }
      }

      .number_keypad {
        width: 100%;
        position: relative;
        padding: 0 !important;

        > div {
          width: 100%;

          &:first-of-type:not(:last-of-type) {
            margin-bottom: 5px;

            .shortcodes_btn:first-of-type {
              justify-self: center;
              color: var(--secondary-color);
              border-radius: 4px;
              transition: .2s;
              padding: 4px 8px;
              border: 1px solid var(--secondary-color);

              &:hover {
                background: var(--secondary-color);
                color: var(--font-color-white);
              }
            }
          }

          &.dial {
            padding: 0 20px;
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 60px 60px 60px 60px 60px;
            place-items: center;

            button {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 46px;
              height: 46px;
              border-radius: 50%;
              border: 1px solid var(--border-color);

              p {
                color: var(--primary-font-color);
                font-weight: 500;
                font-size: 24px;
                line-height: 100%;
                margin-top: 4px;
              }

              > span {
                font-weight: 500;
                font-size: 8px;
                line-height: 9px;
                letter-spacing: 0.04em;
                color: var(--secondary-font-color);
              }

              &[data-center='true'] {
                justify-content: center;

                p {
                  margin: 0;
                }
              }

              &[data-backspace='true'] {
                border: none;
                justify-content: center;

                span {
                  margin-top: 2px;
                  width: 28px;
                  height: 28px;
                }
              }

              &.answerButton {
                border: none;

                &[data-disable-f-d='true'] {
                  filter: grayscale(1);
                }
              }

              &:hover {
                background: var(--tertiary-background);
              }
            }
          }
        }
      }

      > div {
        position: relative;
        height: 100%;
        width: 100%;

        @keyframes slide-in {
          from {
            left: -250px;
          }
          to {
            left: 0;
          }
        }

        > div:not(:first-of-type) {
          position: absolute;
          height: 350px;
          left: 0;
          top: 0;
          transition: .4s;
          width: inherit;

          &[data-handle-call-changing='true'] {
            opacity: 0 !important;
          }

          > button {
            pointer-events: auto;
            position: absolute;
            top: 36px;
            left: 50%;
            padding: 2px 8px;
            transform: translateX(-50%);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover, &:focus {
              background: rgba(255, 255, 255, 0.25);

              p {
                text-decoration: underline;
              }
            }

            p {
              color: var(--font-color-white);
              max-width: 180px;
              line-height: 20px;
            }
          }

          &.not_active_call {
            content-visibility: visible;
            bottom: 0;
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &[data-is-dad-active='true'] {
        pointer-events: none;

        > div > div {
          transition: .2s;
          left: 250px;
        }
      }

      &[data-is-in-call='true'] {
        background: var(--keypad-background);
        visibility: visible;
        transition: .4s;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        backdrop-filter: blur(10px);
        background: radial-gradient(85.6% 103.6% at 49.98% 162.09%, var(--keypad-background-internal) 5.22%, var(--keypad-background) 92.73%);

        //background: radial-gradient(97.86% 174.88% at 49.98% 189.53%,  5.22%,  92.73%);

        .not_active_call {
          content-visibility: hidden;
          bottom: 30px;
          opacity: 0;
          pointer-events: none;
        }

        &::before {
          opacity: 1;
          pointer-events: none;
          transition: 1.2s;
          bottom: -80px;
        }

        &::after {
          opacity: 1;
          pointer-events: none;
          left: 0;
          bottom: 150px;
        }

        .keypad_main_action {
          pointer-events: none;
          z-index: 10;
          transition: .2s;
        }

        &.internal_call {
          background: var(--keypad-background-internal);
        }
      }

      .call_action {
        pointer-events: all;
        display: flex;
        align-items: center;
        position: absolute;
        padding: 0 10px;
        justify-content: space-evenly;
        gap: 12px;
        bottom: 0;
        left: 10px;
        width: calc(100% + 40px);
        margin: 0 -30px;

        .follow_me_drop {
          position: absolute;
          right: 12px;

          > button {
            min-width: 0;
            padding: 2px 4px;
            gap: 0;
            border: none;
            background: none;
            display: flex;
            flex-direction: row;

            > p {
              padding: 0;
              height: 17.5px;
              min-height: 17.5px;
              @include font-body-small-regular;
              color: var(--font-color-white);
            }

            span, svg {
              width: 14px;
              height: 14px;
            }

            path {
              fill: var(--font-color-white);
            }
          }

          ul {
            position: absolute !important;
            top: revert !important;
            right: revert !important;
            left: -12px !important;
            bottom: 100% !important;
            max-height: 320px;
            background: var(--keypad-background);
            border: 1px solid rgba(255, 255, 255, 0.1);

            p {
              color: var(--font-color-white);
            }

            button {
              //justify-content: flex-start;
              align-items: flex-start;

              &:hover, &:focus {
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          pointer-events: all;

          span {
            width: 50px;
            height: 50px;

            svg {
              path {
                fill: white;
              }
            }
          }

          p {
            font-weight: 400;
            width: max-content;
            font-size: 14px;
            color: var(--font-color-white);
            padding-top: 4px;
          }

          &.transfer_button {
            height: 50px;
            width: 50px;
            border-radius: 50%;

            svg {
              padding: 8px;
              height: 50px;
              width: 50px;
              background: rgba(255, 255, 255, 0.3);
              border-radius: 50%;
              transition: 0.2s;

              path {
                fill: white;
              }

            }

            &:hover, &:focus {
              svg {
                background: rgba(255, 255, 255, 0.6);

                path {
                  fill: black;
                }
              }
            }
          }

          &.hangupButton {
            svg circle {
              fill: var(--color-danger);
            }

            &:hover circle {
              fill: var(--color-danger-dark);
            }
          }

          &.answerButton {
            &:hover svg circle {
              fill: var(--color-success);
            }
          }
        }
      }
    }

    &[data-open='true'] {
      .move_handler {
        right: 0;
        transform: scale(1);
        visibility: visible;
        pointer-events: all;
        position: relative;

        > div {
          height: inherit;
          pointer-events: none;
          position: relative;
          bottom: 0;
          border-radius: 20px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .draggableComponent {
    position: absolute;
    z-index: 500;
    bottom: 0;
  }

  [data-pos='right'] {
    [data-comp='shortcodes_pop'] {
      right: calc(100% - 40px);
      left: unset;
    }

    .move_handler {
      right: 250px !important;
    }
  }

  .groupInvitations {
    width: 224px;

    &[data-open='true'] {
      height: 152px;
    }

    &[data-in-call='true'] {
      p {
        color: var(--font-color-white);
      }

      path {
        fill: var(--font-color-white);
      }
    }

    > div {
      p {
        font-size: 14px;
        font-weight: 700;
      }
    }

    > ul {
      margin: 8px 0 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      max-height: 124px;
      padding-right: 12px;

      > p {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto 0;
        width: 100%;
        height: 100%;
        line-height: 32px;
        font-size: 12px;
        color: var(--secondary-font-color)
      }

      > div {
        display: flex;
        justify-content: space-between;
        gap: 4px;

        > li > p {
          max-width: 96px;
        }

        > button {
          @include list-chip;
          padding: 4px 8px;
        }
      }

      li {
        @include list-chip;
      }
    }
  }

  .groupInput {
      position: relative;
      height: 44px;
      width: max-content;

      >button {
          width: 28px;
          height: 28px;
          position: absolute;
          top: 4px;
          right: -12px;
      }
  }

  [data-in-call='true'] {
    button {
      background: none;
      color: var(font-color-white);

      //path {
      //  fill: var(--font-color-white)
      //}

      &:hover, &:focus {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 0, 59, 0.7);
  }

  40% {
    transform: scale(1.1);
  }

  55% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 0, 98, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 0, 99, 0);
  }
}

@keyframes pulse_outbound {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(140, 255, 2, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(123, 255, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(114, 255, 0, 0);
  }
}

@keyframes pulse_active {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(15, 107, 121, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(26, 95, 107, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(17, 71, 91, 0);
  }
}
