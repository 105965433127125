@use "src/sass/abstracts/styled-fonts" as *;

.phonebook-contact-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  gap: 24px;
  position: relative;
  width: 444px;
  height: max-content;
  background: var(--primary-background);
  border: 1px solid var(--border-color);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    section {
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;

      mark {
        color: var(--color-danger);
        position: absolute;
        top: 12px;
        right: 0;
      }

      > div {
        display: flex;
        gap: 4px;
        align-items: center;

        > button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0 8px;
          height: 28px;
          border-radius: 4px;

          &:hover, &:focus {
            background: var(--secondary-background);
          }

          p {
            @include font-body-small-medium;
            color: var(--color-danger);
          }
        }

        label {
          padding: 0 4px;
        }

        > div {
          width: 100%;
        }
      }

      .phonebook-contact-editor__add-item {
        align-self: center;
        margin-top: 4px;
      }
    }
  }
}
