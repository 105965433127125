@use "src/sass/abstracts/styled-fonts" as *;

.container {
  flex: 1;

  > div {
    display: grid;
    grid-template-rows: 60px 1fr;
    flex: 1 1;
    max-height: 100%;

    > div {
      max-height: 100%;
      overflow: hidden auto;
    }
  }

  &[data-is-small='true'] {

    .video_page_grid_display {
      grid-template-columns: 1fr;
    }
  }

  .video_page_grid_display {
    padding: 24px;

    display: grid;
    grid-template-columns: 1fr clamp(300px, 325px, 350px);

    gap: 24px;
  }

  div[data-comp='menu-header__title'] {
    height: 60px;
    padding: 16px;
    margin: 0;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
  }

  .home {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > h2 {
      @include font-body-large-medium;
      color: var(--primary-font-color);
      margin-bottom: 4px;
    }

    > p {
      @include font-body-medium-medium;
      color: var(--secondary-font-color);
      max-width: 500px;
      text-align: center;
      margin-bottom: 24px;
    }

    > div {
      display: flex;
      gap: 10px;

      > button {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 9px;

        &:disabled {
          filter: grayscale(0.8);
        }

        &:hover, &:focus {
          border-color: var(--focus-border-color);
        }

        > span {
          background: var(--secondary-background);
          width: 40px;
          height: 40px;
          display: flex;
          padding: 6px;
          border-radius: 4px;
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          p, span {
            @include font-body-small-medium;
          }

          span {
            color: var(--secondary-font-color);
          }
        }
      }
    }
  }

  .advanced_settings {
    > button {
      margin-bottom: 8px;

      span, svg {
        width: 18px;
        height: 18px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 12px;
    }
  }

  .copy_btn {
    gap: 0;
    padding: 4px;

    p {
      isolation: isolate;
    }

    &:not([data-context]) {
      p {
        color: var(--secondary-font-color);
      }
    }

    path {
      fill: var(--secondary-font-color);
    }

    &:hover, &:focus {

      &:not([data-context]) {
        p {
          color: var(--primary-font-color);
        }
      }

      path {
        fill: var(--primary-font-color);
      }
    }
  }

  .config_form {
    border: none;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    display: flex;

    form {
      width: 100%;

      .form_data {
        @extend .video_page_grid_display;

        label {
          @include font-body-medium-medium;
          color: var(--primary-font-color);
        }

        div[data-comp='text-input'] {
          button {
            padding: 2px;
            gap: 0;
          }

          label > button {
            span, svg {
              width: 14px;
              height: 14px;
            }
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        textarea {
          resize: vertical;
        }

        .config_participants {
          > ul {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-left: 12px;
            max-height: 360px;
            overflow: visible auto;
          }
        }

        div:has(> div > .id_prefix) {
          padding: 0;
          overflow: hidden;

          > div {
            height: 100%;

            .id_prefix {
              background: var(--secondary-background);
              height: 34px;
              padding: 0 4px 0 12px;
              display: flex;
              align-items: center;

              > p {
                color: var(--primary-color);
                @include font-body-medium-regular;
              }
            }
          }
        }
      }
    }
  }

  .meeting_preview {
    //container-type: inline-size;

    .preview_data {
      @extend .video_page_grid_display;

      > div {
        display: flex;
        flex-direction: column;
        gap: 24px;


        > section {
          display: flex;
          flex-direction: column;
          gap: 8px;

          ul {
            max-height: 200px;
            overflow: hidden auto;
          }

          > p {
            @include font-body-medium-medium;
          }

          > span {
            @include font-body-medium-regular;
          }

          &.invite_preview {
            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              > p {
                @include font-body-medium-medium;
              }
            }

            > section {
              display: flex;
              flex-direction: column;
              padding: 10px 12px;
              align-self: stretch;
              background: var(--secondary-background);
              border-radius: 4px;

              > p {
                @include font-body-medium-regular;
              }

              .url {
                color: var(--primary-color);
              }
            }
          }

          .meeting_url {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              @include font-body-medium-medium;
              color: var(--primary-color);
            }
          }
        }
      }
    }

    .controls {
      align-items: center;
      justify-content: flex-end;
      gap: 8px !important;
      flex-direction: row !important;
    }
  }

  .meeting_participant {
    width: 100%;
    height: 32px;
    padding: 4px 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > p {
      @include font-body-large-regular;
      flex: 1;

      > span {
        @include font-body-medium-regular;
        color: var(--secondary-font-color);
      }
    }
  }

}