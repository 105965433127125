@use "src/sass/abstracts/styled-fonts" as *;

//.container {
.ddi_match {
  width: 100%;
  border-radius: 4px;
}

.users_section {
  width: 100%;

  > div {
    border: 1px solid var(--border-color);
    border-radius: 4px;


    > span {
      width: 28px;
      height: 28px;
    }

    > input {
      width: 100%;
    }
  }

  .user_list {
    display: flex;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    max-height: 420px;
    overflow: hidden auto;
    gap: 2px;
  }
}

.inter_tenant_user {
  width: 100%;
  display: grid;
  gap: 4px;
  grid-template-columns: max-content 1fr max-content;
  padding: 4px;
  border-radius: 4px;
  place-items: center left;

  > p {
    @include font-body-medium-regular;
    color: var(--primary-font-color);

    > span {
      margin-left: 4px;
      @include font-body-small-regular;
      color: var(--secondary-font-color)
    }
  }

  .options {
    display: flex;
    gap: 4px;
  }
}

//}