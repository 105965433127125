@use "src/sass/abstracts/styled-fonts" as *;

.container {
  justify-content: flex-start;
  height: 585px;
  width: 431px;

  .icon_editor_container, .add_users_display {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
  }

  > form {
    height: 100%;
  }

  .group_deletion {
    display: flex;
    gap: 48px;
    align-items: center;

    &[data-warning-active='true'] {
      > p {
        color: var(--color-danger);
      }
    }

    > p {
      @include font-body-medium-medium;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > span {
        color: var(--secondary-font-color);
      }
    }

    > div {
      display: flex;
      width: max-content;
      gap: 4px;
    }
  }

  .group_icon_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .icon {
      width: 80px;
      height: 80px;

      > div {
        width: 80px;
        min-width: 80px;
        height: 80px;
        min-height: 80px;

        > img {
          border-radius: 4px;
        }
      }
    }

    .control {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .button {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
      }

      .desc {
        @include font-body-small-regular;
        color: var(--secondary-font-color);
      }
    }
  }

  .channel_members {
    flex: 1;

    > ul {
      margin-top: 4px;
      max-height: 268px;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > button {
        margin: 2px;
        padding: 2px 10px !important;
        border-radius: 4px;

        &:hover, &:focus {
          background: var(--secondary-background);
        }

        span, svg {
          width: 18px;
          height: 18px;
        }

        > span {
          width: 32px;
          height: 32px;
          padding: 7px;
          border-radius: 4px;
          background: var(--secondary-background);

          path {
            fill: var(--primary-font-color);
          }
        }
      }

      .group_member {
        display: grid;
        grid-template-columns: 32px 1fr max-content;
        padding: 4px 12px;
        grid-gap: 12px;
        place-items: center left;

        .group_member_icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          overflow: hidden;

          > span {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }

        .group_member_option {

          > p {
            @include font-body-small-medium;
          }

          > div > button {
            width: max-content;
            border: none;
            gap: 4px;
            border-radius: 4px;
            min-width: 0;
          }

          button {
            border-radius: 0;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    max-height: 32px;

    .strings {
      display: flex;
      flex-direction: column;

      > p {
        @include font-body-small-regular;
      }
    }
  }
}