//AVENIR

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url('../../fonts/avenir/3848E0_0_0.eot');
  src: url('../../fonts/avenir/3848E0_0_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/3848E0_0_0.woff2') format('woff2'),
    url('../../fonts/avenir/3848E0_0_0.woff') format('woff'),
    url('../../fonts/avenir/3848E0_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: url('../../fonts/avenir/3848E0_1_0.eot');
  src: url('../../fonts/avenir/3848E0_1_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/3848E0_1_0.woff2') format('woff2'),
    url('../../fonts/avenir/3848E0_1_0.woff') format('woff'),
    url('../../fonts/avenir/3848E0_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url('../../fonts/avenir/3848E0_2_0.eot');
  src: url('../../fonts/avenir/3848E0_2_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/3848E0_2_0.woff2') format('woff2'),
    url('../../fonts/avenir/3848E0_2_0.woff') format('woff'),
    url('../../fonts/avenir/3848E0_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'icons';
  src: url('../../fonts/icons/icons.ttf?8jpzi2') format('truetype'),
    url('../../fonts/icons/icons.woff?8jpzi2') format('woff'),
    url('../../fonts/icons/icons.svg?8jpzi2#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

//INTER

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('../../fonts/inter/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../../fonts/inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../../fonts/inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../../fonts/inter/Inter-Bold.ttf');
}

//MONTSERRAT

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

//LATO
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('../../fonts/lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url('../../fonts/lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: url('../../fonts/lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: url('../../fonts/lato/Lato-Black.ttf');
}
