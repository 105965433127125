

.dimmed-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 5;
}

.floating-menu-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 300px;
  height: auto;
  background: var(--primary-background);
  border-radius: 10px;
  box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  &.bottom-left {
    bottom: 20px;
    left: 80px;
  }

  .floating-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 14px;
    background: linear-gradient(to right, var(--primary-color), var(--tertiary-color));
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    > div {
      flex: 1;
      display: flex;
      align-items: center;

      &:first-of-type {
        > button {
          display: none;
          align-items: center;
          justify-content: center;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          border: 1px solid var(--border-color);

          ~ button {
            margin-left: 5px;
          }

          &:first-of-type {
            background: var(--primary-color);
          }

          &:nth-of-type(2) {
            background: var(--color-warning);
          }

          &:last-of-type {
            background: var(--color-success);
          }
        }
      }

      &:nth-of-type(2) {
        flex: 2;
        justify-content: center;

        h3 {
          font: 18px AvenirLTStd-Medium, Avenir, sans-serif;
          text-align: center;
          white-space: nowrap;
        }
      }

      &:last-of-type {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          height: 20px;
          transform: translateY(-2px);

          i {
            color: var(--primary-background);

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
    }
  }

  .floating-menu-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
