@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.toolbar {
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
  //position: relative;

  >div {
    height: 32px;
    padding: 2px 0;
  }

  .toolbar_extras {
    overflow: clip;
    height: 32px;
    padding: 2px 0;
    margin-left: 4px;

    > div {
      padding-left: 4px;
      position: relative;
      gap: 4px;
      display: flex;

      &::after {
        background: var(--border-color);
        height: 32px;
        width: 1px;
        content: "";
        position: absolute;
        left: 0;
      }
    }
  }

  .toolbar_button {
    background: transparent;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: .2s;
    z-index: 1;
    border: 1px solid transparent;

    > span {

      svg {
        width: 22px;

        path {
          transition: .2s;
          fill: var(--secondary-font-color);
        }
      }
    }

    &:hover, &[data-is-active="true"] {
      background: var(--secondary-background);
      border: 1px solid var(--border-color);

      path {
        fill: var(--primary-font-color)
      }
    }

    &[data-is-recording="true"] {
      background: var(--primary-color);
      outline: 3px solid var(--primary-color-low-a);

      span svg path {
        fill: var(--font-color-white)
      }
    }
  }

  .overlay_container {
    z-index: 1;

    > div:first-of-type {
      z-index: 3;
      position: fixed;
    }
  }


  .emojiSection {
    position: relative;

    .emoji_menu_container {
      z-index: 2;
      position: fixed;
      //bottom: 32px;
    }
  }

  .send_message {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;

    .message_meta_data {
      position: relative;

      p {
        @include font-body-small-regular;
        color: var(--secondary-font-color);
      }

      > p {
        text-align: right;
      }

      .costData {
        position: absolute;
        bottom: 100%;
        right: 0;
        transform: translateX(4px);
        width: max-content;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        background: var(--primary-background);
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
      }

      &:hover, &:focus {
        .costData {
          opacity: 1;
          pointer-events: all;
        }
      }

      .cost_total {
        @include font-body-small-medium;
        margin-top: 4px;
        color: var(--primary-font-color);
      }
    }

    .send_button {
      margin-left: auto;

      span, svg {
        width: 28px;
        height: 28px;
      }

      > span {
        svg {
          path {
            stroke: transparent;
            fill: var(--primary-font-color);
          }
        }
      }

      &:disabled {
        pointer-events: none;

        > span {
          svg {
            path {
              fill: var(--border-color);
            }
          }
        }
      }
    }

  }

}
