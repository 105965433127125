@use "src/sass/abstracts/styled-fonts" as *;

.chat-sidebar {
  position: relative;

  .chat-header-input {
    display: flex;
    flex-direction: column;
    height: 36px;
    justify-content: center;
    gap: 10px;
    margin: 0 16px 28px;

    > div {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 4px 4px 8px;
      gap: 10px;
      width: 100%;
      height: 50px;
      max-height: 40px;
      border-radius: 4px;
      border: 1px solid var(--border-color);

      &:focus-within {
        border: 1px solid var(--focus-border-color);
      }

      .text-input {
        flex-direction: row;
        align-items: center;
        flex: 1;

        > span {
          width: 28px;
          height: 28px;
        }

        > svg {
          min-height: 24px;
          min-width: 24px;
          max-height: 24px;
          max-width: 24px;
          margin-right: 4px;
        }

        input {
          width: 100%;
          background: none;
        }
      }
    }
  }

  .windowed-chat-list-container {
    > div {
      overflow: hidden visible !important;
      min-height: 100%;
    }
  }

  .recent__chat-list {
    height: calc(100% - 138px);
    position: relative;
    transition: .4s ease-out;
    overflow: hidden auto;

    &::after {
      @include list-fade();
    }

    &[data-hide-overflow] {
      overflow: hidden;
    }

    .chat__chat-row {
      cursor: pointer;
      transition: .2s;
      margin: 0 8px;
      padding: 4px 0;

      &:hover,
      &:focus,
      &:focus-within {
        > div {
          background: var(--secondary-background);
        }

        .thread,
        .recent-row__thread-reply {
          background: var(--secondary-background);
          transition: 0.2s;
        }

        .recent-row__message-time,
          //.recent-row__thread-reply > div > p,
        > div:nth-of-type(2) > div > i {
          animation: 0.15s fade-out linear both;
        }

        //.recent-row__message-type i {
        //  animation: 0.15s fade-out linear both;
        //}

        .recent-row__options {
          display: flex;
        }
      }

      &[data-is-muted='true'] {
        transition: .2s;
        opacity: 0.65;

        &:hover, &:focus-within {
          opacity: 1;
        }
      }

      .recent-row {
        position: relative;
        display: flex;
        padding: 12px;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        height: 67px;
        border-radius: 4px;
        transition: .2s;

        > div[data-comp='entity-icon'] {
          z-index: 1;
        }

        &.thread {
          min-height: auto;
          padding-bottom: 0;
          border-bottom: none;

          &.active {
            background: var(--secondary-background);
          }

          .recent-row__message-time {
            display: none;
          }
        }

        &.active, &:hover {
          background: var(--secondary-background);
        }

        &:hover, &:focus, &:focus-within {
          .recent-row__options {
            display: block;
          }
        }

        .recent-row__content {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          position: relative;
          width: calc(100% - 58px);

          &[data-unread='true'] {
            .recent-row__message-preview p {
              color: var(--primary-font-color);
            }
          }

          &[data-is-global-search='true'] {
          }

          .recent-row__content-icons {
            display: flex;
            margin-right: 4px;

            > button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 22px;
              border-radius: 4px;

              &:hover {
                background: var(--border-color);
              }

              > span {
                width: 22px;
                height: 22px;

                path {
                  fill: var(--primary-color);
                }
              }
            }
          }

          > div:first-of-type {
            display: flex;
            align-items: center;
            height: 24px;

            h3 {
              @include font-body-large-medium;
              color: var(--primary-font-color);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .chat-status {
              width: 7px;
              min-width: 7px;
              height: 7px;
              margin-right: 5px;
              border-radius: 50%;
              transform: translateY(-1px);

              &.dot-green {
                background: var(--color-success);
              }
            }

            div:not(.notification-bubble) {
              border-right: 1px solid var(--secondary-font-color);
              width: 1px;
              height: 10px;
              margin: 0 5px;
            }

            .notification-bubble {
              margin-left: 10px;
            }

            h4 {
              @include font-body-medium-medium;
              color: var(--primary-color);
              margin-bottom: -2px;
              white-space: nowrap;
            }

            > i {
              margin-top: -2px;
              margin-right: 5px;
              @include font-body-small-regular;

              &.icon-pin {
                @include font-body-medium-regular;
              }

              &.icon-pencil {
                @include font-body-medium-regular;
                margin-top: -4px;
                margin-left: 5px;
              }
            }

            > img {
              width: 12px;
              margin-left: 5px;
              transform: translateY(-2px);
            }

            .\recent-row__message-time {
              @include font-body-small-regular;
              color: var(--primary-font-color);
              white-space: nowrap;
              margin-left: auto;
              padding-left: 10px;
              display: flex;
              align-items: center;
              opacity: 0.4;

              > span {
                svg {
                  width: 15px;

                  path {
                    fill: var(--primary-font-color);
                  }
                }
              }
            }
          }

          > h3 {
            @include font-body-medium-medium;
            color: var(--tertiary-color);
            margin-top: 2px;

            &:nth-of-type(n + 2) {
              margin-top: 10px;
            }
          }

          .recent-row__message-preview {
            display: flex;
            align-items: center;
            height: 19px;

            > span {
              width: 28px;
              height: 28px;
              margin: auto 0 0 auto;
              padding-top: 0;

              path {
                fill: var(--secondary-font-color);
                opacity: 0.4;
              }

              &[data-is-read='true'] {
                path {
                  fill: var(--color-success);
                  opacity: 1;
                }
              }
            }

            > p {
              @include font-body-medium-medium;
              color: var(--secondary-font-color);
              max-width: 100%;
              max-height: 18px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              opacity: 0.7;
              margin-left: 4px;
              margin-right: 30px;

              span {
                font-weight: 500;
                color: var(--primary-font-color);
              }

              &[data-is-unread="true"] {
                opacity: 1;

                > span > span {
                  opacity: 0.75;
                }
              }
            }

            a {
              color: #027ffe;
            }

            .recent-row__message-type {
              margin: 0 0 0 auto;
              flex: 1;
              display: flex;
              align-items: flex-end;

              i {
                &.icon-yay {
                  @include font-body-small-regular;
                }
              }
            }
          }
        }

        .recent-row__thread-reply {
          padding: 16px 10px 10px 55px;
          margin-top: -6px;
          cursor: pointer;
          white-space: nowrap;
          border-bottom: solid 1px var(--border-color);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;

          &:last-child {
            border-bottom: none;
          }

          > div {
            display: flex;
            margin-bottom: 5px;

            h4 {
              @include font-body-medium-medium;
              color: var(--primary-font-color);
              text-overflow: ellipsis;
              overflow: hidden;
            }

            p {
              @include font-body-small-regular;
              color: var(--secondary-font-color);
              margin-left: auto;
            }
          }

          > p {
            @include font-body-medium-regular;
            color: var(--secondary-font-color);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .recent-row__options {
          @include row-options;
          top: 12px;
          width: 24px;
          height: 24px;

          .contact__options-container {

            > button {
              background: var(--secondary-background);

              &:hover, &:focus {
                background: var(--primary-background);
              }
            }

            > ul {
              position: absolute;
              top: 100% !important;
              bottom: revert !important;
              right: 0 !important;
              left: revert !important;
            }

            &.menu_walled > ul {
              right: revert !important;
              left: 0 !important;
            }
          }
        }

        .recent-row-divider {
          color: var(--primary-color);
          font-weight: 500;
          padding: 15px 0 10px;
          margin: 0 10px;
          border-bottom: solid 1px var(--border-color);
        }
      }

      .list-divider:first-of-type {
        padding: 20px 0 8px;
      }
    }

    .windowed-chat-list-container {
      transition: width .3s ease-out;
    }
  }

  .small-font .chat-sidebar .recent__chat-list {
    .recent-row .recent-row__content {
      > div {
        h3,
        h4 {
          @include font-body-small-regular;
        }

        .recent-row__message-time {
          @include font-body-small-regular;
          font-size: 10px;
        }
      }

      > h3 {
        @include font-body-small-regular;
        margin: 5px 0;
      }

      .recent-row__message-preview {

        p {
          @include font-body-small-regular;
        }
      }
    }

    .recent-row__thread-reply {
      > div {
        h4 {
          @include font-body-small-regular;
        }

        p {
          @include font-body-small-regular;
          font-size: 10px;
        }
      }

      > p {
        @include font-body-small-regular;
      }
    }
  }
}

[data-reverse-menu='true'] {
  .recent-row__options {
    ul {
      bottom: 20px;
      top: auto !important;
    }
  }
}
