@use "src/sass/theming/variables" as *;
@import "src/sass/theming/_variables.scss";

$yay-sizes: (
        $theme-border-radius-small: 4px,
        $theme-border-radius-medium: 8px,
        $theme-border-radius-large: 12px,
        $theme-border-radius-chat-bubble: 4px,
);

$callswitch-sizes: (
        $theme-border-radius-small: 4px,
        $theme-border-radius-medium: 8px,
        $theme-border-radius-large: 12px,
        $theme-border-radius-chat-bubble: 12px,
);

$pulsehd-sizes: (
        $theme-border-radius-small: 4px,
        $theme-border-radius-medium: 8px,
        $theme-border-radius-large: 12px,
        $theme-border-radius-chat-bubble: 4px,
);