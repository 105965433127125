@use "src/sass/abstracts/styled-fonts" as *;

.contact-card {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 50px;
  padding: 10px;
  cursor: pointer;
  margin: 0 16px;
  border-radius: 4px;

  &[data-is-select='true'] {
    padding: 4px 12px;
    width: 250px;
    margin: 0;
    height: 28px;
    min-height: 28px;

    p {
      color: var(--secondary-font-color)
    }

    & ~ .contact-card {
      margin-top: 4px;
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    z-index: 1;

    p {
      color: var(--primary-font-color);
    }

    &:not(.tile-view) {
      background: var(--secondary-background);
    }

    .recent-row__options {
      display: flex;
    }

    > div > div:first-of-type p {
      transition: 0.25s width;
    }

    &:not(.hide-options) {
      .contact-card__icons {
        animation: moveIcons 0.25s ease;
        animation-fill-mode: forwards;

        @keyframes moveIcons {
          0% {
            margin-right: 0
          }
          100% {
            margin-right: 29px
          }
        }
      }
    }
  }

  &[data-in-call='true'] {
    p {
      color: var(--font-color-white) !important;
    }

    .recent-row__options {
      animation: none !important;
      display: inline;
      right: 13px;

      > div > button {
        background: none;

        &:hover, &:focus {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      circle {
        fill: var(--font-color-white);
      }

      ul {
        background: var(--keypad-background);
        border: 1px solid rgba(255, 255, 255, 0.2);

        p {
          color: var(--font-color-white) !important;
        }
      }
    }

    .contact-card__icons {
      button {
        &:hover, &:focus {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .group-icon {
    width: 35px;
    height: 35px;
    margin-right: 5px;

    > div {
      width: 25px;
      height: 25px;

      img {
        width: 25px !important;
        height: 25px !important;
      }
    }

    .icon:last-of-type {
      right: 4px;
    }
  }

  .contact-card__icons {
    display: flex;
    gap: 1px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      width: 28px;
      border-radius: 6px;

      span {
        width: 28px;
        height: 28px;
      }


      &[data-icon='star'] {
        path {
          fill: var(--tertiary-color);
        }
      }

      &[data-icon='pick_up'] {
        path {
          fill: var(--color-success);
        }
      }

      &:hover {
        background: var(--tertiary-background);
      }
    }
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;

    > div {
      &:first-of-type {
        width: 0;
        flex: 1;
        display: flex;
        align-items: center;

        > p {
          @include font-body-large-regular;
          margin-left: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: calc(100% - 40px);

          span {
            font-size: 10px;
            color: var(--secondary-font-color);
          }

          &.chat-preview-time {
            color: var(--secondary-font-color);
            font-size: 10px;
            padding-left: 10px;
            margin-left: auto;
          }
        }
      }

      &:last-of-type {
        display: flex;
        align-items: center;

        i {
          cursor: pointer;

          &.icon-pencil--hover {
            color: var(--secondary-font-color);

            &:focus,
            &:hover {
              color: var(--primary-color);
            }
          }

          &.icon-down-arrow {
            color: var(--secondary-font-color);

            &:focus,
            &:hover {
              color: var(--tertiary-color);
            }
          }

          //&.Icon-chat-bubble-available--hover:focus {
          //  .path1,
          //  .path2,
          //  .path3,
          //  .path4 {
          //    &:before {
          //      color: var(--tertiary-color);
          //    }
          //  }
          //}

          &.icon-chat-bubble--hover:focus {
            color: var(--tertiary-color);
          }

          &.icon-status-available--hover:focus {
            color: var(--secondary-color);
          }

          &.icon-favourite-enabled,
          &.icon-favourite-disabled {
            font-size: 19px;
          }

          &.icon-transfer--hover {
            color: var(--secondary-color);
          }
        }

        > i ~ i {
          margin-left: 10px;
        }
      }
    }

    &.contact-chat-preview {
      display: block;
      padding-top: 5px;
      padding-left: 40px;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        padding-right: 0;

        p {
          @include font-body-small-regular;
          color: var(--secondary-font-color);
          display: block;
          margin-right: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .contact-card__phone-icon {
    width: 28px;
    height: 28px;
  }

  .recent-row__options {
    @include row-options;
    right: 12px;
    width: 24px;
    height: 24px;

    p {
      color: var(--secondary-font-color);
    }

    .contact__options-container {

      > button {
        background: var(--secondary-background);

        &:hover, &:focus {
          background: var(--primary-background);
        }
      }

      > ul {
        position: absolute;
        top: 100% !important;
        bottom: revert !important;
        right: 0 !important;
        left: revert !important;

        button > p {
          color: var(--primary-font-color);
        }
      }

      &.menu_walled > ul {
        right: revert !important;
        left: 0 !important;
      }

      &.menu_floored {
        > ul {
          top: revert !important;
          bottom: 24px !important;
        }
      }
    }
  }

  &.keypad {
    &.contact-card {
      border: none;
      cursor: pointer;
      margin: 0;
    }

    .contact-card__hover-call {
      position: absolute;
      right: 10px;
      color: var(--primary-color);
      opacity: 0;
      transition: .1s;

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background: var(--primary-color);
        content: '';
        transition: .2s;
      }
    }

    &:hover {
      .contact-card__hover-call {
        opacity: 1;

        &:after {
          width: 100%;
        }
      }
    }

    > div {
      > div:first-of-type {
        p {
          max-width: 100%;
        }
      }
    }
  }

  &.active_call {
    &.contact-card {
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .contact-card__hover-call {
      position: absolute;
      right: 10px;
      color: rgba(66, 66, 66, 1);
      opacity: 0;
      transition: .1s;

      button {
        color: var(--font-color-white);
        background: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        padding: 4px;
        backdrop-filter: blur(10px);
        transition: .2s;

        &:hover {
          background: var(--primary-color);
        }
      }

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        content: '';
        transition: .2s;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);

      .contact-card__hover-call {
        opacity: 1;

        &:after {
          width: 100%;
        }
      }
    }

    > div {
      > div:first-of-type {
        p {
          color: red;
          max-width: 100%;
        }
      }
    }
  }

  &.tile-view {
    padding: 0;
    width: 81px;
    height: 81px;
    border: none;
    margin: 0;

    &:not([data-is-provisioning='true']) {
      &:focus, &:focus-within {
        background: var(--secondary-background);
      }
    }

    [data-comp='entity-icon'] {
      transition: 0.4s;
    }

    .menu_walled {
      [data-comp='blf_icon'] {
        left: unset;
        right: -3px;
        flex-direction: row-reverse;

        > div:nth-of-type(1) {
          left: unset;
          right: -4px;
        }
      }
    }

    > div {
      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        justify-content: center;
        gap: 4px;

        > p {
          @include font-body-small-regular;
          max-width: 80px !important;
          color: var(--primary-font-color);
          margin: 0;
          text-align: center;
        }
      }

      &.recent-row__content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 3px;
        background: var(--secondary-background);
        width: 60px;
        height: 44px;
        position: absolute;
        top: 8px;
        border-radius: 10px;
        pointer-events: none;
        opacity: 0;
        right: 10px;
        transition: .2s;
        animation: none;
        cursor: grab;

        > button {
          flex: 1;
          width: 28px;
          height: 28px;
          display: grid;
          place-items: center;
          border-radius: 4px;
        }

        .recent-row__options {
          flex: 1;
          display: flex;
          justify-content: center;
          margin: 0;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          padding: 0;
        }
      }
    }

    .contact__options-container {
      height: 0;
      max-height: 0;
      width: 100%;

      > button {
        display: none;
      }

      > ul {
        top: 0 !important;
        position: absolute !important;
        left: 0 !important;
        right: revert !important;
      }

      &.menu_walled {

        > ul {
          left: revert !important;
          right: 0 !important;
        }
      }

      &.menu_floored {
        > ul {
          top: revert !important;
          bottom: 24px !important;
        }
      }
    }
  }
}

[data-is-prov-item='true'] {
  box-shadow: var(--tertiary-color) 0 0 0 2px;
  z-index: 1;
}