* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  border: none;

  *:focus {
    outline: none;
  }

  *:focus-visible {
    outline: 2px solid rgba(#33C3EE, 0.5);
  }
}

body {
  line-height: 1.2;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  color: var(--primary-font-color);
}

input:disabled {
  background: inherit;
  color: var(--secondary-font-color);
}

i {
  font-style: normal;
}

* {
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    transition: .2s;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    transition: .2s;
    box-shadow: inset 0 0 10px 10px var(--scroll-color);
    border: solid 3px transparent;
    &:hover, &:active {
      box-shadow: inset 0 0 10px 10px var(--secondary-font-color);
    }
  }
  &::-webkit-scrollbar-track {
    border-radius: 30px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
