@use "src/sass/theming/variables" as *;
@import "src/sass/theming/_variables.scss";

$theme-map-yay: (
        $theme-primary-background: white,
        $theme-secondary-background: #F7F6F6,
        $theme-tertiary-background: #ded9da,
        $theme-primary-font-color: $color-cocoa,
        $theme-secondary-font-color: $grey-font-color,
        $theme-border-color: #EEEEEE,
        $theme-scroll-color: #DFDFDF,
        $theme-nav-background: $yay-gradient,
        $theme-nav-flat: $color-amaranth,
        $theme-nav-background-overlay: none,
        $theme-nav-icon-color: $color-white,
        $theme-dropdown-arrow-color: $color-amaranth,
        $theme-highlight-color: lighten($color-amaranth, 35%),
        $theme-chat-message-background: #fff9cc,
        $theme-chat-message-color: $color-cocoa,
        $theme-keypad-toggle-color: $color-picton-blue,
        $theme-keypad-toggle-color-dark: darken($color-picton-blue, 10%),
        $theme-keypad-background: $color-tundora,
        $theme-keypad-background-internal: $color-amaranth,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: $yay-body-background,
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.1),
        $theme-auth-header-color: $color-bittersweet,
);


$theme-map-callswitch: (
        $theme-primary-background: white,
        $theme-secondary-background: #F7F7F7,
        $theme-tertiary-background: #868895,
        $theme-primary-font-color: $csPrimaryBlue,
        $theme-secondary-font-color: $csDarkGrey,
        $theme-border-color: rgba(black, 0.1),
        $theme-scroll-color: #DFDFDF,
        $theme-chat-message-color: $color-white,
        $theme-nav-background: $csNavGradient,
        $theme-nav-flat: #87001C,
        $theme-nav-background-overlay: $csNavBackgroundOverlay,
        $theme-nav-icon-color: $color-white,
        $theme-dropdown-arrow-color: $csPrimaryRed,
        $theme-chat-message-background: #1B8DFE,
        $theme-keypad-toggle-color: $csKeypadButton,
        $theme-keypad-toggle-color-dark: darken($csKeypadButton, 5%),
        $theme-keypad-background: $csPrimaryBlue,
        $theme-keypad-background-internal: $csPrimaryRed,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: white,
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.1),
        $theme-auth-header-color: $csPrimaryBlue,
);

$theme-map-pulse-hd: (
        $theme-primary-background: white,
        $theme-secondary-background: #F7F6F7,
        $theme-tertiary-background: #ece6f5,
        $theme-primary-font-color: $color-cocoa,
        $theme-secondary-font-color: $grey-font-color,
        $theme-border-color: rgba(0, 0, 0, 0.1),
        $theme-scroll-color: #DFDFDF,
        $theme-nav-background: $pulse-gradient,
        $theme-nav-flat: $color-pulse-violet,
        $theme-nav-background-overlay: none,
        $theme-nav-icon-color: $color-white,
        $theme-dropdown-arrow-color: $color-pulse-violet,
        $theme-chat-message-background: #793eeb,
        $theme-chat-message-color: white,
        $theme-highlight-color: lighten($color-pulse-violet, 35%),
        $theme-keypad-toggle-color: $color-pulse-purple,
        $theme-keypad-toggle-color-dark: darken($color-pulse-hot-pink, 10%),
        $theme-keypad-background: darken($color-pulse-purple, 10%),
        $theme-keypad-background-internal: $internal-call-background,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: $pulse-body-background,
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.1),
        $theme-auth-header-color: $color-pulse-violet,
);

//DARK THEME
$theme-map-callswitch-dark: (
        $theme-primary-background: #181a21,
        $theme-secondary-background: #252631,
        $theme-tertiary-background: #434655,
        $theme-primary-font-color: #c7c7c7,
        $theme-secondary-font-color: #919191,
        $theme-border-color: #363336,
        $theme-scroll-color: #444444,
        $theme-nav-background: linear-gradient(180deg, #B5141A 0%, #87001C 100%),
        $theme-nav-flat: #87001C,
        $theme-nav-background-overlay: $csNavBackgroundOverlay,
        $theme-nav-icon-color: $color-white,
        $theme-dropdown-arrow-color: $csPrimaryRed,
        $theme-chat-message-background: #1B8DFE,
        $theme-chat-message-color: $color-white,
        $theme-highlight-color: lighten($csPrimaryRed, 35%),
        $theme-keypad-toggle-color: $csKeypadButton,
        $theme-keypad-toggle-color-dark: darken($csKeypadButton, 5%),
        $theme-keypad-background: $csPrimaryBlue,
        $theme-keypad-background-internal: $csPrimaryRed,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: #070915,
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.5),
        $theme-auth-header-color: #c7c7c7,
);

$theme-map-yay-dark: (
        $theme-primary-background: #26161c,
        $theme-secondary-background: #3d222c,
        $theme-tertiary-background: #6a454d,
        $theme-primary-font-color: #c7c7c7,
        $theme-secondary-font-color: #a4a4a4,
        $theme-border-color: #49313a,
        $theme-scroll-color: #50373b,
        $theme-nav-background: #3d1122,
        $theme-nav-flat: #3d1122,
        $theme-nav-background-overlay: none,
        $theme-nav-icon-color: $color-amaranth,
        $theme-dropdown-arrow-color: $color-white,
        $theme-chat-message-background: darken($color-bittersweet, 5%),
        $theme-chat-message-color: white,
        $theme-keypad-toggle-color: darken($color-bittersweet, 5%),
        $theme-keypad-toggle-color-dark: darken($color-bittersweet, 10%),
        $theme-keypad-background: #210511,
        $theme-keypad-background-internal: $color-bittersweet,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: #26161c,
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.5),
        $theme-auth-header-color: $color-bittersweet,
);

$theme-map-pulse-hd-dark: (
        $theme-primary-background: darken($color-pulse-purple, 17%),
        $theme-secondary-background: darken($color-pulse-purple, 10%),
        $theme-tertiary-background: #443358,
        $theme-primary-font-color: white,
        $theme-secondary-font-color: $color-pulse-light-grey-secondary,
        $theme-border-color: #352f3d,
        $theme-scroll-color: #3c3446,
        $theme-nav-background: #281a3c,
        $theme-nav-flat: #281a3c,
        $theme-nav-background-overlay: none,
        $theme-nav-icon-color: $color-pulse-red,
        $theme-dropdown-arrow-color: $color-pulse-violet,
        $theme-chat-message-background: #5521bb,
        $theme-chat-message-color: white,
        $theme-keypad-toggle-color: $color-pulse-purple,
        $theme-keypad-toggle-color-dark: darken($color-pulse-hot-pink, 10%),
        $theme-keypad-background: darken($color-pulse-purple, 15%),
        $theme-keypad-background-internal: $color-pulse-red,
        $theme-white-font-color: $color-white,
        $theme-black-font-color: $color-rider,
        $theme-body-background: darken($color-pulse-purple, 17%),
        $theme-focus-border-color: #D2D2D2,
        $theme-backdrop-color: rgba(0, 0, 0, 0.5),
        $theme-auth-header-color: $color-pulse-violet,
);
