

.contact-info {
  position: relative;
  display: flex;
  flex-direction: column;
  border: solid 1px var(--border-color);

  &:hover .contact-info__phonebook-contact-options {
    display: flex;
  }

  > div ~ div {
    margin-top: 5px;
  }

  .contact-info__number-row {
    display: flex;
    flex: 1;
    align-items: center;
    height: 20px;
    margin: 10px 0;

    .contact-info__transfer-icon {
      width: 16px;
      margin: 0 15px;

      i {
        cursor: pointer;
      }
    }

    .contact-info__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .contact-info__number {
        &:not(.no-call) {
          span {
            cursor: pointer;
          }
        }

        span {
          color: var(--tertiary-color);
        }
      }

      .contact-info__buttons {
        display: flex;
        align-items: center;

        i {
          min-width: 20px;
          min-height: 20px;

          &:hover {
            cursor: pointer;
          }

          &.icon-phone-call {
            &:hover {
              color: var(--secondary-color);
            }
          }

          &.icon-sms {
            color: var(--secondary-color);

            &:hover {
              color: var(--tertiary-color);
            }
          }

          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }

    .contact-info__options {
      display: flex;
      align-items: center;
      margin: 0 39px 0 auto;

      i {
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .contact-info__phonebook-contact-options {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    margin-top: 0;
    background: yellowgreen;


    i {
      cursor: pointer;
      width: 19px;

      ~ i {
        margin-left: 10px;
      }
    }
  }
}
