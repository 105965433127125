@use "src/sass/abstracts/mixins" as *;


.downloadBody {
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  @include mobile {
    overflow-y: scroll;
  }
}

#download-app {
  //overflow: hidden;
  .background-phones {
    pointer-events: none;
    width: 100%;
    //height: 100vh;
    //position: fixed;
    top: 0;
    left: 0;
    //background: url("/static/img/upsell/phone-1.png") right top no-repeat, url("/static/img/upsell/phone-2.png") left bottom no-repeat;
    //background-size: 900px, 900px;
    //background-position-x: -55vw, 35vw;
    //background-position-y:  400px, -400px;
    > img {
      width: 700px;
      max-width: 1100px;
      position: fixed;
    }

    .firstImage {
      left: -370px;
      bottom: -320px;
      @include tablet {
        display: none;
      }
    }

    .secondImage {
      right: -300px;
      top: -350px;
      @include tablet {
        display: none;
      }
    }

    .mobile-phones-480 {
      display: none;
      @include tablet {
        display: block;
        //width: 100%;
        position: relative;
        margin: 0 auto;
        max-width: none;
        width: 480px;
        //height: 320px;
      }
    }

    @include tablet {
      height: auto;
      position: static;
    }

    @include mobile {
      display: none;
    }
  }

  .upsellPageContent {
    position: static;
    width: 100%;
    z-index: 10;
    padding: 80px 90px 80px;

    > h1 {
      margin-bottom: 40px;
      font-size: 38px;
      max-width: 447px;
      color: var(--font-color-white);

      span {
        font-family: var(--font-family), sans-serif;
      }

      @include tablet {
        font-size: 36px;
        margin: 0 auto 40px;
      }
      @include mobile {
        font-size: 28px;
        text-align: center;
      }
    }

    > p {
      color: var(--font-color-white);
      margin-bottom: 30px;
      @include tablet {
        text-align: center;
      }
    }

    .appBadges {
      display: flex;
      margin-bottom: 50px;

      a:first-of-type {
        margin-right: 20px;
      }

      img {
        @include tablet {
          width: 195px;
        }

        @include mobile {
          width: 148px;
        }
      }

      @include tablet {
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
      }

      @include mobile {
        margin-bottom: 27px;
      }
    }

    .topHalfPhone, .bottomHalfPhone {
      display: none;
      @include mobile {
        display: block;
        width: 100%;
        transform: translateX(-6px);
      }
    }

    .infoDivs {
      //display: flex;
      //flex-wrap: wrap;
      overflow: overlay !important;
      margin-left: 58px;

      .infoDiv {
        width: 50%;
        margin-bottom: 45px;
        float: left;

        p {
          color: #ffffff;
          font-size: 14px;

          &:first-child {
            margin-bottom: 15px;
            font-size: 20px;
          }

          @include mobile {
            font-size: 16px;
            text-align: center;
            &:first-child {
              margin-bottom: 20px;
              font-size: 24px;
            }
          }
        }

        &:nth-child(3), &:nth-child(4) {
          padding-left: 33px;
          @include tablet {
            padding-left: 0;
            height: 85px;
          }

          @include mobile {
            height: auto;
          }
        }

        &:nth-child(5), &:nth-child(6) {
          padding-left: 66px;
          @include tablet {
            padding-left: 0;
          }
        }
      }

      @include tablet {
        margin: 0 auto;
        max-width: 480px;
        .infoDiv {
          margin-bottom: 33px;
          width: 43%;

          &:nth-child(2n) {
            float: right;
          }
        }
      }

      @include mobile {
        margin: 40px 0 7px;
        .infoDiv {
          width: 100%;
        }
      }
    }

    @include tablet {
      position: static;
      transform: none;
      width: 100%;
      padding: 80px 0 0;
    }
  }


  //download mobile header
  .mobile-header-top {
    display: none;
    z-index: 10;
    @include small-screens {
      padding: 6px 13px 7px;
      //display: block;
      position: fixed;
      z-index: 101;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(100deg, var(--primary-color), var(--tertiary-color));
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo img {
        height: 40px;
        display: block;
        padding: 5px 0;
      }
      > i, > div > i, > div > a {
        color: var(--font-color-white);
        font-size: 25px;
        padding: 8px 12px;
      }
      &.home {
        transition: background 0.2s ease-in-out;
        background: transparent;

        &.colored {
          background: linear-gradient(100deg, #ea1b4b, #ff7461);
        }
      }

      .profileIconImage {
        width: 20px;
      }
      &.display-none {
        display: none;
      }
    }

    @include tablet {
      .logo img {
        height: 30px;
        display: block;
        padding: 3px 0;
      }
      > i, > div > i, > div > a {
        color: var(--font-color-white);
        font-size: 20px;
        padding: 3px 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }


    &.dashboard_edit {
      @include small-screens {
        z-index: 10;
      }
    }


    //download NEW mobile header (same as react dashboard)
    > .react-navbar {
      position: fixed;
      background: #ffffff;
      height: 100%;
      width: 210px;
      padding-left: 10px;
      padding-top: 10px;
      z-index: 3;
      top: 0;
      left: 0;
      margin-left: -100%;
      box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16);
      transition: margin-left 0.3s ease-in;

      > i {
        transform: rotate(90deg);
        color: #ff7461;
        font-size: 10px;
        border-radius: 30px;
        border: 1px solid #ff7461;
        padding: 5px;

        &:hover {
          cursor: pointer;
        }
      }

      > ul {
        margin-top: 15px;
        overflow-y: scroll;
        height: 100%;
        padding-bottom: 60px;

        > li {
          > p {
            font-size: 12px;
            padding: 10px;
            color: #837470;
            width: 100%;
            border-bottom: 1px solid #f8efed;

            &:hover {
              background-color: #f8efed;
              cursor: pointer;
              color: #ff7461;
            }
          }

          > ul {
            display: none;
            padding-left: 20px;
            border-bottom: 1px solid #f8efed;

            > li {
              height: 34px;

              > a {
                padding-left: 10px;
                font-size: 10px;
                height: 34px;
                color: #837470;
                text-decoration: none;
                display: flex;
                align-items: center;
              }

              &:hover, &.current {
                background-color: #f8efed;
                cursor: pointer;

                > a {
                  color: #ff7461;
                }
              }
            }
          }

          &:last-child {
            > ul {
              border-bottom: 0;
            }
          }
        }
      }

      &.active {
        transition: margin-left 0.25s ease-in;
        margin-left: 0;
      }
    }

    .cart-for-side-panel {
      position: absolute;
      right: 56px;
      top: 5px;

      i {
        font-size: 30px;
        position: relative;
      }

      span {
        position: absolute;
        top: 15px;
        right: 50%;
        transform: translateX(28%);
        font-size: 11px;
        color: #ffffff;
        font-family: var(--font-family), sans-serif;
      }

      @include tablet {
        span {
          top: 10px;
        }
      }
    }
  }


  .download-footer {
    background-image: linear-gradient(to right, #ff7461, #ea1b4b);

    position: fixed;
    width: 100%;
    bottom: 0;

    > div {
      width: 100%;
      max-width: 1366px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    }

    * {
      color: var(--font-color-white);
    }

    > div > p {
      display: none;
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p, a {
        padding: 0;
      }
    }

    ul.download-info {
      > li {
        &:nth-child(2) {
          margin: 0 37px 0 30px;
        }

        > a {
          line-height: 1.3;
          text-decoration: none;
          font-size: 14px;
        }

        &:hover {
          > a {
            text-decoration: underline;
            color: #ffffff;
          }
        }
      }
    }

    ul.download-contact {
      p {
        line-height: 1.38;
      }

      > li {
        display: flex;
        align-items: center;

        > p {
          font-size: 16px;
        }

        > i {
          margin-right: 10px;
          font-size: 20px;
        }
      }

      > li:first-child {
        margin-right: 20px;
      }

      > li:nth-child(2) {
        height: 40px;
        padding-right: 20px;
        border-right: 1px solid white;
        margin-right: 17px;
      }

      > li:last-child {
        margin-left: 30px;

        > a {
          display: block;
          width: 75px;
          transform: translateY(2px);
        }
      }

      .logo {
        img {
          width: 100%;
        }
      }
    }

    @include small-screens {
      //display: none;
      height: auto;
      > div {
        flex-direction: column;
      }
      ul.download-info {
        order: 1;
        margin-top: 18px;
      }
    }
    @include tablet {
      > div {
        height: 100px;
      }
      ul.download-info {
        display: none;
      }
      > div > p {
        display: block;
        margin-top: 8px;
        line-height: 1.33;
        font-size: 12px;
      }
      ul.download-contact {
        justify-content: center;
        width: 100%;

        > li:nth-last-child(-n+2) {
          display: none;
        }

        > li:nth-child(2) {
          border-right: none;
          height: auto;
          padding: 0;
          margin: 0;
        }

        > li:first-child {
          margin-right: 10%;
        }
      }
    }
    @include tablet {
      position: static;
      bottom: auto;
    }

    @include mobile {
      position: static;
      .download-shopping-cart ~ & {
        display: none;
      }
    }
  }
}
