@use "src/sass/abstracts/mixins" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.editor_container {
  margin: 0 12px 12px;
  padding: 12px 12px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  //overflow: clip;
  min-width: 340px;
  transition: .2s;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: var(--primary-background);
  max-height: calc(100% - 88px);
  min-height: 100px;

  &:focus-within {
    z-index: 100;
  }

  * {
    list-style: revert;
  }

  [data-slate-placeholder='true'] {
    top: revert !important;
  }

  [data-slate-editor='true'] {
    &:focus, &:focus-within {
      outline: none !important;
    }
  }

  .user_typing {
    @include font-body-small-regular;
    position: absolute;
    top: -28px;
    color: var(--secondary-font-color);
    background: var(--primary-background);
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    height: 24px;
    overflow: hidden;
    gap: 4px;
    transition: border 0.25s, background 0.25s;
    z-index: 100;

    &[data-has-border='true'] {
      border-color: var(--border-color);
      background: var(--secondary-background)
    }
  }

  &::before {
    background: var(--secondary-background);
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    pointer-events: none;
  }

  > div[role="textbox"] {
    padding: 12px 12px;
    margin: -6px -12px 0 -6px;
    overflow-wrap: anywhere !important;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 18px;

    li {
      list-style-type: revert;
      margin-left: 17px;
    }
  }

  &:focus-within {
    border-color: var(--secondary-font-color);
  }

  .placeholder {
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    list-style: none;
  }
}
