@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    width: 100%;
  }

  div[data-comp='menu-header__title'] {
    height: 76px;
    padding-left: 24px;
    padding-top: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--border-color);
    width: 100%;

    h2 {
      @include font-body-large-medium;
      color: var(--primary-font-color);
    }

    p {
      @include font-body-medium-medium;
      color: var(--secondary-font-color);
    }
  }

  .section_group {
    @include settings-page-section-group;
  }

  .section {
    @include settings-page-item;
    grid-column: 2 / 3;

    .sliderWithLoader {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .volumeInput {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        @include font-body-medium-regular;
        color: var(--secondary-font-color);
      }

      .volumeInputRange {
        display: flex;
        align-items: center;
        height: 28px;
        border-radius: 6px;

        &:hover, &:focus-within {
          background: var(--secondary-background);
        }

        input {
          padding: 12px 0;
          background: none;
          cursor: pointer;
        }
      }

      .speakerTest {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;

        span, svg {
          width: 22px;
          height: 22px;

          path {
            fill: var(--primary-font-color);
          }
        }
      }

      button span {
        width: 28px;
        height: 28px;
      }
    }

    > button {
      p {
        color: var(--secondary-font-color);
        font-weight: 500;
      }
    }


    &[data-section='caller-id'] > div {
      height: auto;
      min-width: 0;
      background: none;

      > button {
        @include font-body-small-regular;
        color: var(--primary-font-color);
        border: 1px solid var(--border-color);
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 150px;
        max-width: 250px;

        path {
          fill: var(--primary-font-color);
        }

        span, span > svg {
          width: 22px;
          height: 22px;
        }

        p {
          @include font-body-small-regular;
          text-align: left;
        }
      }

      ul {
        width: max-content;
        max-width: 500px;
        z-index: 100;

        button {
          max-width: 484px;

          &[data-is-selected="true"] {
            background: var(--secondary-background);
          }
        }
      }
    }

    .dropdown {
      @include options-list;
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;

      > svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        path {
          fill: transparent;
        }
      }

      > button {
        @include font-body-small-regular;
        color: var(--primary-font-color);
        border: 1px solid var(--border-color);
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 150px;
        max-width: 250px;

        &:disabled {
          opacity: .25;
          pointer-events: none;
        }

        p {
          @include font-body-small-regular;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }

        span {
          width: 22px;
          height: 22px;

          path {
            fill: var(--border-color);
          }
        }

        &.speakerTest {
          width: 36.5px;
          height: 36.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          min-width: 0;

          svg {
            width: 22px;
            height: 22px;

            path {
              fill: var(--primary-font-color);
            }
          }
        }
      }

      ul, div {
        width: max-content;
        max-width: 500px;
        z-index: 100;

        button {
          max-width: 484px;

          &[data-is-selected="true"] {
            background: var(--secondary-background);
          }
        }
      }

      p {
        @include font-body-medium-regular;
      }
    }

    &.section_with_description {
      flex-wrap: wrap;
      height: fit-content;

      .description {
        width: 100%;
        padding: 12px;
        border: 1px solid rgba(var(--color-info-rgb), 0.25);
        border-radius: 4px;
        margin-top: 12px;
        position: relative;
        background: rgba(var(--color-info-rgb), 0.05);

        p {
          @include font-body-medium-regular;
          color: var(--color-info);

          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  .heading {
    @include settings-page-divider;
  }

  .callHandling {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    .description {
      width: 100%;
      padding: 12px;
      border: 1px solid rgba(var(--color-info-rgb), 0.25);
      border-radius: 4px;
      margin-top: 12px;
      position: relative;
      background: rgba(var(--color-info-rgb), 0.05);

      p, a, a:visited {
        @include font-body-medium-regular;
        color: var(--color-info);

        span, a, a:visited {
          font-weight: 500;
        }
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .saveArea {
        display: flex;
        gap: 6px;

        p {
          margin: 0;
        }

        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .call_handling_module {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 4px;
      }

      > div:not(:nth-of-type(3)) {
        margin: 0 0 0 20px;
        width: max-content;
        display: flex;
        gap: 8px;

        > div > button {
          padding: 0 4px !important;
          border: none;
          min-width: 0;
          min-height: 24px;
          height: 24px;
          gap: 4px;

          > p {
            @include font-body-medium-regular;
            color: var(--primary-color);
          }

          path {
            fill: var(--primary-color);
          }
        }
      }


      > div:nth-of-type(3) {
        margin: 0 0 0 20px;
        width: max-content;
        display: flex;
        gap: 8px;

        > div > button {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 4px;
          border-radius: 4px;

          &:hover, &:focus, &:focus-within {
            background: var(--secondary-background);
          }

          p {
            @include font-body-medium-regular;
            color: var(--primary-color);

            &[data-is-placeholder='true'] {
              color: var(--secondary-font-color);
            }
          }

          span, svg {
            display: flex;
            width: 14px;
            height: 14px;
          }

          path {
            fill: var(--primary-color);
          }
        }

        input {
          background: none;
          @include font-body-medium-regular;
          margin: -4px 0;
          color: var(--primary-color);

          &::placeholder {
            @include font-body-medium-regular;
            color: var(--secondary-font-color);
          }
        }

        label {
          padding: 0;
        }

        ul {
          padding: 0;

          > button:not([data-warning='true']) {
            @include font-body-small-regular;
            height: 24px;
            padding: 0 12px;
            border-radius: 0;

            &:disabled {
              @include font-body-medium-medium;
              padding: 0 8px;
              height: 24px;
            }

            &[data-is-large='true'] {
              min-height: 48px;

              > span {
                color: var(--secondary-font-color);
              }
            }
          }
        }
      }
    }

    > div {
      margin-top: 8px;
    }

    h3 {
      @include font-body-medium-medium;
      color: var(--primary-font-color);
      margin-top: 8px;
    }
  }
}

.shortcodes_container {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;

  .shortcode {
    display: grid;
    grid-template-columns: 40px auto;
    grid-gap: 18px;
    align-items: center;

    h2 {
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      font-weight: 700;
    }

    > div {
      @include font-body-medium-regular;
      line-height: 16px;

      > p {
        font-weight: 700;
        color: var(--primary-font-color);
      }

      > div {
        margin-top: 2px;

        p {
          font-weight: 500;
          color: var(--secondary-font-color);
        }
      }
    }

    ~ .shortcode {
      padding-top: 16px;
      border-top: 1px solid var(--border-color);
    }
  }

}
