@use "src/sass/abstracts/abstracts" as *;
@use "src/sass/abstracts/styled-fonts" as *;

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: .2s;
  z-index: 10000;
  overflow: clip;
  pointer-events: all;
  padding: 20px;
  gap: 32px;
  backdrop-filter: blur(5px);
  background: rgb(0, 0, 0, 0.5);
  border-radius: 20px;

  div {
    background: none;
  }

  p {
    color: var(--font-color-white);
  }

  > p {
    @include font-body-large-medium;
  }

  svg > path {
    fill: var(--font-color-white)
  }

  .original_call {
    display: flex;
    padding: 1px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      h3 {
        @include font-body-large-regular;
        color: var(--font-color-white);
      }

      > div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          @include font-body-small-regular;
        }
      }
    }
  }

  .transferring_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    flex: 1 0 0;
    align-self: stretch;

    .transfer_to_data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        @include font-body-large-medium;
        width: 100%;
        text-align: center;

        &:nth-of-type(2) {
          @include font-body-small-medium;
        }
      }
    }
  }

  .options_dropdown {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 240px;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      @include options-list;
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;

      > button {
        width: 114px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        padding: 0 12px;
        height: 32px;
        border-radius: 4px;

        span, svg {
          width: 24px;
          height: 24px;
        }

        p {
          @include font-body-small-regular;
          color: var(--font-color-black);
        }

        svg {
          margin-right: -8px;
          opacity: 0.5;

          path {
            fill: var(--font-color-black);
          }
        }
      }

      ul {
        min-width: 108px;
        max-width: 108px;
        padding: 8px 0;
        background: var(--font-color-white);

        button {
          @include font-body-small-regular;
          height: 24px;
          width: 100%;
          border-radius: 0;
          padding: 4px 8px;
          color: var(--font-color-black);

          &:hover, &:focus {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    > p {
      @include font-body-medium-medium;
    }
  }

  .button_container {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      height: 28px;
      border-radius: 4px;

      p {
        color: var(--color-danger);
        @include font-body-small-regular;
      }

      &:hover, &:focus {
        background: var(--keypad-background);
      }

      &:nth-of-type(2) {
        background: var(--font-color-white);

        p {
          color: var(--font-color-black);
        }

        &:hover, &:focus {
          background: rgba(254, 254, 254, 0.8);
        }
      }
    }
  }
}