

#password-set {
  display: flex;
  height: 100vh;

  .container {
    height: unset;

    > img {
      margin: 0 155px 50px 155px;
    }

    h2 {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .passwords {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .double-row-input-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 340px;
        height: 50px;
        margin-bottom: 20px;
        background: #fff;
        border-radius: 4px;

        > p {
          display: flex;
          align-items: center;
          color: var(--secondary-font-color);
          font-size: 10px;
          width: 100%;
          margin-top: 6px;
          padding: 0 10px;

          > span {
            color: var(--primary-color);
            margin-left: auto;
          }
        }

        input {
          width: 100%;
          height: 30px;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 4px;
        }
      }
    }

    button {
      width: 340px;
      height: 50px;
      color: var(--primary-background);
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
