@use "src/sass/abstracts/abstracts" as *;

.container {
  max-height: 210px;
  overflow: hidden;
  height: 100vh !important;
  margin: 0 -19px !important;
  width: 246px;
  padding: 0 16px 8px 0;
  border-radius: 6px;

  > div {
    > div > div > div > div {
      max-width: calc(100% - 8px);
      width: 100%;
      margin: 0 4px;
      cursor: default;
      padding: 4px 8px;
      min-height: 40px;

      &:hover, &:focus, &:focus-within {
        background: transparent !important;
      }
    }
  }

  p {
    font-size: 14px !important;
    font-weight: 500;
  }

  &[data-in-call='true'] p {
    color: var(--font-color-white);
  }

  &[data-scale='1'] {
    max-height: 171px;
  }

  &[data-scale='2'] {
    max-height: 120px;
  }

  &[data-scale='3'] {
    max-height: 240px;
  }

  &[data-scale='4'] {
    max-height: 212px;
  }

  &[data-scale='5'] {
    max-height: 192px;
  }

  &[data-scale='6'] {
    max-height: 80px;
  }

  > div {
    > p {
      color: var(--primary-font-color);
      text-align: center;
    }
  }

  ul {
    overflow: hidden auto;
    position: absolute !important;
  }

  > p {
    color: var(--font-color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
  }

  .windowed_contact_list_container {
    overflow: hidden auto !important;

    > div {
      overflow: visible !important;
    }
  }

  span {
    color: var(--font-color-white) !important;
  }

  .invitation_contact {

    &[data-in-call='true'] {
      margin-bottom: 4px;
      background: none !important;

      > div {
        background: var(--secondary-background);
        margin: 3px !important;

        path {
          fill: var(--primary-font-color) !important;
        }

        p {
          max-width: 140px !important;
        }
      }
    }

    > div {
      display: flex;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      width: max-content;
      height: 32px;
      padding: 5px 10px;
      gap: 8px;
      margin: 3px 0 3px 12px;
      align-items: center;

      p {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        width: 20px;
        height: 20px;
        border-radius: 6px;

        &:hover, &:focus {
          background: var(--secondary-background);
        }
      }
    }
  }
}
