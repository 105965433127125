.video-admin-side-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid var(--border-color);

  > .title {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 15px;

    span {
      font-size: 24px;
      font-weight: 500;
      color: var(--primary-color);
    }
  }

  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(+10px);
    }
    60% {
      transform: translateX(+5px);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(+10px);
    }
    60% {
      transform: translateX(+5px);
    }
  }

  > .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 10px 0;
    grid-gap: 10px;

    button {
      flex: 1;
      position: relative;
      background: var(--secondary-background);
      border: 1px solid transparent;

      &:hover, &:focus {
        background: var(--secondary-background);
        border-color: var(--border-color);
      }

      > div i {
        right: 15px;
      }

      &:nth-of-type(2) {

        > div i {
          right: 45px;
        }
      }

      &:hover, &:focus {
        //box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

        > div i {
          display: initial;
        }
      }

      i, p {
        color: var(--primary-font-color);
      }

      > i {
        font-size: 30px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          display: none;
          position: absolute;
          bottom: 12px;
          font-size: 16px;
          transform-origin: 50% 50%;
          -webkit-animation: bounce .8s;
          animation: bounce .8s;
        }
      }
    }
  }

  .search-bar {
    padding: 10px;
    display: flex;

    .input-container {
      display: flex;
      align-items: center;
      flex: 1;
      height: 40px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid var(--border-color);

      &:focus {
        border-color: var(--secondary-font-color);
      }

      i {
        margin-right: 7.5px;
        color: var(--primary-font-color);
        font-size: 14px;
      }

      .text-input-default-container {
        input {
          margin-left: 10px;
          border: none;
          padding: 10px 5px;
          font-size: 16px;
          background: none;
        }
      }

      >div {
        width: 100%;
        > input {
          background: none;
          width: 100%;
        }
      }
    }
  }

  .meetings {
    overflow: hidden auto;

    .list-container {
      height: 100%;
      padding: 0 0 100px 0;

      > div {
        .list-divider {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          height: 32px;

          i {
            color: var(--primary-color);
          }
        }

        .list {
          .no-results-placeholder {
            padding: 20px;
            font-size: 16px;
            font-weight: 500;
            color: var(--primary-font-color);
          }

          .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            width: calc(100% - 20px);
            padding: 0 15px;
            margin: 0 10px;
            border-radius: 4px;
            transition: .2s;

            &[data-active="true"] {
              background: var(--secondary-background);
            }

            &:hover, &:active, &:focus-within {
              background: var(--secondary-background);
              cursor: pointer;

              .actions {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &:focus-within {
                  button {
                    background: var(--primary-background);
                  }
                }

                > button {
                  width: 22px;
                  height: 22px;
                  border-radius: 6px;

                  &:hover, &:focus {
                    background: var(--primary-background);
                  }
                }

                .actions-menu {
                  @include options-list;
                }
              }
            }

            .details {
              display: flex;
              align-items: center;
              width: 300px;

              .status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 6px;

                .icon-ellipse-green {
                  margin-bottom: 5px;
                  margin-right: 5px;

                  i {
                    font-size: 13px;
                  }
                }
              }

              .title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 260px;

                * {
                  text-align: left;
                }

                .name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: var(--primary-font-color);
                }

                .description {
                  margin-top: 5px;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: var(--primary-font-color);
                }
              }
            }

            .actions {
              display: none;
            }
          }
        }
      }
    }
  }
}
