/*
@use "src/sass/abstracts/styled-fonts" as *;
 */

/*
Font sizes:
 - large: 16px
 - medium: 14px
 - small: 12px

Font weights:
 - bold: 700
 - medium: 500
 - regular: 400
 - light: 300
 */


@mixin font-body-large-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
}

@mixin font-body-large-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
}

@mixin font-body-large-regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
}

@mixin font-body-large-light {
  font-weight: 300;
  font-size: 16px;
  line-height: 125%;
}

@mixin font-body-medium-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.01em;
}

@mixin font-body-medium-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.01em;
}

@mixin font-body-medium-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.01em;
}

@mixin font-body-medium-light {
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.01em;
}

@mixin font-body-small-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.03em;
}

@mixin font-body-small-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.03em;
}

@mixin font-body-small-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.03em;
}

@mixin font-body-small-regular-imp {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 125% !important;
  letter-spacing: 0.03em !important;
}

@mixin font-body-small-light {
  font-weight: 300;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.03em;
}



@mixin font-title-h1-bold {
  font-weight: 700;
  font-size: 96px;
  line-height: 100%;
}

@mixin font-title-h1-medium {
  font-weight: 500;
  font-size: 96px;
  line-height: 100%;
}

@mixin font-title-h1-regular {
  font-weight: 400;
  font-size: 96px;
  line-height: 100%;
}

@mixin font-title-h1-light {
  font-weight: 300;
  font-size: 96px;
  line-height: 100%;
}

@mixin font-title-h2-bold {
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
}

@mixin font-title-h2-medium {
  font-weight: 500;
  font-size: 60px;
  line-height: 100%;
}

@mixin font-title-h2-regular {
  font-weight: 400;
  font-size: 60px;
  line-height: 100%;
}

@mixin font-title-h2-light {
  font-weight: 300;
  font-size: 60px;
  line-height: 100%;
}

@mixin font-title-h3-bold {
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
}

@mixin font-title-h3-medium {
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
}

@mixin font-title-h3-regular {
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
}

@mixin font-title-h3-light {
  font-weight: 300;
  font-size: 48px;
  line-height: 100%;
}

@mixin font-title-h4-bold {
  font-weight: 700;
  font-size: 34px;
  line-height: 100%;
}

@mixin font-title-h4-medium {
  font-weight: 500;
  font-size: 34px;
  line-height: 100%;
}

@mixin font-title-h4-regular {
  font-weight: 400;
  font-size: 34px;
  line-height: 100%;
}

@mixin font-title-h4-light {
  font-weight: 300;
  font-size: 34px;
  line-height: 100%;
}