@use "src/sass/theming/variables" as *;
@import "src/sass/theming/_variables.scss";

$theme-yay-semantics: (
        $theme-color-warning: $color-amber,
        $theme-color-danger: $color-amaranth,
        $theme-color-danger-dark: darken($color-amaranth, 5%),
        $theme-color-disabled: $color-mamba,
        $theme-color-success: $color-atlantis,
        $theme-color-success-dark: darken($color-atlantis, 5%),
        $theme-notification-color: $color-picton-blue,
);

$theme-pulse-hd-semantics: (
        $theme-color-warning: $color-amber,
        $theme-color-danger: $color-pulse-red,
        $theme-color-danger-dark: darken($color-pulse-red, 10%),
        $theme-color-disabled: $color-mamba,
        $theme-color-success: $color-atlantis,
        $theme-color-success-dark: darken($color-atlantis, 5%),
        $theme-notification-color: $color-picton-blue,
);

$theme-call-switch-semantics: (
        $theme-color-warning: $color-amber,
        $theme-color-danger: $csPrimaryRed,
        $theme-color-danger-dark: darken($csPrimaryRed, 5%),
        $theme-color-disabled: #F3F3F4,
        $theme-color-success: $color-atlantis,
        $theme-color-success-dark: darken($color-atlantis, 5%),
        $theme-notification-color: $color-picton-blue,
);

$theme-gen-sem-cs: (
        $theme-color-warning: $color-amber,
        $theme-color-danger: $csPrimaryRed,
        $theme-color-danger-dark: darken($csPrimaryRed, 5%),
        $theme-color-disabled: #F3F3F4,
        $theme-color-success: $color-atlantis,
        $theme-color-success-dark: darken($color-atlantis, 5%),
);
