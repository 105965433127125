

.menu-search {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  background: #faf4f2;
  border-bottom: solid 1px var(--border-color);

  input {
    color: #837470;
    font-size: 14px;
    flex: 1;
    height: 100%;
    margin: 0 10px;
    background: #faf4f2;

    &::placeholder {
      color: var(--secondary-font-color);
    }
  }

  .divider {
    background-color: var(--border-color);
  }

  .icon-create-chat--hover {
    margin-left: 10px;
    cursor: pointer;
  }
}
