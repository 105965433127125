

.call-notification-container {
  z-index: 50;

  &.top-right {
    top: 60px;
    bottom: initial;
  }

  &.bottom-left {
    right: initial;
    left: 60px;
  }

  &.top-left {
    top: 60px;
    right: initial;
    bottom: initial;
    left: 60px;
  }

  > div ~ div {
    margin-bottom: 10px;
  }
  > div > div {
    transition: .2s;
  }

  .call-notification {
    position: relative;
    min-width: 400px;
    background: rgba(51, 41, 37, 0.8);
    border-radius: 4px;

    > div {
      &:first-of-type {
        display: flex;
        align-items: center;
        padding: 15px 10px;

        p {
          font-size: 16px;
          color: #fff;

          span {
            &:first-child {
              animation: dot-pulse 1.4s linear infinite both;
            }

            &:nth-child(2) {
              animation: dot-pulse 1.4s linear 0.2s infinite both;
            }

            &:last-child {
              animation: dot-pulse 1.4s linear 0.4s infinite both;
            }
          }
        }

        .call-notification__details {
          flex: 1;
          padding-right: 10px;

          h3 {
            font-size: 16px;
            color: #fff;
            width: fit-content;
          }

          p {
            margin-top: 2px;
            font-weight: 300;
            width: fit-content;
          }
        }

        .round-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          margin-left: 10px;
          border-radius: 50%;
          cursor: pointer;

          &:hover {
            background: var(--secondary-color);
          }

          i {
            font-size: 16px;
          }

          p {
            font-size: 10px;
          }
        }

        .round-button--hangup {
          @extend .round-button;
          background: var(--primary-color);
        }

        .round-button--answer {
          @extend .round-button;
          background: var(--color-success);
        }

        .round-button--transfer {
          @extend .round-button;
          background: var(--secondary-color);

          &:hover {
            background: var(--color-success);
          }

          i {
            color: #fff;
          }
        }
      }

      .icon-maximise--hover {
        font-size: 8px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
      }
    }

    &:nth-of-type(2) h1 {
      margin-bottom: 5px;
    }
  }

  .dialpad--options-menu,
  .dialpad--on-call {
    grid-template-columns: repeat(3, 60px);
  }

  .dialpad-button {
    > button {
      width: 60px;
      height: 60px;

      h3,
      i {
        font-size: 26px;
      }

      h3 {
        line-height: 1;
      }
    }

    p {
      font-size: 10px;
      color: var(--border-color);
    }
  }

  .dialpad-button--text {
    width: 60px;
    height: 60px;

    p {
      font-size: 14px;
    }
  }

  .dialpad-button--start-call button i {
    font-size: 32px;
  }

  .dialpad-button--backspace {
    width: 60px;
  }

  .call-notification--incoming {
    @extend .call-notification;
    background: rgba(40, 40, 40, 0.8);

    div:first-of-type .round-button--transfer {
      display: none;
    }
  }

  .first {
    animation: bounce-in-right 1.1s cubic-bezier(0.42, 0.48, 1, 0.73) both;
  }

  .call-notification--on-call {
    position: relative;
    @extend .call-notification;
    backdrop-filter: blur(12px);
    background: rgba(0,0,0,0.6);

    div:first-of-type {
      .round-button--hangup {
        width: 35px;
        height: 35px;
      }

      .round-button--answer,
      .round-button--transfer {
        display: none;
      }
    }
  }

  .call-notification--on-transfer {
    @extend .call-notification;
    backdrop-filter: blur(12px);
    background: rgba(0,0,0,0.6);

    div:first-of-type {
      .round-button--hangup,
      .round-button--transfer {
        width: 35px;
        height: 35px;
      }

      .round-button--answer {
        display: none;
      }
    }
  }

  .call-notification--on-hold {
    @extend .call-notification;
    backdrop-filter: blur(12px);
    background: rgba(0,0,0,0.4);

    div:first-of-type .round-button--answer {
      display: none;
    }
  }

  .call-notification--terminating {
    @extend .call-notification;
    backdrop-filter: blur(12px);
    background: rgba(0,0,0,0.4);

    div:first-of-type .round-button--answer {
      display: none;
    }

    button {
      opacity: 0.7;
      pointer-events: none;
      cursor: initial;

      &.active {
        background: #fff;

        i {
          color: var(--primary-color);
        }
      }
    }

    .icon-maximise--hover {
      display: none;
    }
  }
}
