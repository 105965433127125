@use 'src/sass/theming/pallete' as *;
@use 'src/sass/theming/mixins' as *;

.container {
  width: 100%;
  max-width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  &[data-schema='yddab88d'] {
    * {
      &[data-theme="light"] {
        @include spread-map($theme-map-yay)
      }

      &[data-theme="dark"] {
        @include spread-map($theme-map-yay-dark);
      }
    }

  }

  // PulseHd Theme
  &[data-schema='pe12cd8c'] {
    * {
      &[data-theme="light"] {
        @include spread-map($theme-map-pulse-hd);
      }

      &[data-theme="dark"] {
        @include spread-map($theme-map-pulse-hd-dark);
      }
    }
  }

  // CallSwitchOne Theme
  &[data-schema='cef527d1'] {
    * {
      &[data-theme="light"] {
        @include spread-map($theme-map-callswitch);
      }

      &[data-theme="dark"] {
        @include spread-map($theme-map-callswitch-dark);
      }
    }
  }

  * {
    transition: .2s;

    &[data-fill="primary-background"] {
      fill: var(--primary-background);
    }

    &[data-fill="secondary-background"] {
      fill: var(--secondary-background);
    }

    &[data-fill="tertiary-background"] {
      fill: var(--tertiary-background);
    }

    &[data-fill="nav-flat"] {
      fill: var(--nav-flat);
    }

    &[data-fill="border"] {
      fill: var(--border-color);
    }

    &[data-fill="primary-font"] {
      fill: var(--primary-font-color);
    }

    &[data-fill="secondary-font"] {
      fill: var(--secondary-font-color);
    }

    &[data-fill="tertiary-font"] {
      fill: var(--tertiary-font-color);
    }

    &[data-fill="primary"] {
      fill: var(--nav-background);
    }

    &[data-fill="secondary"] {
      fill: var(--secondary-color);
    }

    &[data-fill="tertiary"] {
      fill: var(--tertiary-color);
    }

    &[data-fill="chat-background"] {
      fill: var(--chat-message-background);
    }

    &[data-fill="chat-color"] {
      fill: var(--chat-message-color);
    }
  }
}
