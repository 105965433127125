@use "src/sass/abstracts/styled-fonts" as *;

.defaultTag {
  position: relative;

  &:hover, &:focus-within {
    > div {
      opacity: 1;
      pointer-events: all;
    }
  }

  > div {
    transform: translateY(12px) translateX(-12px);
    position: fixed;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.2s, visibility;
    pointer-events: none;
    padding: 12px;
  }
}

.generic_tag {
  height: 64px;
  padding: 12px;
  border: 1px solid var(--border-color);
  background: var(--primary-background);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
  border-radius: 4px;

  .user_tag {
    display: flex;
    border-radius: 4px;
    z-index: 10;
    overflow: clip;
    flex-direction: row;
    gap: 8px;
    width: max-content;
    min-width: 320px;
    max-width: 480px;
    align-items: center;

    > :nth-child(2) {

      > p {
        @include font-body-small-regular-imp;
      }

      span {
        p {
          @include font-body-small-regular-imp;
          color: var(--secondary-font-color) !important;
        }
      }
    }

    > :nth-child(3) {
      display: flex;
      align-items: center;
      gap: 4px;
      width: max-content;
      justify-content: flex-end;
      margin-left: auto;

      > button {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }
    }
  }
}

.chat_generic_tag {
  @include font-body-small-regular;
  padding: 2px 4px !important;
  height: max-content !important;
  max-width: 240px;
  border-radius: 4px;
  margin: 0 2px 0 0;

  &[data-is-dark='true'] {
    background: rgba(255, 255, 255, 0.05);
  }

  &[data-is-dark='false'] {
    background: rgba(0, 0, 0, 0.05);
  }

  &[data-is-leaf='true'] {
    border-radius: 4px;
  }
}

.department_span {

  .department {
    border-radius: 4px;
  }

  & ~ .department_span {
    &:before {
      content: ', ';
    }
  }
}