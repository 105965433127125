
@use "src/sass/abstracts/mixins" as *;

#login {
  font-family: var(--font-family), sans-serif;
  display: flex;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    width: 340px;
    height: auto;
    margin: auto;

    .logo {
      margin: 0 auto 50px;
      width: 170px;
      height: 70px;
    }

    .login-form {
      justify-content: center;
      width: 100%;
      margin: 0 70px;

      .login__error {
        display: flex;
        align-items: center;
        position: relative;
        background: var(--primary-color);
        width: 423px;
        height: 53px;
        margin-top: -53px;
        padding: 2px 15px;
        border-radius: 4px;
        border: solid 1px #fff;
        font-size: 14px;
        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both, visibility-none 0s 3s forwards;
        left: -40px;
        top: 22px;

        > p {
          color: var(--primary-background);
          padding-left: 5px;
        }

        &:after, &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(234, 27, 75, 0);
          border-top-color: var(--primary-color);
          border-width: 11px;
          margin-left: -11px;
        }

        &:before {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #ffffff;
          border-width: 12px;
          margin-left: -12px;
        }
      }

      h2 {
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
      }

      h3 {
        text-align: center;

        a {
          color: #fff;
          text-decoration: none;
        }
      }

      .user-text-input-box {
        @include user-text-input-box;
      }

      button {
        @include form-button;
        margin-bottom: 20px;
      }

      .forgot-password {
        width: 340px;
        height: 19px;
        font-size: 14px;
        margin-top: -4px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }

    .google-login-button {
      @include form-button;
      margin-bottom: 20px;
      background: #fff;
      border: 1px solid var(--primary-color);

      > div {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          color: var(--secondary-font-color);
          margin-left: 15px;
          margin-bottom: -5px;
        }
      }
    }

    h4 {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 15px;

      &:after, &:before {
        flex: 1;
        content: '';
        height: 1px;
        background: #fff;
      }

      &:before {
        margin-right: 15px;
      }

      &:after {
        margin-left: 15px;
      }
    }

    .disabled {
      .user-text-input-box {
        .user-input-overlay {
          display: block;
        }
      }
    }

    .register {
      button {
        @include form-button;
      }
    }
  }
}

