@use "src/sass/abstracts/styled-fonts" as *;


.short_codes {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-height: calc(100% - 136px);
  overflow: hidden auto;

  figure {
    width: 100%;
  }

  button {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--border-color);

    &:hover, &:focus {
      background: var(--secondary-background);
    }

    > div {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      h2 {
        color: var(--tertiary-color);
        pointer-events: none;
      }

      > div {
        max-width: 100%;
        overflow: hidden;

        > p {
          @include font-body-medium-bold;
          text-align: left;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:nth-of-type(2) {
            @include font-body-medium-medium;
            color: var(--secondary-font-color);
          }
        }
      }
    }
  }
}