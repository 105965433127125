@use "src/sass/abstracts/abstracts" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    &:first-child {
      padding: 0;
      justify-self: start;
      width: 100%;
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 10px;

      > div {
        margin: 24px;
        font-weight: 500;
        line-height: 22px;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        h2 {
          font-size: 16px;
          font-weight: 500;
          color: var(--primary-font-color);
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: var(--secondary-font-color);
        }
      }
    }
  }

  .content {
    width: 100%;

    .accountDetails {
      display: flex;
      gap: 15px;
      width: 100%;

      .userIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 50px;
        overflow: visible;

        .userIconImages {
          display: flex;
          align-items: center;

          div:nth-child(1) {
            margin: 0;
            min-width: 50px;
            min-height: 50px;
            width: 50px;
            height: 50px;

            > i {
              width: 16px;
              height: 16px;
              right: -5px;
              top: -3px;

              span, svg {
                width: 16px;
                height: 16px;
              }
            }

            span {
              font-size: 24px;
            }

            > svg {
              width: 50px;
              height: 50px;
            }
          }
        }

        > button {
          @include font-body-small-regular;
          padding: 5px 10px;
          border-radius: 6px;
          color: var(--color-danger);

          &:hover, &:focus {
            background: var(--secondary-background);
          }
        }
      }

      .userDetails {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        h2 {
          @include font-body-large-medium;
          color: var(--primary-font-color);
        }

        p {
          @include font-body-medium-regular;
        }
      }
    }

    .imageModal {
      display: grid;
      place-items: center;
      background: none;
    }

    .previewTheme {
      margin-top: 25px;
      opacity: 0.4;
      background: var(--primary-background);
      border-radius: 12px;
      padding: 25px;
      border: 3px dashed var(--border-color);
      transition: .2s;
      width: 100%;

      &:hover {
        background: var(--secondary-background);
        opacity: 1;

      }

      > p {
        @include font-body-small-regular;
        text-transform: uppercase;
        color: var(--secondary-font-color);
        text-align: center;
      }

      > div > div {
        transition: .2s;

        &:hover, &:focus {
          background: var(--primary-background);
        }
      }
    }

    .section_group {
      @include settings-page-section-group;
    }

    .heading {
      @include settings-page-divider;
    }

    .section {
      @include settings-page-item;
      grid-column: 2 / 3;

      &[data-open='true'] {
        border-bottom: none;
      }

      p[data-right-item="true"] {
        color: var(--secondary-font-color);
      }

      &[data-section-type='icon-btn'] {
        button {
          padding: 0;
          width: 28px;
          height: 28px;

          span {
            width: 28px;
            height: 28px;
          }
        }
      }

      .sliderWithLoader {
        display: flex;
        gap: 4px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      button {
        padding: 8px;
        border-radius: 4px;

        &[data-context='danger'] p {
          color: var(--color-danger);
        }

        p {
          color: var(--primary-font-color);
          font-weight: 400;
        }

        &:hover, &:focus {
          background: var(--secondary-background);
        }
      }

      .dropdown {
        @include options-list;
        position: relative;
        display: flex;
        align-items: center;
        gap: 4px;

        > svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;

          path {
            fill: transparent;
          }
        }

        > button {
          @include font-body-medium-regular;
          color: var(--primary-font-color);
          width: 200px;
          border: 1px solid var(--border-color);
          padding: 6px 12px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 250px;
            display: block;
          }

          span {
            width: 22px;
            height: 22px;

            path {
              fill: var(--primary-font-color);
            }
          }

          &.speakerTest {
            padding: 3px;
            width: 28px;
            height: 28px;

            svg {
              width: 22px;
              height: 22px;
            }
          }
        }

        p {
          @include font-body-medium-regular;
        }
      }

      > input {
        @include font-body-medium-regular;
        line-height: 20px;
        padding: 4px 12px;
        border-radius: 6px;
        border: 1px solid transparent;

        &:focus {
          border: 1px solid var(--border-color);
        }
      }

      > svg {
        width: 24px;
        height: 24px;

        path {
          fill: none;
        }
      }
    }

    .devices {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: 64px;
      grid-column: 2 / 3;
      max-height: 400px;
      overflow: hidden auto;

      .device {
        display: flex;
        align-items: center;
        height: 40px;
        gap: 8px;
        margin-left: 36px;

        p {
          @include font-body-small-regular;
          flex: 1;
          color: var(--secondary-font-color);
        }

        > svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }

        > button {
          height: 32px;
          padding: 0 8px;
          cursor: default;
          border-radius: 4px;

          p {
            color: var(--primary-font-color);
          }

          &:not(:disabled) {
            cursor: pointer;

            p {
              color: var(--color-danger);
            }

            &:hover, &:focus {
              background: var(--secondary-background);
            }
          }
        }
      }
    }
  }
}
